import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, Modal, DatePicker } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { changeAuctionStatus, getAuctions } from "Network";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import AuctionModal from "./AuctionModal";
import moment from "moment";

function Auctions() {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [isAuctionModalVisible, setIsAuctionModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [statusModalLoading, setStatusModalLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele.startDate, "MM/DD/YYYY");
        return (
          comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
        );
      });
      setFilteredDataSource(dataWithingRange);
    } else {
      setFilteredDataSource(dataSource);
    }
  };

  const handleStatusChange = () => {
    if (statusModalLoading) return;
    setStatusModalLoading(true);

    const payload = {
      isActive: !rowData.isActive,
      auctionId: rowData.id,
    };

    changeAuctionStatus(
      payload,
      (res) => {
        setIsStatusModalVisible(false);
        setStatusModalLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          setRenderMe(!renderMe);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsStatusModalVisible(false);
        setStatusModalLoading(false);
        toastError(res.message);
      }
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="all-orders-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined style={selectedRange ? { color: "blue" } : {}} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "nameAr",
      title: "NAME(AR)",
      dataIndex: "nameAr",
      ...getColumnSearchProps("nameAr"),
    },
    {
      key: "nameEn",
      title: "NAME(EN)",
      dataIndex: "nameEn",
      ...getColumnSearchProps("nameEn"),
    },
    {
      key: "type",
      title: "TYPE",
      dataIndex: "type",
      ...getColumnSearchProps("type"),
    },
    {
      key: "maximumAuctionAmountInKilo",
      title: "MAX QUANTITY",
      dataIndex: "maximumAuctionAmountInKilo",
      ...getColumnSearchProps("maximumAuctionAmountInKilo"),
    },
    {
      key: "maximumAuctionAmountForUserInKilo",
      title: "MAXIMUM QUANTITY PER USER",
      dataIndex: "maximumAuctionAmountForUserInKilo",
      ...getColumnSearchProps("maximumAuctionAmountForUserInKilo"),
    },
    {
      key: "availableAuctionAmountInKilo",
      title: "AVAILABLE QUANTITY",
      dataIndex: "availableAuctionAmountInKilo",
      ...getColumnSearchProps("availableAuctionAmountInKilo"),
    },
    {
      key: "minimumBidding",
      title: "MINIMUM BIDDING",
      dataIndex: "minimumBidding",
      ...getColumnSearchProps("minimumBidding"),
    },
    {
      key: "incrementValue",
      title: "INCREMENT VALUE",
      dataIndex: "incrementValue",
      ...getColumnSearchProps("incrementValue"),
    },
    {
      key: "startDate",
      title: "START DATE",
      dataIndex: "startDate",
      ...getColumnDateProps("startDate"),
    },
    {
      key: "isActive",
      title: "IS ACTIVE",
      dataIndex: "isActive",
      filters: [
        { text: "Active", value: true },
        { text: "Deactive", value: false },
      ],
      onFilter: (value, record) => record?.isActive === value,
      render: (_, row) => {
        return <span>{row.isActive ? "Active" : "Deactive"}</span>;
      },
    },
    {
      key: "id",
      title: "ACTIONS",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              onClick={() => handleEditAuction(row)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleEditAuction = (row) => {
    setRowData(row);
    setMode("edit");
    setIsAuctionModalVisible(true);
  };

  const handleAddAuction = () => {
    setIsAuctionModalVisible(true);
    setMode("add");
  };

  const onCancel = () => {
    setIsAuctionModalVisible(false);
    setRenderMe((prev) => !prev);
    setMode("add");
  };

  const getAllAuctions = () => {
    getAuctions(
      (res) => {
        if (res.success) {
          setLoading(false);
          const updatedData = res.data.map((ele) => {
            ele.startDate = new Date(ele.startDate).toLocaleString();
            return ele;
          });
          setDataSource(updatedData);
          setFilteredDataSource(updatedData);
        } else {
          setLoading(false);
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          setLoading(false);
          toastError(res.message);
        } else {
          setLoading(false);
          toastError(res.message);
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    getAllAuctions();
  }, [renderMe]);

  return (
    <section className="users auctions">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Auctions"
              plusButtonName={"Add Auction"}
              plusButtonAction={handleAddAuction}
            />
            <SubHeader bgColor="#356ab3" title="All Auctions" />
            <div className="users__table grid-table mrg-ye-50">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
                rowClassName={(record) => record.showRedFlag ? 'auctions__table-row auctions__table-row--red-flag' : 'auctions__table-row' }
              />
            </div>
          </div>
        </div>
        {isAuctionModalVisible && (
          <AuctionModal
            isAuctionModalVisible={isAuctionModalVisible}
            mode={mode}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${
                  mode === "add" ? "Add New" : mode === "edit" ? "Edit" : "View"
                } Auction`}
                closeButton
                onCancel={onCancel}
              />
            }
            onCancel={onCancel}
            primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isStatusModalVisible && (
          <Modal
            title={`${rowData.isActive ? "Deactivate" : "Activate"} Auction`}
            open={isStatusModalVisible}
            onOk={handleStatusChange}
            okText={rowData.isActive ? "Deactivate" : "Activate"}
            onCancel={() => {
              setIsStatusModalVisible(false);
            }}
            centered
            className="modal-with-no-header"
            confirmLoading={statusModalLoading}
          >
            <span>
              You are about to {rowData.isActive ? "deactivate" : "activate"}{" "}
              this auction!
            </span>
          </Modal>
        )}
      </div>
    </section>
  );
}

export default Auctions;
