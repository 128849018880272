import {
  postResource,
  getResource,
  putResource,
  patchResource,
  deleteResource,
} from "services";

/* Start authentication */
export const signinAdmin = (data, onSuccess, onFail) => {
  const path = "api/Admin/Login?lang=en";
  postResource(path, data, onSuccess, onFail);
};
/* End authentication */

/* Start Items */
export const addItemApi = (data, onSuccess, onFail) => {
  const path = "api/Item/Item?lang=en";
  postResource(path, data, onSuccess, onFail);
};
export const editItemApi = (data, onSuccess, onFail) => {
  const path = "api/Item/Item?lang=en";
  putResource(path, data, onSuccess, onFail);
};
export const deleteItemApi = ({ itemId }, onSuccess, onFail) => {
  const path = `api/Item/Item?lang=en&itemId=${itemId}`;
  deleteResource(path, onSuccess, onFail);
};
/* End Items */

/* Start supplier admin */
export function getSupplierAdminRequests(onSuccess, onFail) {
  const path = "api/Supplier/GetSupplierRequests";
  getResource(path, onSuccess, onFail);
}
export function AddSupplierRequest(data, onSuccess, onFail) {
  const path = "api/Supplier/AddSupplierRequest";
  postResource(path, data, onSuccess, onFail);
}
export function getAllSuppliersRequests(onSuccess, onFail) {
  const path = "api/Admin/SuppliersRequestsForAdmin";
  getResource(path, onSuccess, onFail);
}
/* End supplier admin */

export function getAllUsersData(onSuccess, onFail) {
  const path = "api/users?lang=en";
  getResource(path, onSuccess, onFail);
}
export function getAllUnitsApi(onSuccess, onFail) {
  const path = "api/Item/Units?language=en";
  getResource(path, onSuccess, onFail);
}
export function getAllItemsApi(data, onSuccess, onFail) {
  const path = "api/Item/ItemsForAdminV2?lang=en";
  postResource(path, data, onSuccess, onFail);
}
export function getUserRequestsApi(onSuccess, onFail) {
  const path = "api/Admin/LoginRequest?lang=en";
  getResource(path, onSuccess, onFail);
}
export function approveUserRequestApi(data, onSuccess, onFail) {
  const path = "api/User/ApproveUserLogin?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function getSearchData({ search }, onSuccess, onFail) {
  const data = { search };
  const path = `api/search`;
  postResource(path, data, onSuccess, onFail);
}
export function getOrders(onSuccess, onFail) {
  const path = "api/Order/GetOrders?lang=en";
  getResource(path, onSuccess, onFail);
}
export function getOrdersV2(payload, onSuccess, onFail) {
  const path = "api/Order/GetOrdersV2?lang=en";
  postResource(path, payload, onSuccess, onFail);
}
export function getExchangeOrderDetails({ id }, onSuccess, onFail) {
  const path = `api/order/GetExchangeOrderDetails?orderId=${id}&lang=en`;
  getResource(path, onSuccess, onFail);
}

export function getSoldOrders(data, onSuccess, onFail) {
  const path = "api/Order/GetSoldOrdersV2?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function SendPurchaseRequestApi(data, onSuccess, onFail) {
  const path = "api/Admin/SendPurchaseRequestFromAdmin?lang=en";
  postResource(path, data, onSuccess, onFail);
}
export function updateOrderStatusApi(data, onSuccess, onFail) {
  const path = "api/Admin/UpdateOrderStatus?lang=en";
  putResource(path, data, onSuccess, onFail);
}
export function makeOfferForItemApi(data, onSuccess, onFail) {
  const path = "api/Item/MakeOfferForItem?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function getPurchaseRequestsApi(onSuccess, onFail) {
  const path = "api/Admin/PurchaseRequests?language=en";
  getResource(path, onSuccess, onFail);
}
export function getAcceptedPurchaseRequestsApi(data, onSuccess, onFail) {
  const path = `api/Admin/PurchaseRequestsAcceptedToAdminV2?language=en`;
  postResource(path, data, onSuccess, onFail);
}
export function getPendingOrdersForAdminApi(data, onSuccess, onFail) {
  const path = `api/Order/GetPendingOrdersFoAdminV2?language=en`;
  postResource(path, data, onSuccess, onFail);
}
export function getOrderStatusApi(onSuccess, onFail) {
  const path = "api/User/OrderStatus?language=en";
  getResource(path, onSuccess, onFail);
}
export function acceptRejectPendingOrderByAdminApi(data, onSuccess, onFail) {
  const path = "api/Admin/AcceptOrRejectPendingOrderByAdmin?lang=en";
  postResource(path, data, onSuccess, onFail);
}
export function acceptRejectOfferByAdminApi(data, onSuccess, onFail) {
  const path = "api/Admin/AcceptOfferByAdminForItem?lang=en";
  postResource(path, data, onSuccess, onFail);
}

/* Start admin */
export function getAdminsApi(onSuccess, onFail) {
  const path = "api/Admin/GetAdmins";
  getResource(path, onSuccess, onFail);
}
export function addAdminApi(data, onSuccess, onFail) {
  const path = "api/Admin/AddAdmin";
  postResource(path, data, onSuccess, onFail);
}
export function editAdmin(data, onSuccess, onFail) {
  const path = `api/Admin/EditAdmin`;
  putResource(path, data, onSuccess, onFail);
}
export function checkAdminStatus(onSuccess, onFail) {
  const path = "api/Admin/CheckAdminStatus";
  getResource(path, onSuccess, onFail);
}
export function getAdminOffers(data, onSuccess, onFail) {
  const path = "api/Admin/GetAdminOffersV2?lang=en";
  postResource(path, data, onSuccess, onFail);
}
export const removeOfferByAdmin = ({ offerId }, onSuccess, onFail) => {
  const path = `api/Admin/RemoveOfferByAdmin?lang=en&offerId=${offerId}`;
  deleteResource(path, onSuccess, onFail);
};
/* End admin */

/* Start users */
export function getUserApi(onSuccess, onFail) {
  const path = "api/User/GetUsers";
  getResource(path, onSuccess, onFail);
}
export function getUserApiV2(data, onSuccess, onFail) {
  const path = "api/User/GetUsersV2";
  postResource(path, data, onSuccess, onFail);
}
export function getUserBalanceForAdminApi(userId, onSuccess, onFail) {
  const path = `api/Order/BalanceInfoGroupedByItemCategoryByUserId?language=en&userId=${userId}`;
  getResource(path, onSuccess, onFail);
}
export function getUserSoldInfoForAdminApi(userId, onSuccess, onFail) {
  const path = `api/Order/SoldInfoGroupedByItemCategoryByUserId?language=en&userId=${userId}`;
  getResource(path, onSuccess, onFail);
}
/* End users */

// Category APIs
export function getCategoriesApi(onSuccess, onFail) {
  const path = "api/Category";
  getResource(path, onSuccess, onFail);
}
export function getActiveCategoriesApi(onSuccess, onFail) {
  const path = "api/Category/GetActiveCategories";
  getResource(path, onSuccess, onFail);
}
export function addCategoryApi(data, onSuccess, onFail) {
  const path = "api/Category";
  postResource(path, data, onSuccess, onFail);
}
export function editCategoryApi(data, onSuccess, onFail) {
  const path = "api/Category";
  putResource(path, data, onSuccess, onFail);
}
export function deleteCategoryApi(data, onSuccess, onFail) {
  const path = `api/Category?id=${data.id}`;
  deleteResource(path, onSuccess, onFail);
}
export function makeOfferForCategoryApi(data, onSuccess, onFail) {
  const path = "api/Item/MakeOfferForCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}

/* Start factory */
export function getFactoryRequestsForAdminApi(onSuccess, onFail) {
  const path = "api/Admin/FactoryRequestsForAdmin";
  getResource(path, onSuccess, onFail);
}
export function getFactoryRequestsApi(onSuccess, onFail) {
  const path = "api/Factory/GetFactoryRequests";
  getResource(path, onSuccess, onFail);
}
export const addFactoryRequestApi = (data, onSuccess, onFail) => {
  const path = "api/Factory/AddFactoryRequest?lang=en";
  postResource(path, data, onSuccess, onFail);
};
export const editFactoryRequestApi = (data, onSuccess, onFail) => {
  const path = "api/Item/Item?lang=en";
  putResource(path, data, onSuccess, onFail);
};
/* End factory */

export function getRoles(onSuccess, onFail) {
  const path = "api/User/GetRoles";
  getResource(path, onSuccess, onFail);
}
export function getSalesPersonApi(onSuccess, onFail) {
  const path = "api/User/GetSalesPerson?lang=en";
  getResource(path, onSuccess, onFail);
}
export function assignUserToSalesPersonApi(data, onSuccess, onFail) {
  const path = "api/User/AssignUserToSalesPerson?lang=en";
  postResource(path, data, onSuccess, onFail);
}
export function endOffer(id, onSuccess, onFail) {
  const path = `api/Factory/EndOfferForFactory?offerId=${id}&lang=en`;
  postResource(path, {}, onSuccess, onFail);
}

/* Start purchase requests */
export function getPaymentRequests(onSuccess, onFail) {
  const path = "api/Admin/GetPaymentRequest";
  getResource(path, onSuccess, onFail);
}
export function answerPaymentRequests(data, onSuccess, onFail) {
  const path = "api/Admin/AnswerPaymentRequestByAdmin";
  postResource(path, data, onSuccess, onFail);
}
/* End purchase requests */

/* Start groups */
export function getGroups(onSuccess, onFail) {
  const path = "api/Group/All?language=en";
  getResource(path, onSuccess, onFail);
}
export function addGroup(data, onSuccess, onFail) {
  const path = "api/Group?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function updateGroup(data, onSuccess, onFail) {
  const path = "api/Group?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function changeGroupStatus(data, onSuccess, onFail) {
  const path = "api/Group/ActivateOrDeactivateGroup?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function getUsersBySalesPerson(id, onSuccess, onFail) {
  const path = `api/User/GetUsersBySalesPerson?salesPersonId=${id}&language=en`;
  getResource(path, onSuccess, onFail);
}
/* End groups */

/* Start auctions */
export function getAuctions(onSuccess, onFail) {
  const path = "api/Auction/All?language=en";
  getResource(path, onSuccess, onFail);
}
export function getAuctionsActiveCategories(onSuccess, onFail) {
  const path = "api/Category/GetActivecategoriesForAuction?language=en";
  getResource(path, onSuccess, onFail);
}
export function addAuction(data, onSuccess, onFail) {
  const path = "api/Auction?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function updateAuction(data, onSuccess, onFail) {
  const path = "api/Auction?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function changeAuctionStatus(data, onSuccess, onFail) {
  const path = "api/Auction/ActivateOrDeactivateAuction?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End auctions */

/* Start auction report */
export function acceptAuctionPayment(data, onSuccess, onFail) {
  const path = "api/Auction/AcceptAuctionPaymentByAdmin?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function getAuctionReport(onSuccess, onFail) {
  const path = "api/Auction/UserEarnedAuctionsForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function sendAuctionDuesToUser(data, onSuccess, onFail) {
  const path = "api/Auction/TransferAuctionToUser?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End auction report */

/* Start lucky hour */
export const getLuckyHourItemsApi = (onSuccess, onFail) => {
  const path = "api/Item/LuckyHourItemsForAdmin?language=en";
  getResource(path, onSuccess, onFail);
};
export const addLuckyHourItemApi = (data, onSuccess, onFail) => {
  const path = "api/Item/LuckyHourItem?language=en";
  postResource(path, data, onSuccess, onFail);
};
export const editLuckyHourItemApi = (data, onSuccess, onFail) => {
  const path = "api/Item/LuckyHourItem?language=en";
  putResource(path, data, onSuccess, onFail);
};
export const deleteLuckyHourItemApi = ({ itemId }, onSuccess, onFail) => {
  const path = `api/Item/LuckyHourItem?language=en&itemId=${itemId}`;
  deleteResource(path, onSuccess, onFail);
};
/* End lucky hour */

export function getSalesRequests(onSuccess, onFail) {
  const path = "api/Dealer/SalesRequestForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
/* End sales requests */

/* Start stores */
export function getActiveStores(onSuccess, onFail) {
  const path = "api/Admin/ActiveStores?language=en";
  getResource(path, onSuccess, onFail);
}
export function getStores(onSuccess, onFail) {
  const path = "api/Admin/StoreForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function addStore(data, onSuccess, onFail) {
  const path = "api/Admin/Store?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function updateStore(data, onSuccess, onFail) {
  const path = "api/Admin/Store?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End stores */

/* Start settings */
export function getSettings(onSuccess, onFail) {
  const path = "api/Setting/AllSettings?language=en";
  getResource(path, onSuccess, onFail);
}
export function editSettings(data, onSuccess, onFail) {
  const path = "api/Setting/Setting?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End settings */

/* Start store items */
export function getStoreItems(data, onSuccess, onFail) {
  const path = "api/GMStore/StoreItemsForAdminV2?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function uploadStoreItemImage(data, onSuccess, onFail) {
  const path = "api/GMStore/AddItemImage?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function addStoreItem(data, onSuccess, onFail) {
  const path = "api/GMStore/AddStoreItemV2?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStoreItem(data, onSuccess, onFail) {
  const path = "api/GMStore/EditStoreItemV2?language=en";
  putResource(path, data, onSuccess, onFail);
}
export const deleteStoreItem = (itemId, onSuccess, onFail) => {
  const path = `api/GMStore/StoreItem?language=en&itemId=${itemId}`;
  deleteResource(path, onSuccess, onFail);
};
export function getStoreOrders(data, onSuccess, onFail) {
  const path = "api/GMStore/StoreOrdersV2?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function submitStoreExchangeDelivery(orderId, onSuccess, onFail) {
  const path = `api/GMStore/SubmitStoreExchangeDelivery?storeExchangeId=${orderId}&language=en`;
  postResource(path, {}, onSuccess, onFail);
}
/* End store items */

/* Start store categories */
export function getStoreCategories(onSuccess, onFail) {
  const path = "api/Category/StoreItemcategoriesForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function getActiveStoreCategories(onSuccess, onFail) {
  const path = "api/Category/StoreItemActiveCategoriesForList?language=en";
  getResource(path, onSuccess, onFail);
}
export function addStoreCategory(data, onSuccess, onFail) {
  const path = "api/Category/AddStoreItemCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStoreCategory(data, onSuccess, onFail) {
  const path = "api/Category/EditStoreItemCategory?language=en";
  putResource(path, data, onSuccess, onFail);
}
export const deleteStoreCategory = (categoryId, onSuccess, onFail) => {
  const path = `api/Category/DeleteStoreItemCategory?language=en&id=${categoryId}`;
  deleteResource(path, onSuccess, onFail);
};

/* End store categories */

/* Start store subcategories */
export function getStoreSubcategories(onSuccess, onFail) {
  const path = "api/SubCategory/StoreItemSubCategoriesForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function getActiveStoreSubcategories(categoryId, onSuccess, onFail) {
  const path = `api/SubCategory/GetActiveSubCategoryByCategoryId?language=en&CategoryId=${categoryId}`;
  getResource(path, onSuccess, onFail);
}
export function addStoreSubcategory(data, onSuccess, onFail) {
  const path = "api/SubCategory/AddStoreItemSubCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStoreSubcategory(data, onSuccess, onFail) {
  const path = "api/SubCategory/EditStoreItemSubCategory?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function deleteStoreSubcategory(subcategoryId, onSuccess, onFail) {
  const path = `api/SubCategory/DeleteStoreItemSubCategory?language=en&id=${subcategoryId}`;
  deleteResource(path, onSuccess, onFail);
}
export function makeCategoryAsSubCategoryOfAnotherCategory(
  data,
  onSuccess,
  onFail
) {
  const path =
    "api/Category/MaleCategoryAsSubCategoryOfAnotherCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}

/* End store subcategories */

/* Start buy and supply item */
export function getBuyItems(onSuccess, onFail) {
  const path = "api/Item/SupplyANdBuyBuyItemsForAdmin?language=en ";
  getResource(path, onSuccess, onFail);
}
export function getSupplyItems(onSuccess, onFail) {
  const path = "api/Item/SupplyANdBuySupplyItemsForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function buyAndSupplyAddBuyItem(data, onSuccess, onFail) {
  const path = "api/Item/SupplyAndBuyBuyItem?language=en ";
  postResource(path, data, onSuccess, onFail);
}
export function buyAndSupplyEditBuyItem(data, onSuccess, onFail) {
  const path = "api/Item/SupplyAndBuyBuyItem?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function buyAndSupplyDeleteBuyItem({ itemId }, onSuccess, onFail) {
  const path = `api/Item/SupplyAndBuyBuyItem?itemId=${itemId}&language=en`;
  deleteResource(path, onSuccess, onFail);
}
export function getbuyItemsPendingOrders(onSuccess, onFail) {
  const path = "api/BuyAndSupply/GetScraptionPendingOrdersForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function acceptOrRejectBuyPendingOrders(data, onSuccess, onFail) {
  const path = "api/BuyAndSupply/AcceptOrRejectPendingOrderByAdmin?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function buyAndSupplyAddSupplyItem(data, onSuccess, onFail) {
  const path = "api/Item/SupplyAndBuySupplyItem?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function buyAndSupplyEditSupplyItem(data, onSuccess, onFail) {
  const path = "api/Item/SupplyAndBuySupplyItem?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function buyAndSupplyDeleteSupplyType({ itemId }, onSuccess, onFail) {
  const path = `api/Item/SupplyAndBuySupplyItem?itemId=${itemId}&language=en`;
  deleteResource(path, onSuccess, onFail);
}
export function getSupplyTypesPendingOrders(onSuccess, onFail) {
  const path = "api/BuyAndSupply/UserBuyAndSupplySuppliesForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function getAcceptedBuyAndSupplyReport(data, onSuccess, onFail) {
  const path = "api/BuyAndSupply/AllBuyOrdersV2?language=en";
  postResource(path, data, onSuccess, onFail);
}

// export function getActiveStoreCategories(onSuccess, onFail) {
//   const path = "api/Category/StoreItemActiveCategoriesForList?language=en";
//   getResource(path, onSuccess, onFail);
// }
// export function addStoreCategory(data, onSuccess, onFail) {
//   const path = "api/Category/AddStoreItemCategory?language=en";
//   postResource(path, data, onSuccess, onFail);
// }
// export function editStoreCategory(data, onSuccess, onFail) {
//   const path = "api/Category/EditStoreItemCategory?language=en";
//   putResource(path, data, onSuccess, onFail);
// }
/* End buy item */

/* Start sales person report */
export function getSalesPersonsReport(onSuccess, onFail) {
  const path = "api/Admin/GetSalesPersonReport?language=en";
  getResource(path, onSuccess, onFail);
}
/* End sales person report */

/* Start merchant store */
export function getMerchantTypes(onSuccess, onFail) {
  const path = "api/Merchant/Types?language=en";
  getResource(path, onSuccess, onFail);
}

export function editTypePriceByMerchant(data, onSuccess, onFail) {
  const path = "api/Merchant/TypePriceByMerchant?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End merchant store */