import ROUTES from "constants/NewRoutes";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store/authorization";
import { emptyDynamicData, updateCurrentPath } from "store/dynamicData";
import { emptyStaticData } from "store/staticData";

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    dispatch(emptyStaticData());
    dispatch(emptyDynamicData());
    dispatch(updateCurrentPath());
    navigate(ROUTES.SIGNIN);
  }, [navigate, dispatch]);
  return <></>;
}

export default Logout;
