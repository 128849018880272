import { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";
import { getMerchantTypes } from "Network";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import UpdatePriceModal from "./UpdatePriceModal";
import ConfirmStatusChangeModal from "./ConfirmStatusChangeModal";

const MerchantStore = () => {
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [openUpdatePriceModal, setOpenUpdatePriceModal] = useState(false);
    const [openConfirmDeactivateModal, setOpenConfirmDeactivateModal] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleEditBtn = (row) => {
        setRowData(row);
        setOpenUpdatePriceModal(true);
    };

    const handleCancel = () => {
        setRowData(null)
        setOpenUpdatePriceModal(false)
        setOpenConfirmDeactivateModal(false)
    };

    const handleStatusChange = (value, row) => {
        if(value) {
            handleEditBtn(row)
        } else {
            setRowData(row)
            setOpenConfirmDeactivateModal(true)
        }
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            key: "name",
            title: "TYPE",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
        },
        {
            key: "perKiloPrice",
            title: "PRICE PER KILO",
            dataIndex: "perKiloPrice",
            ...getColumnSearchProps("perKiloPrice"),
        },
        {
            key: "id",
            title: "STATUS",
            dataIndex: "perKiloPrice",
            render: (_, row) => {
                return (
                    <Switch
                        checked={row.perKiloPrice}
                        onChange={(value) => handleStatusChange(value, row)}
                    />
                )
            }
        },
        {
            key: "id",
            title: "ACTION",
            dataIndex: "action",
            render: (_, row) => {
                return (
                    <Space size={5}>
                        <Button
                            onClick={() => handleEditBtn(row)}
                            style={{ backgroundColor: "green", color: "white" }}
                        >
                            Update
                        </Button>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        getMerchantTypes(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                if (res.data?.error) {
                    toastError(res.message);
                } else {
                    toastError(res.message);
                }
            }
        );
    }, [renderMe]);

    return (
        <section className="users">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header title="Merchant Store" />
                        <SubHeader bgColor="#356ab3" title="All Types" />
                        <div className="users__table store-items__data-table grid-table mrg-ye-50">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"id"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {openUpdatePriceModal && (
                <UpdatePriceModal
                    open={openUpdatePriceModal}
                    rowData={rowData}
                    SubHeader={
                        <SubHeader
                            bgColor="#F58234"
                            title={`Update Price`}
                            closeButton
                            onCancel={handleCancel}
                        />
                    }
                    onSubmit={() => {
                        setRenderMe(!renderMe)
                        handleCancel()
                    }}
                    onCancel={handleCancel}
                />
            )}
            {openConfirmDeactivateModal && (
                <ConfirmStatusChangeModal
                    open={openConfirmDeactivateModal}
                    rowData={rowData}
                    SubHeader={
                        <SubHeader
                            bgColor="#F58234"
                            title={`Deactivate Type`}
                            closeButton
                            onCancel={handleCancel}
                        />
                    }
                    onSubmit={() => {
                        setRenderMe(!renderMe)
                        handleCancel()
                    }}
                    onCancel={handleCancel}
                />
            )}
        </section>
    );
}

export default MerchantStore;