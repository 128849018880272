import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, DatePicker } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";
import { getAcceptedBuyAndSupplyReport } from "Network";
import { exportDataAsExcel } from "helpers/helpfulFunctions";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";

const AcceptedBuyAndSupply = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [isExporting, setIsExporting] = useState(false);
    const [searchValues, setSearchValues] = useState({
        userName: "",
        userMobile: "",
        item: "",
        quantity: "",
        remainingQuantity: "",
        unitPrice: "",
        totalPrice: "",
        paymentType: "",
        from: "",
        to: ""
    })
    const [selectedRange, setSelectedRange] = useState(null);
    const { RangePicker } = DatePicker;
    const searchInput = useRef(null);

    const updateSearchValues = (property, newValue, otherProperty) => {
        const copyOfSearchObj = { ...searchValues }
        copyOfSearchObj[property] = newValue;
        if (otherProperty) {
            copyOfSearchObj[otherProperty.property] = otherProperty.value
        }
        setSearchValues(copyOfSearchObj)
    }

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("YYYY-MM-DD");
            const endDate = dates[1].format("YYYY-MM-DD");
            updateSearchValues("from", startDate, { property: "to", value: endDate })
        } else {
            updateSearchValues("from", "", { property: "to", value: "" })
        }
    };

    const handlePageChange = (page) => {
        if (page !== currentPage) {
            setLoading(true)
        }
        setCurrentPage(page)
    }

    const handlePageSizeChange = (current, newSize) => {
        setLoading(true)
        setPageSize(newSize)
    }

    const handleExportDataToExcel = () => {
        if (isExporting) return;
        setIsExporting(true)
        const baseProps = [
            { title: "Full Name", prop: "fullName" },
            { title: "Phone Number", prop: "phone" },
            { title: "Payment Type", prop: "paymentType" },
            { title: "Item", prop: "item" },
            { title: "Quantity", prop: "quantity" },
            { title: "Remaining Quantity", prop: "remainingQuantity" },
            { title: "Price", prop: "price" },
            { title: "Unit Price", prop: "unitPrice" },
            { title: "Creation Date", prop: "creationDate" },
        ]
        const payload = {
            isExport: true,
            currentPage,
            perPage: pageSize,
            ...searchValues,
        };
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
                delete payload[property]
            }
        }
        getAcceptedBuyAndSupplyReport(
            payload,
            (res) => {
                if (res.success) {
                    exportDataAsExcel(res.data.orders, { baseProps, tab: "Accepted Buy And Supply Report" })
                    setIsExporting(false)
                }
            },
            (res) => {
                setIsExporting(false)
                toastError(res?.data?.error);
            }
        );
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setLoading(true)
        switch (dataIndex) {
            case "fullName":
                updateSearchValues("userName", selectedKeys[0])
                break;
            case "phone":
                updateSearchValues("userMobile", selectedKeys[0])
                break;
            case "item":
                updateSearchValues("item", selectedKeys[0])
                break;
            case "quantity":
                updateSearchValues("quantity", selectedKeys[0])
                break;
            case "remainingQuantity":
                updateSearchValues("remainingQuantity", selectedKeys[0])
                break;
            case "unitPrice":
                updateSearchValues("unitPrice", selectedKeys[0])
                break;
            case "price":
                updateSearchValues("totalPrice", selectedKeys[0])
                break;
            case "paymentType":
                updateSearchValues("paymentType", selectedKeys[0])
                break;
            default:
                break;
        }
        confirm();
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        setLoading(true);
        switch (dataIndex) {
            case "fullName":
                updateSearchValues("userName", "")
                break;
            case "phone":
                updateSearchValues("userMobile", "")
                break;
            case "item":
                updateSearchValues("item", "")
                break;
            case "quantity":
                updateSearchValues("quantity", "")
                break;
            case "remainingQuantity":
                updateSearchValues("remainingQuantity", "")
                break;
            case "unitPrice":
                updateSearchValues("unitPrice", "")
                break;
            case "price":
                updateSearchValues("totalPrice", "")
                break;
            case "paymentType":
                updateSearchValues("paymentType", "")
                break;
            default:
                break;
        }
        clearFilters();
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => true,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const getColumnDateProps = (dataIndex) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={(dates, datesStr) => {
                        setCurrentPage(1)
                        handleDateChange(dates, datesStr)
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const columns = [
        {
            key: "fullName",
            title: "FULL NAME",
            dataIndex: "fullName",
            ...getColumnSearchProps("fullName"),
        },
        {
            key: "phone",
            title: "PHONE NUMBER",
            dataIndex: "phone",
            ...getColumnSearchProps("phone"),
        },
        {
            key: "paymentType",
            title: "PAYMENT TYPE",
            dataIndex: "paymentType",
            ...getColumnSearchProps("paymentType"),
        },
        {
            key: "item",
            title: "ITEM",
            dataIndex: "item",
            ...getColumnSearchProps("item"),
        },
        {
            key: "quantity",
            title: "QUANTITY",
            dataIndex: "quantity",
            ...getColumnSearchProps("quantity"),
        },
        {
            key: "remainingQuantity",
            title: "REMAINING QUANTITY",
            dataIndex: "remainingQuantity",
            ...getColumnSearchProps("remainingQuantity"),
        },
        {
            key: "price",
            title: "PRICE",
            dataIndex: "price",
            ...getColumnSearchProps("price"),
        },
        {
            key: "unitPrice",
            title: "UNIT PRICE",
            dataIndex: "unitPrice",
            ...getColumnSearchProps("unitPrice"),
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
    ];

    useEffect(() => {
        const payload = {
            pageNumber: currentPage,
            perPage: pageSize,
            ...searchValues
        }
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
                delete payload[property]
            }
        }
        getAcceptedBuyAndSupplyReport(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data.orders);
                    setTotalCount(res.data.totalCount);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            }
        );
    }, [searchValues, pageSize, currentPage]);

    return (
        <section className="users auction-report">
            <div className="users__all-contents ">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header title="Accepted Buy And Supply Report" />
                        <SubHeader bgColor="#356ab3" title="Accepted Buy And Supply Report" />
                        <div className="users__table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"id"}
                                allowExport={true}
                                apiExport={true}
                                isExporting={isExporting}
                                onExport={handleExportDataToExcel}
                                title={"Accepted Buy & Supply Report"}
                                currentPage={currentPage}
                                totalCount={totalCount}
                                onPageChange={handlePageChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AcceptedBuyAndSupply;