import { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { getExchangeOrderDetails } from "Network";
import { toastError } from "helpers/toasters";
import SubHeader from "modules/SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";

const ViewExchange = ({ open, onCancel, rowData, type }) => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [totalPrice, setTotalPrice] = useState("");
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedKeys([]);
                            handleSearch([], confirm, dataIndex);
                            return clearFilters && handleReset(clearFilters);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, row) =>
            searchedColumn === dataIndex ?
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                /> : (text),
    });

    const columns = [
        {
            key: "itemName",
            title: "Name",
            dataIndex: "itemName",
            ...getColumnSearchProps("itemName"),
        },
        {
            key: "soldQuantity",
            title: "Exchanged Quantity",
            dataIndex: "soldQuantity",
            ...getColumnSearchProps("soldQuantity"),
        },
        {
            key: "soldUnitPrice",
            title: "Exchanged Price",
            dataIndex: "soldUnitPrice",
            ...getColumnSearchProps("soldUnitPrice"),
        },
        {
            key: "totalPrice",
            title: "Total Price",
            dataIndex: "totalPrice",
            ...getColumnSearchProps("totalPrice"),
        },
    ];

    useEffect(() => {
        if (rowData) {
            if (type === "storeOrder") {
                setLoading(false);
                setTotalPrice(rowData.soldItemsDetailsForStoreExchange.totalExchangeItemsPrice)
                setDataSource(rowData.soldItemsDetailsForStoreExchange.items)
            } else {
                getExchangeOrderDetails({ id: rowData.id }, (res) => {
                    setLoading(false);
                    if (res.success) {
                        setTotalPrice(res.data.totalExchangeItemsPrice)
                        setDataSource(res.data.items)
                    } else {
                        toastError(res.message);
                    }
                }, (res) => {
                    setLoading(false);
                    toastError(res.message);
                })
            }

        }
    }, [rowData, type]);

    return (
        <Modal
            title={
                <SubHeader
                    bgColor="#F58234"
                    title={"Exchanged Items"}
                />
            }
            open={open}
            width={window.innerWidth >= 1024 ? "65%" : "90%"}
            className="msg-modal text-center"
            footer={false}
            closable={true}
            onCancel={onCancel}
        >
            {!loading && dataSource.length > 0 ? (
                <p className="text-start fw-bold fs-5">Total Price: {totalPrice} EGP</p>
            ) : (
                ""
            )}
            <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"itemId"}
                allowExport={false}
                title={""}
            />
        </Modal>);
}

export default ViewExchange;