import { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Button, Input, Space, Select, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  assignUserToSalesPersonApi,
  getSalesPersonApi,
  getUserApi,
} from "Network";

// components
import SubHeader from "../SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import BalanceModal from "./BalanceModal";
import SoldModal from "./SoldModal";

function UsersForSales() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState({});
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [showSoldModal, setShowSoldModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [salesPersons, setSalesPersons] = useState([]);
  const [selectedPersonId, setSelectedPersonId] = useState("");
  const [render, setRender] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const searchInput = useRef(null);
  const exportConfig = {
    tab: `${authorization?.fullName}'s Users Report`,
    baseProps: [
      { title: "Name", prop: "fullName" },
      { title: "Mobile Number", prop: "mobile" },
      { title: "Sales Person", prop: "salesPerson" }
    ]
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const handleAssignSalesPerson = (personId, record) => {
    setSelectedPersonId(personId);
    setSelectedUser(record?.userId);
    setShowModal(true);
  };
  const handleOk = () => {
    const data = {
      userId: selectedUser,
      salesPersonId: selectedPersonId,
    };
    assignUserToSalesPersonApi(
      data,
      (success) => {
        if (success?.success) {
          toastSuccess(success.message);
          setRender((prev) => !prev);
          setShowModal(false);
        } else {
          toastError(success.message);
          setShowModal(false);
        }
      },
      (fail) => {
        toastError(fail.message);
        setShowModal(false);
      }
    );
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const columns = [
    {
      key: "fullName",
      title: "NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "mobile",
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "salesPerson",
      title: "Sales Person",
      dataIndex: "salesPerson",
      hidden: !authorization?.roles.includes(6),
      ...getColumnSearchProps("salesPerson"),
    },
    {
      key: "person",
      title: "Assign user to sales person",
      dataIndex: "person",
      hidden: !authorization?.roles.includes(6),
      width: 200,
      render: (_, record) => (
        <Select
          className="assign-user-to-sales-person"
          allowClear
          getPopupContainer={trigger => trigger.parentElement}
          onChange={(personId) => {
            if (personId) {
              handleAssignSalesPerson(personId, record)
            }
          }
          }
          options={salesPersons}
        />
      ),
    },
    {
      key: "userId",
      title: "ACTION",
      render: (_, row) => authorization.id === row.salesPersonId ? (
        <Space size={5}>
          <Button
            type="primary"
            onClick={() => {
              setRowData(row)
              setShowBalanceModal(true)
            }}>
            View Balance
          </Button>
          <Button
            className="main-btn-green"
            onClick={() => {
              setRowData(row)
              setShowSoldModal(true)
            }}
          >
            View Sold Orders
          </Button>
        </Space>
      ) : (
        ""
      )
    }
  ].filter((col) => !col.hidden);

  useEffect(() => {
    getUserApi(
      (success) => {
        setLoading(false);
        if (success.success) {
          setDataSource(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.err_msg);
      }
    );
    getSalesPersonApi(
      (success) => {
        if (success.success) {
          let persons = [];
          success.data.forEach((person) => {
            persons.push({ value: person?.id, label: person?.name });
          });
          setSalesPersons(persons);
        }
      },
      (fail) => {
        toastError(fail.err_msg);
      }
    );
  }, [render]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Users" />
            <SubHeader bgColor="#356ab3" title="All Users" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"userId"}
                allowExport={true}
                exportConfig={exportConfig}
                title={"Users"}
              />
            </div>
          </div>
        </div>
      </div>
      {showBalanceModal && (
        <BalanceModal
          isModalOpen={showBalanceModal}
          onCancel={() => setShowBalanceModal(false)}
          userId={rowData.userId}
          title={`${rowData.fullName.split(" ")[0]}'s Balance`}
        ></BalanceModal>
      )}
      {showSoldModal && (
        <SoldModal
          isModalOpen={showSoldModal}
          onCancel={() => setShowSoldModal(false)}
          userId={rowData.userId}
          title={`${rowData.fullName.split(" ")[0]}'s Sold Info`}
        ></SoldModal>
      )}
      {showModal && (
        <Modal
          title={"Assign user to Sales person"}
          open={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <span>{`Are you sure you want to assign user to this sales person?`}</span>
        </Modal>
      )}
    </section>
  );
}

export default UsersForSales;
