import { useEffect, useState } from "react";
import { Col, Form, InputNumber, Modal, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { addItemApi, addLuckyHourItemApi, editItemApi, editLuckyHourItemApi, getCategoriesApi } from "Network";
import TextArea from "antd/lib/input/TextArea";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import UploadImage from "modules/SubComponents/UploadImage";

function ItemModal({
  isItemModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
  page
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const [categories, setCategories] = useState(null);
  const [unitValue, setUnitValue] = useState("");
  const [form] = Form.useForm();

  const handlePreviewImgChange = (value) => {
    setPreviewImg(value);
  };

  const handleTotalWeightValidation = (_, value) => {
    const inputValue = parseFloat(value);
    if (mode === "edit" && inputValue === 0) {
      return Promise.resolve()
    } else if (inputValue === 0 || (inputValue < rowData.unitValue || inputValue < unitValue)) {
      return Promise.reject(new Error(`Please, Enter a valid weight`));
    } else {
      return Promise.resolve()
    }
  }

  const handlePriceValidation = (_, value) => {
    const inputValue = parseFloat(value);
    if (inputValue === 0 || (inputValue && (isNaN(inputValue) || inputValue < 1))) {
      return Promise.reject(new Error(`Minimum value is 1 EGP`));
    } else if (inputValue > 10000) {
      return Promise.reject(new Error(`Maximum value is 10,000 EGP`));
    } else {
      return Promise.resolve()
    }
  }

  const handleQuantityValidation = (_, value) => {
    const inputValue = parseFloat(value);
    if (mode === "edit" && inputValue === 0) {
      return Promise.resolve();
    } else if (inputValue === 0 || (inputValue && (isNaN(inputValue) || inputValue < 1))) {
      return Promise.reject(new Error(`Please, Enter a valid quantity`));
    } else {
      return Promise.resolve()
    }
  }

  const handlePreventLetters = (e) => {
    if (e.key === "Backspace" || e.key === "." || (e.key.startsWith("Arrow") && (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight")))) return;
    if (/\D+/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (rowData && mode === "edit") {
      form.setFieldsValue({
        description: rowData.description,
        descriptionAr: rowData.descriptionAr,
        category: rowData.categoryId,
        image: rowData.image,
        pricePerKilo: rowData.itemUnits[0]?.price,
        quantity: rowData.totalUnits,
        totalWeight: rowData.quantityPerKilo,
        isActive: rowData.isActive
      });
      setUnitValue(rowData.unitValue)
    }
  }, [rowData, mode, form]);

  useEffect(() => {
    getCategoriesApi(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.name, unitValue: ele.unitValue };
          });
          setCategories(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        } else {
          toastError(res.message);
        }
      }
    );
  }, []);

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const handleQuantityPerKilo = (quantity, category) => {
    form.setFields([{ name: "totalWeight", errors: [], touched: false }])
    if (quantity === null || !category) {
      return;
    }
    const categoryUnit = categories.find(
      (ele) => ele.value === category
    ).unitValue;
    setUnitValue(categoryUnit)
    // if (categoryUnit === 1 || categoryUnit === 0) {
    //   return;
    // }
    const value = quantity * categoryUnit;
    form.setFieldValue("totalWeight", value.toFixed(3))
  };

  const handleTotalWeightChange = (total, category) => {
    form.setFields([{ name: "quantity", errors: [], touched: false }])
    if (total === null || !category) {
      return;
    }
    const categoryUnit = categories.find(
      (ele) => ele.value === category
    ).unitValue;
    // if (categoryUnit === 1 || categoryUnit === 0) {
    //   return;
    // }
    const quantity = total / categoryUnit;
    form.setFieldValue("quantity", quantity.toFixed(3))
  };

  const addLuckyHourItem = (payload) => {
    addLuckyHourItemApi(
      payload,
      (success) => {
        if (success.success) {
          toastSuccess(success.message);
          handleOnCancel();
        } else {
          setIsModalLoading(false);
          toastError(success.message);
        }
      },
      (fail) => {
        if (fail.data?.error) {
          setIsModalLoading(false);
          toastError(fail.message);
        } else {
          setIsModalLoading(false);
          toastError();
        }
      }
    );
  }

  const editLuckyHourItem = (payload) => {
    editLuckyHourItemApi(
      payload,
      (success) => {
        if (success.success) {
          toastSuccess(success.message);
          handleOnCancel();
        } else {
          toastError(success.message);
          setIsModalLoading(false);
        }
      },
      (fail) => {
        if (fail.data?.error) {
          setIsModalLoading(false);
          toastError(fail.message);
        } else {
          setIsModalLoading(false);
          toastError();
        }
      }
    );
  }

  const addNormalItem = (payload) => {
    addItemApi(
      payload,
      (success) => {
        if (success.success) {
          toastSuccess(success.message);
          handleOnCancel();
        } else {
          setIsModalLoading(false);
          toastError(success.message);
        }
      },
      (fail) => {
        if (fail.data?.error) {
          setIsModalLoading(false);
          toastError(fail.message);
        } else {
          setIsModalLoading(false);
          toastError();
        }
      }
    );
  }

  const editNormalItem = (payload) => {
    editItemApi(
      payload,
      (success) => {
        if (success.success) {
          toastSuccess(success.message);
          handleOnCancel();
        } else {
          toastError(success.message);
          setIsModalLoading(false);
        }
      },
      (fail) => {
        if (fail.data?.error) {
          setIsModalLoading(false);
          toastError(fail.message);
        } else {
          setIsModalLoading(false);
          toastError();
        }
      }
    );
  }

  const handleOnFinish = (values) => {
    if (values) {
      const quantityToSend = unitValue !== 1 ? values.totalWeight : values.quantity;
      const formData = new FormData();
      const data = {
        isActive: values.isActive,
        description: values.description,
        descriptionAr: values.descriptionAr,
        categoryId: values.category,
        quantityPerKilo: quantityToSend,
        itemUnits: [{ price: +values.pricePerKilo, unitId: 1 }],
        image: values.image,
      }
      if (mode === "edit") {
        data.id = rowData?.itemId;
      }

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (key === "itemUnits") {
            for (let i = 0; i < data[key].length; i++) {
              const itemUnit = data[key][i];
              for (const unitKey in itemUnit) {
                if (itemUnit.hasOwnProperty(unitKey)) {
                  formData.append(`${key}[${i}].${unitKey}`, itemUnit[unitKey]);
                }
              }
            }
          } else {
            formData.append(key, data[key]);
          }
        }
      }
      setIsModalLoading(true);
      if (mode === "add") {
        if (page === "lucky-hour") {
          addLuckyHourItem(formData)
        } else {
          addNormalItem(formData)
        }
      } else {
        if (page === "lucky-hour") {
          editLuckyHourItem(formData)
        } else {
          editNormalItem(formData)
        }
      }
    }
  };

  return (
    isItemModalVisible && (
      <Modal
        title={SubHeader}
        open={isItemModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={false}
        centered
        destroyOnClose
      >
        <Form
          form={form}
          className="edit-actor__content"
          onFinish={handleOnFinish}
          initialValues={{
            description: "",
            category: undefined,
            image: "",
            pricePerKilo: "",
            quantity: "",
            isActive: true,
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Description in English
                </label>
                <Form.Item
                  className=" new-category__form-item "
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the description",
                    },
                  ]}
                >
                  <TextArea
                    type="text"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    className="search__searchField py-2"
                    placeholder="Description"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Description In Arabic
                </label>
                <Form.Item
                  className=" new-category__form-item "
                  name="descriptionAr"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the description",
                    },
                  ]}
                >
                  <TextArea
                    type="text"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    className="search__searchField py-2"
                    placeholder="Description"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a type",
                    },
                  ]}
                >
                  <Select
                    disabled={mode === "edit"}
                    placeholder="Select a type"
                    loading={categories ? false : true}
                    options={categories ? categories : []}
                    onChange={(value) =>
                      handleQuantityPerKilo(
                        form.getFieldValue("quantity"),
                        value
                      )
                    }
                    getPopupContainer={trigger => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              {" "}
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Price per Kilo
                  </label>
                </div>
                <Form.Item
                  className="new-category__form-item"
                  name="pricePerKilo"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the price per kilo",
                    },
                    { validator: handlePriceValidation }
                  ]}
                >
                  <InputNumber
                    // min={1}
                    className="w-100 d-block search__searchField py-2"
                    placeholder="Enter Price per kilo"
                    onKeyDown={handlePreventLetters}
                    // disabled={mode === "edit" ? true : false}
                    controls={false}
                    keyboard={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name mb-4 row mx-0 align-items-center">
                <div className="col-4 px-0 text-start">
                  <label className="users__parent-info-label">Active</label>
                </div>
                <div className="col-8 px-0 d-flex">
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    className="mb-0"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Quantity
                  </label>
                </div>
                <Form.Item
                  className="new-category__form-item"
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter quantity",
                    },
                    { validator: handleQuantityValidation }
                  ]}
                >
                  {/* {mode === "add" ?
                    <Input
                      type="number"
                      min={1}
                      className="search__searchField py-2"
                      placeholder="Enter quantity"
                      onChange={(e) => {
                        if (e.target.value) {
                          form.setFieldValue(
                            "quantity",
                            Math.floor(e.target.value)
                          );
                          handleQuantityPerKilo(
                            e.target.value,
                            form.getFieldValue("category")
                          );
                        }
                      }}
                    /> : */}
                  <InputNumber
                    min={0}
                    className="w-100 d-block search__searchField py-2"
                    placeholder="Enter quantity"
                    onKeyDown={handlePreventLetters}
                    controls={false}
                    keyboard={false}
                    onChange={(value) => {
                      if (value !== undefined && value !== null) {
                        handleQuantityPerKilo(value, form.getFieldValue("category")
                        );
                      }
                    }}
                  />
                  {/* } */}
                </Form.Item>
              </div>
            </Col>
            {mode === "edit" || (unitValue && unitValue !== 1) || (rowData?.unitValue && rowData?.unitValue !== 1 && rowData?.unitValue !== 0) ? (
              <Col span={24}>
                <div className="new-actor__name new-category mb-5">
                  <div className="lang-name wd-100 flex">
                    <label className="users__parent-info-label mb-2" htmlFor="">
                      Total Weight
                    </label>
                  </div>
                  <Form.Item
                    className="new-category__form-item"
                    name="totalWeight"
                    rules={[
                      { validator: handleTotalWeightValidation }
                    ]}
                  >
                    <InputNumber
                      min={0}
                      className="w-100 d-block search__searchField py-2"
                      placeholder="Enter Total weight"
                      onKeyDown={handlePreventLetters}
                      controls={false}
                      // disabled={rowData.unitValue === 1 || unitValue === 1}
                      onChange={(value) => {
                        // if (value !== null && value !== undefined && rowData.unitValue !== 1) {
                        if (value !== null && value !== undefined) {
                          handleTotalWeightChange(value, form.getFieldValue("category")
                          );
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>)
              : ""}
            { /* ) : quantityPerKilo ? (
              <Col span={24}>
                <div className="new-actor__name new-category mb-5">
                  <div className="lang-name wd-100 flex">
                    <label className="users__parent-info-label mb-2" htmlFor="">
                      Total Weight
                    </label>
                  </div>
                  <div className="new-category__form-item">
                    <p className="search__searchField py-2 text-start ps-3">
                      {quantityPerKilo}
                    </p>
                  </div>
                </div>
              </Col>
            ) : ""} */}
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Image
                  </label>
                </div>
                <UploadImage
                  mode={mode}
                  image={rowData?.image}
                  formRef={form}
                  previewImage={previewImg}
                  onPreviewCoverChange={handlePreviewImgChange}
                  acceptGIF={true}
                  title={"Upload Image +"}
                />
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default ItemModal;
