import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  addStoreSubcategory,
  editStoreSubcategory,
  getActiveStoreCategories,
} from "Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AddEditStoreSubcategory = ({
  isSubcategoryModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onSubmit,
  onCancel,
}) => {
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const initialValues = {
    nameEn: "",
    nameAr: "",
    category: undefined,
    isActive: true,
  };

  const handleAddSubcategory = (payload) => {
    addStoreSubcategory(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  const handleEditSubcategory = (payload) => {
    editStoreSubcategory(
      payload,
      (success) => {
        setSubmitting(false);
        if (success.success) {
          toastSuccess(success.message);
          onSubmit();
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        setSubmitting(false);
        toastError(fail.message);
      }
    );
  };

  const handleOnFinish = (values) => {
    if (submitting) return;
    setSubmitting(true);

    const payload = {
      name: values.nameEn.trim(),
      nameAr: values.nameAr.trim(),
      categoryId: selectedCategory,
      isActive: values.isActive,
    };

    if (mode === "add") {
      handleAddSubcategory(payload);
    } else {
      payload.subCategoryId = rowData.id;
      handleEditSubcategory(payload);
    }
  };

  useEffect(() => {
    if (rowData && mode === "edit") {
      form.setFieldsValue({
        nameEn: rowData.nameEn,
        nameAr: rowData.nameAr,
        isActive: rowData.isActive,
      });
      setSelectedCategory(rowData.categoryId)
    }
  }, [rowData, mode, form]);

  useEffect(() => {
    getActiveStoreCategories(
      (res) => {
        if (res.success) {
          const options = res.data.map((category) => {
            return { value: category.id, label: category.name };
          });
          setCategories(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!rowData || !categories || mode !== "edit") return;
    const categoryExists = categories.find((category) => category.value === rowData.categoryId)
    if (!categoryExists) {
      form.setFieldValue("category", rowData.category)
    } else {
      form.setFieldValue("category", rowData.categoryId)
    }
  }, [rowData, categories, mode])

  return (
    <Modal
      title={SubHeader}
      open={isSubcategoryModalVisible}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        className="edit-actor__content store-items__item-form"
        onFinish={handleOnFinish}
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Name in English
              </label>
              <Form.Item
                className=" new-category__form-item"
                name="nameEn"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, Enter Subcategory name in English",
                  },
                  {
                    pattern:
                      /^[a-zA-Z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]$/,
                    message: "Please, Enter Subcategory name in English",
                  },
                  {
                    max: 50,
                    message: "Maximum length is 50 characters",
                  },
                  {
                    min: 3,
                    message: "Minimum length is 3 characters",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder="Enter Name in English"
                //   maxLength={50}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Name in Arabic
              </label>
              <Form.Item
                className=" new-category__form-item"
                name="nameAr"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, Enter Subcategory name in Arabic",
                  },
                  {
                    pattern:
                      /^[\u0600-\u06FF][\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]*[\u0600-\u06FF\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]$/,
                    message: "Please, Enter Subcategory name in Arabic",
                  },
                  {
                    max: 50,
                    message: "Maximum length is 50 characters",
                  },
                  {
                    min: 3,
                    message: "Minimum length is 3 characters",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder="Enter Name in Arabic"
                //   maxLength={50}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Category
              </label>
              <Form.Item
                className="new-category__form-item"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please, Select the category",
                  },
                ]}
              >
                <Select
                  placeholder="Select category"
                  loading={categories ? false : true}
                  options={categories ? categories : []}
                  onChange={(value) => setSelectedCategory(value)}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name mb-4 row mx-0 align-items-center">
              <div className="col-4 px-0 text-start">
                <label className="users__parent-info-label">Active</label>
              </div>
              <div className="col-8 px-0 d-flex">
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  className="mb-0"
                >
                  <Switch />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mrg-ys-20 mrg-x-auto">
          <Space size={20}>
            <SecondaryButton buttonName={secondaryBtnName} onClick={onCancel} />
            <PrimaryButton
              buttonName={primaryBtnName}
              loading={submitting}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEditStoreSubcategory;
