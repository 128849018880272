import { useState } from "react";
import { Form, Modal, Row, Space } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { acceptAuctionPayment } from "Network";

import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";

const AcceptPaymentModal = ({
    open,
    onCancel,
    onSubmit,
    rowData,
    SubHeader
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {
        if (isSubmitting) return
        setIsSubmitting(true)

        const payload = {
            auctionId: rowData.auctionId,
        };

        acceptAuctionPayment(
            payload,
            (res) => {
                setIsSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setIsSubmitting(false)
                toastError(res.message);
            }
        );
    }

    return (
        <Modal
            title={SubHeader}
            closable={false}
            open={open}
            footer={false}
            onCancel={onCancel}
            centered
            className="msg-modal text-center"
        >
            <p className="text-center">You are about to accept the auction payment</p>
            <Form
                name="form"
                className="edit-actor__content mb-0"
                onFinish={handleSubmit}
            >
                <Row className="mrg-ys-20 mrg-x-auto">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName="Accept"
                            loading={isSubmitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default AcceptPaymentModal;