import ROUTES from "constants/NewRoutes";
import { useAuth } from "hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoutes = ({ allowedRoles }) => {
  const { isAuthorized, adminRoles } = useAuth();

  return adminRoles?.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : isAuthorized ? (
    <Navigate to={ROUTES.NOT_HAVE_ACCESS} replace />
  ) : (
    <Navigate to={ROUTES.SIGNIN} replace />
  );
};
