import { useState, useEffect } from "react";
import { Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { doc, updateDoc, increment } from "firebase/firestore";
import {
  addFactoryRequestApi,
  getCategoriesApi,
  makeOfferForCategoryApi,
} from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import db from "../../../firebase";

const FactoryRequestModal = ({
  isItemModalVisible,
  mode,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [form] = Form.useForm();

  const handleNumberValidation = (_, value) => {
    if (value && (isNaN(+value) || +value < 1)) {
      return Promise.reject(new Error("Please, enter a valid price"));
    } else {
      return Promise.resolve();
    }
  };

  const handlePreventLetters = (e) => {
    if (e.key === "Backspace" || e.key === "." || e.key.startsWith("Arrow"))
      return;
    if (/\D+/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getCategoriesApi(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.name };
          });
          setCategories(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        } else {
          toastError(res.message);
        }
      }
    );
  }, []);

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const updateFireStore = async (data) => {
    await data.forEach(async (user) => {
      updateDoc(doc(db, `users`, user), {
        unReadMessagesCount: increment(1),
      });
    });
  };

  const handleOnFinish = (values) => {
    if (values) {
      const data = {
        ...values,
        pricePerKilo: +values.pricePerKilo,
      };
      setIsModalLoading(true);

      makeOfferForCategoryApi(
        data,
        (res2) => {
          setIsModalLoading(false);
          if (res2?.success) {
            toastSuccess(res2.message);
            updateFireStore(res2.data);
            handleOnCancel();
          } else {
            toastError(res2.message);
          }
        },
        (fail) => {
          setIsModalLoading(false);
          toastError(fail.message);
        }
      );
    }
  };

  return (
    isItemModalVisible && (
      <Modal
        title={SubHeader}
        open={isItemModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={false}
      >
        <Form
          form={form}
          className="edit-actor__content  "
          onFinish={handleOnFinish}
          initialValues={{
            description: "",
            category: undefined,
            image: "",
            pricePerKilo: "",
            quantity: "",
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a type"
                    loading={categories ? false : true}
                    options={categories ? categories : []}
                    getPopupContainer={trigger => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Price per Kilo
                  </label>
                </div>
                <Form.Item
                  className=" new-category__form-item "
                  name="pricePerKilo"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the price per kilo",
                    },
                    { validator: handleNumberValidation },
                  ]}
                >
                  <InputNumber
                    min={1}
                    className="w-100 d-block search__searchField"
                    placeholder="Enter Price per kilo"
                    onKeyDown={handlePreventLetters}
                    controls={false}
                  />
                </Form.Item>
              </div>
            </Col>

            {/* <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Quantity
                  </label>
                </div>
                <Form.Item
                  className=" new-category__form-item "
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter quantity",
                    },
                    { validator: handleNumberValidation }
                  ]}
                >
                  <Input
                    type="number"
                    min={mode === "edit" ? rowData?.quantityPerKilo : 1}
                    className="search__searchField py-2"
                    placeholder="Enter quantity"
                    disabled={mode === "edit" ? true : false}
                  />
                </Form.Item>
              </div>
            </Col> */}
          </Row>

          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
};

export default FactoryRequestModal;
