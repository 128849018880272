import { useState } from "react";
import { Form, Modal, Row, Space } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { editTypePriceByMerchant } from "Network";

import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const ConfirmStatusChangeModal = ({
    open,
    rowData,
    SubHeader,
    onCancel,
    onSubmit,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();

    const handleFinish = () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const payload = {
            typeId: rowData.id,
            pricePerKilo: 0
        }

        editTypePriceByMerchant(
            payload,
            (res) => {
                setIsSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message)
                    onSubmit();
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setIsSubmitting(false);
                toastError(res.message);
            }
        )
    }

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                onFinish={handleFinish}
            >
                <p className="text-center">Are you sure you want to deactivate '{rowData?.name}'?</p>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            type="submit"
                            loading={isSubmitting}
                            buttonName="Confirm"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ConfirmStatusChangeModal;