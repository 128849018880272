import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getSalesPersonsReport } from "../../Network";
import { toastError } from "../../helpers/toasters";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "modules/SubComponents/SubHeader";
import Header from "modules/SubComponents/Header";

const SalesPersonReport = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const exportConfig = {
        tab: `Sales Person Report`,
        baseProps: [
            { title: "Sales Person", prop: "name" },
            { title: "Cash In", prop: "cashIn" },
            { title: "Cash Out", prop: "cashOut" },
            { title: "Difference", prop: "remaining" },
        ]
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={close}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || text === 0 ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: "name",
            title: "SALES PERSON",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
        },
        {
            key: "cashIn",
            title: "CASH IN",
            dataIndex: "cashIn",
            ...getColumnSearchProps("cashIn"),
        },
        {
            key: "cashOut",
            title: "CASH OUT",
            dataIndex: "cashOut",
            ...getColumnSearchProps("cashOut"),
        },
        {
            key: "remaining",
            title: "DIFFERENCE",
            dataIndex: "remaining",
            ...getColumnSearchProps("remaining"),
        },
    ]

    useEffect(() => {
        getSalesPersonsReport(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res?.data?.error);
            })
    }, [])

    return (
        <section className="users sales-requests">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header title="Sales Person Report" />
                        <SubHeader bgColor="#356ab3" title="All Sales Person Report" />
                        <div className="users__table grid-table mrg-y-30">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"salesRequestId"}
                                allowExport={true}
                                exportConfig={exportConfig}
                                title={"Sales Person Report"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SalesPersonReport;