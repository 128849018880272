import { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Modal, Row, Space, DatePicker, Select, Switch } from "antd";
import moment from "moment";
import { toastError, toastSuccess } from "helpers/toasters";
import { addAuction, updateAuction, getAuctionsActiveCategories } from "Network";
import { handleAllowNumbersOnly } from "helpers/helpfulFunctions";
import { doc, increment, setDoc } from "firebase/firestore";
import db from "../../firebase";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AuctionModal = ({
    isAuctionModalVisible,
    mode,
    rowData,
    SubHeader,
    onCancel,
}) => {
    const [types, setTypes] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        nameEn: "",
        nameAr: "",
        type: undefined,
        minBidding: "",
        quantity: "",
        maxQuantity: "",
        increment: "",
        startDate: "",
        isActive: true,
    }

    const validateIncrement = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject("Please, Enter an increment value")
        } else if (isNaN(value) || value !== parseInt(value)) {
            return Promise.reject("Please, Enter a valid increment value")
        } else if (value <= 0) {
            return Promise.reject("Minimum value should be greater than 0")
        } else if (value > 10000) {
            return Promise.reject("Maximum value should be less than or equal to 10000")
        } else {
            return Promise.resolve()
        }
    }

    const validateMinBidding = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject("Please, Enter the minimum bidding price")
        } else if (isNaN(value) || value !== parseInt(value)) {
            return Promise.reject("Please, Enter a valid minimum bidding price")
        } else if (value <= 0) {
            return Promise.reject("Minimum value should be greater than 0")
        } else if (value > 1000000) {
            return Promise.reject("Maximum value should be less than or equal to one million")
        } else {
            return Promise.resolve()
        }
    }

    const validateQuantity = (_, value) => {
        if ((value || value === 0) && form.getFieldValue("maxQuantity") && !form.isFieldValidating("maxQuantity")) {
            form.validateFields(["maxQuantity"])
        }
        if (!value && value !== 0) {
            return Promise.reject("Please, Enter the quantity in kilo")
        } else if (value <= 0) {
            return Promise.reject("Minimum value should be greater than 0")
        } else if (value > 1000000) {
            return Promise.reject("Maximum value should be less than or equal to one million of kilo")
        } else {
            return Promise.resolve()
        }
    }

    const validateMaxQuantity = (_, value) => {
        let quantity = form.getFieldValue("quantity")
        if (!value && value !== 0) {
            return Promise.reject("Please, Enter the maximum quantity per user")
        } else if (value <= 0) {
            return Promise.reject("Minimum value should be greater than 0")
        } else if (quantity && value > quantity) {
            return Promise.reject("Maximum value should be less than or equal to the Quantity")
        } else {
            return Promise.resolve()
        }
    }

    const updateFireStore = (data) => {
        data.forEach((user) => {
            setDoc(doc(db, "users", user), {
                auctionNotifications: increment(1),
            }, { merge: true });
        });
    };

    const handleFinish = (values) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const payload = {
            nameAr: values.nameAr,
            nameEn: values.nameEn,
            minimumBidding: values.minBidding,
            incrementValue: values.increment,
            startDate: moment(values.startDate).toISOString(true).split(".")[0],
            typeId: values.type,
            maximumAuctionAmountInKilo: values.quantity,
            maximumAuctionAmountForUserInKilo: values.maxQuantity,
            isActive: values.isActive,
        }

        if (mode === "edit") {
            payload.id = rowData.id
            delete payload.typeId
        }

        if (mode === "add") {
            addAuction(
                payload,
                (res) => {
                    setIsSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        updateFireStore(res.data)
                        onCancel();
                    } else {
                        toastError(res.message);
                    }
                },
                (fail) => {
                    setIsSubmitting(false);
                    if (fail.data?.error) {
                        toastError(fail.message);
                    } else {
                        toastError();
                    }
                }
            );
        } else {
            updateAuction(
                payload,
                (res) => {
                    setIsSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onCancel();
                    } else {
                        toastError(res.message);
                    }
                },
                (fail) => {
                    setIsSubmitting(false);
                    if (fail.data?.error) {
                        toastError(fail.message);
                    } else {
                        toastError();
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (rowData && mode !== "add") {
            form.setFieldsValue({
                nameEn: rowData.nameEn,
                nameAr: rowData.nameAr,
                minBidding: rowData.minimumBidding,
                increment: rowData.incrementValue,
                startDate: moment(new Date(rowData.startDate)),
                type: rowData.typeId,
                quantity: rowData.maximumAuctionAmountInKilo,
                maxQuantity: rowData.maximumAuctionAmountForUserInKilo,
                isActive: rowData.isActive
            })
        }
    }, [mode, rowData, form])

    useEffect(() => {
        getAuctionsActiveCategories(
            (res) => {
                if (res.success) {
                    const options = res.data.map((category) => {
                        return { value: category.id, label: category.name };
                    })
                    setTypes(options);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [])

    return (isAuctionModalVisible && (
        <Modal
            title={SubHeader}
            open={isAuctionModalVisible}
            className="msg-modal text-center auction-modal"
            footer={false}
            closable={false}
            centered
        >
            <Form
                form={form}
                name="form"
                className="edit-actor__content"
                initialValues={initialValues}
                onFinish={handleFinish}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (EN)
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="nameEn"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the name in English",
                                    },
                                    {
                                        pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]+$/,
                                        message: "Please, Enter a valid auction name in English",
                                    },
                                    {
                                        min: 2,
                                        message: "Name must be at least 2 characters long"
                                    },
                                    {
                                        max: 50,
                                        message: "Name cannot exceed 50 characters"
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter name in English"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (AR)
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="nameAr"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the name in Arabic",
                                    },
                                    {
                                        pattern: /^[\u0621-\u064A\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~`]+$/,
                                        message: "Please, Enter a valid auction name in Arabic",
                                    },
                                    {
                                        min: 2,
                                        message: "Name must be at least 2 characters long"
                                    },
                                    {
                                        max: 50,
                                        message: "Maximum length is 50 characters"
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter name in Arabic"
                                    dir={"rtl"}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Type
                            </label>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Select the type",
                                    },
                                ]}
                                name="type"

                                className="new-category__form-item"
                            >
                                <Select
                                    name="type"
                                    className="new-category__form-item auction-modal__select-holder"
                                    placeholder="Select type"
                                    loading={types ? false : true}
                                    options={types ? types : []}
                                    disabled={mode !== "add"}
                                    getPopupContainer={trigger => trigger.parentElement}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Quantity in kilo
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item w-100"
                                name="quantity"
                                rules={[
                                    { validator: validateQuantity }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField auction-modal__quantity-field"
                                    placeholder="Enter quantity"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                    addonAfter={"Kilo"}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Maximum Quantity per user in kilo
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item w-100"
                                name="maxQuantity"
                                rules={[
                                    { validator: validateMaxQuantity }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter max quantity"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Minimum Bidding
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item w-100"
                                name="minBidding"
                                rules={[
                                    { validator: validateMinBidding }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter minimum bidding"
                                    onKeyDown={handleAllowNumbersOnly}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Increment Value
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item w-100"
                                name="increment"
                                rules={[
                                    { validator: validateIncrement }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter increment value"
                                    onKeyDown={handleAllowNumbersOnly}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24} className="mb-3">
                        <div className="new-actor__name new-category">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Start date
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item d-flex justify-content-start mb-2"
                                name="startDate"
                                rules={[
                                    { required: true, message: "Please, Select the start date" }
                                ]}
                            >
                                <DatePicker
                                    className="w-100"
                                    showTime={{
                                        defaultValue: moment("00:00:00", "HH:mm:ss"),
                                        format: "HH:mm"
                                    }}
                                    showSecond={false}
                                    disabledDate={(current) => current.isBefore(new Date())}
                                    onKeyDown={(e) => e.preventDefault()}
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Active</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={mode === "add" ? "Add" : "Edit"}
                            loading={isSubmitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    ));
}

export default AuctionModal;