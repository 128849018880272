import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Space, Switch, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { toastError, toastSuccess } from "helpers/toasters";
import { addStoreCategory, editStoreCategory } from "Network";
import { baseUrl } from "services";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const StoreCategoryForm = ({
    isCategoryModalVisible,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onSubmit,
    onCancel
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [categoryImage, setCategoryImage] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [form] = Form.useForm();
    const initialValues = {
        nameEn: "",
        nameAr: "",
        image: "",
        isActive: true,
    }
    const props = {
        name: 'image',
        accept: ".png,.jpg,.jpeg,.bmp,.gif,.png,.svg",
        maxCount: 1,
        showUploadList: false,
        onChange(info) {
            if (!info.file.type.startsWith("image")) {
                handleRemoveImage();
                return;
            }
            handlePreview(info.file)
            form.setFields([{ name: "image", value: info.file, errors: [] }])
        },
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        setCategoryImage(file.originFileObj);
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleRemoveImage = () => {
        setPreviewImage("");
        form.resetFields(["image"])
        setCategoryImage("");
    };

    const addCategory = (payload) => {
        addStoreCategory(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    }

    const editCategory = (payload) => {
        editStoreCategory(
            payload,
            (success) => {
                setSubmitting(false);
                if (success.success) {
                    toastSuccess(success.message);
                    onSubmit();
                } else {
                    toastError(success.message);
                }
            },
            (fail) => {
                setSubmitting(false);
                toastError(fail.message);
            }
        );
    }

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const formData = new FormData();
        const payload = {
            Name: values.nameEn.trim(),
            NameAr: values.nameAr.trim(),
            IsActive: values.isActive,
            Image: categoryImage
        }
        if (mode === "edit") {
            payload.Id = rowData.id;
        }
        if (categoryImage) {
            payload.Image = categoryImage
        }
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                formData.append(key, payload[key]);
            }
        }
        setSubmitting(true);
        if (mode === "add") {
            addCategory(formData)
        } else {
            editCategory(formData)
        }
    };

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                nameEn: rowData.nameEn,
                nameAr: rowData.nameAr,
                image: rowData.image,
                isActive: rowData.isActive,
            });
        }
    }, [rowData, mode, form]);

    return (
        isCategoryModalVisible && (
            <Modal
                title={SubHeader}
                open={isCategoryModalVisible}
                className="msg-modal"
                footer={false}
                closable={false}
                centered
                destroyOnClose
            >
                <Form
                    form={form}
                    className="edit-actor__content store-items__item-form"
                    onFinish={handleOnFinish}
                    initialValues={initialValues}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Category Image
                                    </label>
                                </div>
                                {previewImage ? (
                                    <img src={previewImage} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                                ) : mode === "edit" ? (
                                    <img src={baseUrl + rowData?.image} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                                ) : (
                                    ""
                                )}
                                <Form.Item
                                    name="image"
                                    rules={[{ required: true, message: "Please, Upload the category image" }]}
                                    className="w-100"
                                >
                                    <ImgCrop cropperProps={{ restrictPosition: false }} fillColor="transparent" quality={1} rotationSlider aspectSlider minZoom={0.5}>
                                        <Upload {...props}>
                                            Upload Image +
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Name in English
                                </label>
                                <Form.Item
                                    className=" new-category__form-item"
                                    name="nameEn"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the category name in English",
                                        },
                                        {
                                            max: 30,
                                            message: "Maximum length is 30 characters"
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                        {
                                            pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]$/,
                                            message: "Please, Enter the category name in English",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter Name in English"
                                        maxLength={30}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Name in Arabic
                                </label>
                                <Form.Item
                                    className=" new-category__form-item"
                                    name="nameAr"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the category name in Arabic",
                                        },
                                        {
                                            max: 30,
                                            message: "Maximum length is 30 characters"
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                        {
                                            pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]+$/,
                                            message: "Please, Enter the category name in Arabic",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter Name in Arabic"
                                        maxLength={30}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name mb-4 row mx-0 align-items-center">
                                <div className="col-4 px-0 text-start">
                                    <label className="users__parent-info-label">Active</label>
                                </div>
                                <div className="col-8 px-0 d-flex">
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mrg-ys-20 mrg-x-auto">
                        <Space size={20}>
                            <SecondaryButton
                                buttonName={secondaryBtnName}
                                onClick={onCancel}
                            />
                            <PrimaryButton
                                buttonName={primaryBtnName}
                                loading={submitting}
                                type="submit"
                            />
                        </Space>
                    </Row>
                </Form>
            </Modal >
        ));
}

export default StoreCategoryForm;