import { useState, useEffect, useRef } from "react";
// import { useSelector } from "react-redux";
import { Button, DatePicker, Input, Modal, Space } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { acceptRejectOfferByAdminApi, getAcceptedPurchaseRequestsApi } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import { exportDataAsExcel } from "helpers/helpfulFunctions";
import { baseUrl } from "services";

import SubHeader from "../SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";

function PendingOffers() {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [storeItemModalOpen, setStoreItemModalOpen] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    userName: "",
    userMobile: "",
    type: "",
    quantity: "",
    price: "",
    totalPriceAfterCommission: "",
    cashFromUser: "",
    cashToUser: "",
    moneyCollectionType: "",
    from: "",
    to: "",
    sellingDateFrom: "",
    sellingDateTo: "",
    offeredBy: "",
  })
  const [sellingDateRange, setSellingDateRange] = useState([]);
  const [creationDateRange, setCreationDateRange] = useState([]);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);
  // const { authorization } = useSelector((state) => state.auth);

  const handleTableChange = (_, filters) => {
    if (filters.moneyCollectionType) {
      if (filters.moneyCollectionType.length > 1) {
        updateSearchValues("moneyCollectionType", "")
      } else {
        updateSearchValues("moneyCollectionType", filters.moneyCollectionType[0])
      }
    } else if (searchValues.moneyCollectionType) {
      updateSearchValues("moneyCollectionType", "")
    }
  }

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues }
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }

  const handleDateChange = (dates, dataIndex) => {
    if (dataIndex === "offerDate") {
      setCreationDateRange(dates);
    } else {
      setSellingDateRange(dates)
    }
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      if (dataIndex === "offerDate") {
        updateSearchValues("from", startDate, { property: "to", value: endDate })
      } else {
        updateSearchValues("sellingDateFrom", startDate, { property: "sellingDateTo", value: endDate })
      }
    } else {
      if (dataIndex === "offerDate") {
        updateSearchValues("from", "", { property: "to", value: "" })
      } else {
        updateSearchValues("sellingDateFrom", "", { property: "sellingDateTo", value: "" })
      }
    }
  };

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setLoading(true)
    }
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Name", prop: "fullName" },
      { title: "Mobile", prop: "phone" },
      { title: "Type", prop: "categoryName" },
      { title: "Sealed Quantity", prop: "sealedQuantity" },
      { title: "Offer Price", prop: "offeredPricePerKilo" },
      { title: "Total Price (After Commission)", prop: "totalOfferPriceAfterCommission" },
      { title: "Collection Type", prop: "moneyCollectionType" },
      { title: "Cash From User", prop: "cashFromUser" },
      { title: "Cash To User", prop: "cashToUser" },
      { title: "Selling Date", prop: "sellingDate" },
      { title: "Creation Date", prop: "offerDate" },
      { title: "Offered By", prop: "offerdBy" },
    ]
    const payload = {
      isExport: true,
      currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
        delete payload[property]
      }
    }
    getAcceptedPurchaseRequestsApi(
      payload,
      (res) => {
        if (res.success) {
          exportDataAsExcel(res.data.response, { baseProps, tab: "Pending Offers Report" })
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setLoading(true)
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", selectedKeys[0])
        break;
      case "phone":
        updateSearchValues("userMobile", selectedKeys[0])
        break;
      case "categoryName":
        updateSearchValues("type", selectedKeys[0])
        break;
      case "sealedQuantity":
        updateSearchValues("quantity", selectedKeys[0])
        break;
      case "offeredPricePerKilo":
        updateSearchValues("price", selectedKeys[0])
        break;
      case "totalOfferPriceAfterCommission":
        updateSearchValues("totalPriceAfterCommission", selectedKeys[0])
        break;
      case "cashFromUser":
        updateSearchValues("cashFromUser", selectedKeys[0])
        break;
      case "cashToUser":
        updateSearchValues("cashToUser", selectedKeys[0])
        break;
      case "moneyCollectionType":
        updateSearchValues("moneyCollectionType", selectedKeys[0])
        break;
      case "offeredBy":
        updateSearchValues("offeredBy", selectedKeys[0])
        break;
      default:
        break;
    }
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    setLoading(true);
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", "")
        break;
      case "phone":
        updateSearchValues("userMobile", "")
        break;
      case "categoryName":
        updateSearchValues("type", "")
        break;
      case "sealedQuantity":
        updateSearchValues("quantity", "")
        break;
      case "offeredPricePerKilo":
        updateSearchValues("price", "")
        break;
      case "totalOfferPriceAfterCommission":
        updateSearchValues("totalPriceAfterCommission", "")
        break;
      case "cashFromUser":
        updateSearchValues("cashFromUser", "")
        break;
      case "cashToUser":
        updateSearchValues("cashToUser", "")
        break;
      case "moneyCollectionType":
        updateSearchValues("moneyCollectionType", "")
        break;
      case "offeredBy":
        updateSearchValues("offeredBy", "")
        break;
      default:
        break;
    }
    clearFilters();
    confirm()
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnDateProps = (dataIndex) => {
    const dateValue = dataIndex === "offerDate" ? creationDateRange : sellingDateRange;
    return ({
      filterDropdown: () => (
        <div
          style={{
            padding: 8,
          }}
          className="range-picker-holder"
          onKeyDown={(e) => e.stopPropagation()}
        >
          <RangePicker
            value={dateValue}
            allowClear
            popupClassName="main-date-picker"
            inputReadOnly={false}
            onChange={(dates) => {
              setCurrentPage(1)
              handleDateChange(dates, dataIndex)
            }}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
      ),
      filterIcon: () => <FilterOutlined style={dateValue?.length > 0 ? { color: "blue" } : {}} />,
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) => text,
    })
  }

  const handleCancelStoreItemModal = () => {
    setRowData("")
    setStoreItemModalOpen(false)
  }

  const handleAcceptBtn = (e, row) => {
    setIsApprove(true);
    setRowData(row);
    setIsModalOpen(true);
  };

  const handleRejectBtn = (e, row) => {
    setIsApprove(false);
    setRowData(row);
    setIsModalOpen(true);
  };

  const handleStoreItemModal = (row) => {
    setRowData(row)
    setStoreItemModalOpen(true)
  }

  const handleCancel = () => {
    setRowData({});
    setIsModalOpen(false);
  };

  const handleOk = () => {
    const data = {
      purchaserequestId: rowData?.purchaserequestId,
      isAccepted: isApprove ? true : false,
    };
    acceptRejectOfferByAdminApi(
      data,
      (success) => {
        if (success?.success) {
          toastSuccess(success.message);
          setRenderMe((prev) => !prev);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
    setIsModalOpen(false);
  };

  const columns = [
    {
      key: "fullName",
      title: "NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "phone",
      title: "MOBILE",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      key: "categoryName",
      title: "TYPE",
      dataIndex: "categoryName",
      ...getColumnSearchProps("categoryName"),
    },
    {
      key: "sealedQuantity",
      title: "SEALED QUANTITY",
      dataIndex: "sealedQuantity",
      ...getColumnSearchProps("sealedQuantity"),
    },
    {
      key: "offeredPricePerKilo",
      title: "OFFER PRICE",
      dataIndex: "offeredPricePerKilo",
      ...getColumnSearchProps("offeredPricePerKilo"),
    },
    {
      key: "totalOfferPriceAfterCommission",
      title: "TOTAL PRICE (AFTER COMMISSION)",
      dataIndex: "totalOfferPriceAfterCommission",
      ...getColumnSearchProps("totalOfferPriceAfterCommission"),
    },
    {
      key: "moneyCollectionType",
      title: "COLLECTION TYPE",
      dataIndex: "moneyCollectionType",
      filters: [
        {
          text: 'Cash',
          value: 1,
        },
        {
          text: 'Store',
          value: 2,
        }
      ],
    },
    {
      key: "cashFromUser",
      title: "CASH FROM USER",
      dataIndex: "cashFromUser",
      ...getColumnSearchProps("cashFromUser"),
    },
    {
      key: "cashToUser",
      title: "CASH TO USER",
      dataIndex: "cashToUser",
      ...getColumnSearchProps("cashToUser"),
    },
    {
      key: "sellingDate",
      title: "SELLING DATE",
      dataIndex: "sellingDate",
      ...getColumnDateProps("sellingDate"),
    },
    {
      key: "offerDate",
      title: "CREATION DATE",
      dataIndex: "offerDate",
      ...getColumnDateProps("offerDate"),
    },
    {
      key: "offeredBy",
      title: "OFFERED BY",
      dataIndex: "offeredBy",
      ...getColumnSearchProps("offeredBy"),
    },
    {
      key: "purchaserequestId",
      title: "ACTIONS",
      dataIndex: "action",
      // hidden: authorization?.roles.includes(6),
      render: (_, row) => {
        return (
          <>
            <Space size={5} className="mb-1">
              <Button
                type="primary"
                onClick={(e) => handleAcceptBtn(e, row)}
              >
                Accept
              </Button>
              <Button type="danger" onClick={(e) => handleRejectBtn(e, row)}>
                Reject
              </Button>
            </Space>
            <Button
              style={row.moneyCollectionType === "Store" ? { backgroundColor: "green", color: "white" } : { backgroundColor: "#cdcdcd", color: "white" }}
              className="d-block w-100"
              onClick={() => handleStoreItemModal(row)}
              disabled={row.moneyCollectionType !== "Store"}
            >
              View Store Info
            </Button>
          </>
        );
      },
    }
  ]

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getAcceptedPurchaseRequestsApi(
      payload,
      (res) => {
        if (res.success) {
          setLoading(false);
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.err_msg);
      }
    );
  }, [renderMe, searchValues, pageSize, currentPage]);

  return (
    <section className="users pending-offers">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Pending Offers" />
            <SubHeader bgColor="#356ab3" title="All Pending Offers" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"purchaserequestId"}
                allowExport={true}
                apiExport={true}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                title={"Pending Offers"}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onChange={handleTableChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <Modal
            title={isApprove ? "Accept Order" : "Reject Order"}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            centered
          >
            <span>{`Are you sure you want ${isApprove ? "approve" : "reject"} this order?`}</span>
          </Modal>
        )}
        {storeItemModalOpen && (
          <Modal
            title={"View Store Item Details"}
            open={storeItemModalOpen}
            footer={false}
            className="pending-offers__view-store-item"
            centered
            onCancel={handleCancelStoreItemModal}
          >
            <h2 className="text-center fw-bold green-text">{rowData.storeProductName}</h2>
            <img
              src={baseUrl + rowData.storeProductImage}
              alt={rowData.storeProductName}
              className="pending-offers__view-store-item__image d-block img-fluid mx-auto mb-3"
            />
            <div className="pending-offers__view-store-item__data-holder mx-auto d-flex flex-column align-items-center gap-3">
              <p className="pending-offers__view-store-item__info mb-0">
                <span className="fw-500">
                  Quantity:
                </span>{" "}{rowData.storeProductQuantity}
              </p>
              <p className="pending-offers__view-store-item__info mb-0">
                <span className="fw-500">
                  Description:
                </span>{" "}{rowData.storeProductDescription}
              </p>
              <p className="pending-offers__view-store-item__info mb-0">
                <span className="fw-500">
                  Item Unit Price:
                </span>{" "}{rowData.storeProductUnitPrice}
              </p>
              <p className="pending-offers__view-store-item__info mb-0">
                <span className="fw-500">
                  Total Price:
                </span>{" "}{rowData.totalStoreProductPrice}
              </p>
            </div>
          </Modal>
        )}
      </div>
    </section>
  );
}

export default PendingOffers;
