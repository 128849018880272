import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Input, Modal, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { addAdminApi, editAdmin, getActiveStores, getRoles } from "Network";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

function AdminModal({
  isAdminModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isFactoryAdmin, setIsFactoryAdmin] = useState(false);
  const [isStoreAdmin, setIsStoreAdmin] = useState(false);
  const [stores, setStores] = useState(null);
  const [adminsType, setAdminsType] = useState([]);
  const { authorization } = useSelector((state) => state.auth);
  const [isPasswordRequired, setIsPasswordRequired] = useState(mode !== "edit")
  const [form] = Form.useForm();

  const handlePasswordRequire = (value, otherField) => {
    if (mode !== "edit") return;
    if (value) {
      setIsPasswordRequired(true)
    } else {
      if (!form.getFieldValue(otherField)) {
        setIsPasswordRequired(false)
        form.setFields([{
          name: [otherField],
          value: "",
          touched: false,
          validating: false,
          errors: [],
          warnings: []
        }])
      } else {
        setIsPasswordRequired(true)
      }
    }
  }

  const handlePasswordValidation = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please, provide password"));
    }
    if (
      form.getFieldValue("confirmPassword") &&
      !form.isFieldValidating("confirmPassword")
    ) {
      form.validateFields(["confirmPassword"]);
    }
    return Promise.resolve();
  };

  const handleAdminRoles = (value) => {
    setIsFactoryAdmin(value === 3);
    setIsStoreAdmin(value === 7);
  };

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const checkIfNoDataHasBeenModified = (values) => {
    let isModified = false;
    for (let key in values) {
      if (key.includes("assword")) {
        if (values[key]) {
          isModified = true;
          break;
        }
      } else if (key === "roles") {
        let newRoles = values.roles;
        let oldRoles = rowData.roles;
        if (typeof newRoles === "object") {
          newRoles = newRoles.sort().join("")
        }
        if (typeof oldRoles === "object") {
          oldRoles = oldRoles.sort().join("")
        }
        if (newRoles !== oldRoles) {
          isModified = true;
          break;
        }
      } else if (!Object.keys(rowData).includes(key)) {
        isModified = true;
        break;
      } else if (values[key] !== rowData[key]) {
        isModified = true;
        break;
      }
    }
    return isModified;
  }

  const handleOnFinish = (values) => {
    if (isModalLoading) return;
    setIsModalLoading(true);
    if (mode === "edit") {
      const isModified = checkIfNoDataHasBeenModified(values);
      if (!isModified) {
        setIsModalLoading(false);
        toastError("No changes!");
        handleOnCancel();
        return;
      }
    }
    if (values) {
      const data = {
        userName: values.userName,
        password: values.password || null,
        mobile: values.mobile,
        isActive: values.isActive,
        factoryAdmin: isFactoryAdmin,
        factoryName: values.factoryName || null,
        tradeNumber: values.tradeNumber || null,
        roles: typeof values.roles === "object" ? values.roles : [values.roles],
      };

      if (isStoreAdmin) {
        data.storeId = values.store
      }

      if (mode === "edit") {
        data.id = rowData?.adminId;
        delete data.userName;
        if (rowData.adminId === authorization.id) {
          data.isActive = rowData?.isActive;
        }
      }
      if (mode === "add") {
        addAdminApi(
          data,
          (success) => {
            if (success.success) {
              toastSuccess(success.message);
              handleOnCancel();
            } else {
              toastError(success.message);
            }
            setIsModalLoading(false);
          },
          (fail) => {
            if (fail.data.error) {
              toastError(fail.message);
            } else {
              toastError(fail.message);
            }
            setIsModalLoading(false);
          }
        );
      } else {
        editAdmin(
          data,
          (res) => {
            setIsModalLoading(false);
            if (res.success) {
              toastSuccess(res.message);
              handleOnCancel();
            } else {
              toastError(res.message);
            }
          }, (res) => {
            toastError(res.message);
            setIsModalLoading(false);
          }
        )
      }
    }
  };

  useEffect(() => {
    getRoles(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.name };
          });
          setAdminsType(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        } else {
          toastError(res.message);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (rowData && mode === "edit") {
      form.setFieldsValue({
        userName: rowData.userName,
        isActive: rowData.isActive,
        mobile: rowData.mobile,
        factoryAdmin: rowData.factoryAdmin,
        factoryName: rowData.factoryName,
        tradeNumber: rowData.tradeNumber,
        roles: rowData.roles,
        // store: rowData.storeId
      });

      setIsFactoryAdmin(rowData.roles.includes(3));
      setIsStoreAdmin(rowData.roles.includes(7));
    }
  }, [rowData, mode, form]);

  useEffect(() => {
    if (isStoreAdmin) {
      getActiveStores(
        (res) => {
          if (res.success) {
            let storeId = "";
            const data = res.data.map((ele) => {
              if (mode === "edit" && ele.id === rowData.storeId) {
                storeId = rowData.storeId;
              }
              return { value: ele.id, label: ele.name };
            });
            if (mode === "edit") {
              form.setFieldValue("store", storeId)
            }
            setStores(data);
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          toastError(res.message)
        })
    }
  }, [isStoreAdmin])

  return (
    isAdminModalVisible && (
      <Modal
        title={SubHeader}
        open={isAdminModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={false}
        destroyOnClose
        centered
      >
        <Form
          form={form}
          autoComplete="false"
          className="edit-actor__content"
          onFinish={handleOnFinish}
          initialValues={{
            userName: "",
            password: "",
            confirmPassword: "",
            mobike: "",
            isActive: true,
            factoryAdmin: false,
            factoryName: "",
            tradeNumber: "",
            storeAdmin: false,
            store: "",
            roles: [],
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2">
                  Admin Name
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please, provide admin name",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    autoComplete="false"
                    disabled={mode === "edit"}
                    className="search__searchField py-2"
                    placeholder="Admin Name"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2">
                  Password
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="password"
                  rules={isPasswordRequired ? [
                    { validator: handlePasswordValidation }
                  ] : []}
                >
                  <Input.Password
                    autoComplete="false"
                    placeholder={mode === "edit" ? "**********" : "Password"}
                    className="search__searchField py-2"
                    onChange={(e) => handlePasswordRequire(e.target.value, "confirmPassword")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2">
                  Confirm Password
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="confirmPassword"
                  rules={isPasswordRequired ? [
                    {
                      required: true,
                      message: "Please, confirm password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match")
                        );
                      },
                    }),
                  ] : []}
                >
                  <Input.Password
                    placeholder={mode === "edit" ? "**********" : "Confirm Password"}
                    className="search__searchField py-2"
                    onChange={(e) => handlePasswordRequire(e.target.value, "password")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2">
                  Phone Number
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please, provide phone number",
                    },
                    {
                      validator(_, value) {
                        const phoneRegex = /^[\d-+]*$/;
                        if (value && !phoneRegex.test(value)) {
                          return Promise.reject(
                            new Error("Please, enter a valid phone number")
                          );
                        }
                        if (
                          value &&
                          (value.length > 11 ||
                            !value.startsWith("0") ||
                            (value.length === 2 && value !== "01") ||
                            (value.length === 3 &&
                              value !== "010" &&
                              value !== "011" &&
                              value !== "012" &&
                              value !== "015"))
                        ) {
                          return Promise.reject(
                            new Error("Please, enter a valid phone number")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="search__searchField py-2"
                    placeholder="Phone Number"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name mb-4 row mx-0 align-items-center">
                <div className="col-4 px-0 text-start">
                  <label className="users__parent-info-label">Active</label>
                </div>
                <div className="col-8 px-0 d-flex">
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    className="mb-0"
                  >
                    <Switch disabled={isModalLoading || authorization?.id === rowData?.adminId} />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name mb-4 row mx-0 align-items-start">
                <div className="col-3 px-0 text-start">
                  <label className="users__parent-info-label">Admin Type</label>
                </div>
                <div className="col-9 px-0 d-flex">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please, Select Admin Type",
                      },
                    ]}
                    name="roles"
                    className="mb-0 w-100"
                  >
                    <Select
                      className="new-category__form-item"
                      name="roles"
                      placeholder="Select Admin Type"
                      loading={adminsType ? false : true}
                      options={adminsType ? adminsType : []}
                      onChange={handleAdminRoles}
                      // mode="multiple"
                      allowClear
                      getPopupContainer={trigger => trigger.parentElement}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>

            {isFactoryAdmin && (
              <>
                <Col span={24}>
                  <div className="new-actor__name new-category mb-5">
                    <label className="users__parent-info-label mb-2">
                      Factory Name
                    </label>
                    <Form.Item
                      className="new-category__form-item"
                      name="factoryName"
                      rules={[
                        {
                          required: isFactoryAdmin,
                          message: "Please, provide factory name",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        className="search__searchField py-2"
                        placeholder="Factory Name"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="new-actor__name new-category mb-5">
                    <label className="users__parent-info-label mb-2">
                      Trade Number
                    </label>
                    <Form.Item
                      className="new-category__form-item"
                      name="tradeNumber"
                      rules={[
                        {
                          required: isFactoryAdmin,
                          message: "Please, provide trade number",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        className="search__searchField py-2"
                        placeholder="Trade Number"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </>
            )}

            {isStoreAdmin && (
              <Col span={24}>
                <div className="new-actor__name mb-4 row mx-0 align-items-start">
                  <div className="col-3 px-0 text-start">
                    <label className="users__parent-info-label">Store</label>
                  </div>
                  <div className="col-9 px-0 d-flex">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please, Select the Store",
                        },
                      ]}
                      name="store"
                      className="mb-0 w-100"
                    >
                      <Select
                        className="new-category__form-item"
                        name="store"
                        placeholder="Select Store"
                        loading={stores ? false : true}
                        options={stores ? stores : []}
                        allowClear
                        getPopupContainer={trigger => trigger.parentElement}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default AdminModal;
