import { useState, useEffect, useRef } from "react";
import { Button, Input, Space } from "antd";
import { toastError } from "helpers/toasters";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getOrders } from "Network";

// components
import SubHeader from "../SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import ViewExchange from "./ViewExchange";

function FinancialOrders() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [isViewExchangeModalOpen, setIsViewExchangeModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const exportConfig = {
    tab: `Financial Orders Report`,
    baseProps: [
      { title: "Name", prop: "fullName" },
      { title: "Mobile", prop: "phone" },
      { title: "Item", prop: "item" },
      { title: "Quantity", prop: "quantity" },
      { title: "Remaining Quantity", prop: "remainingQuantity" },
      { title: "Unit Price", prop: "unitPrice" },
      { title: "Total Price", prop: "price" },
      { title: "Payment Type", prop: "paymentType" },
      { title: "Order Type", prop: "orderType" },
      { title: "Creation Date", prop: "creationDate" },
    ],
  };

  const handleOpenExchangeModal = (rowData) => {
    setRowData(rowData)
    setIsViewExchangeModalOpen(true)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, row) =>
      searchedColumn === dataIndex ?
        (dataIndex === "paymentType" && text === "Exchange" ?
          <div className="d-flex gap-2 align-items-center">
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
            <button
              type="button"
              onClick={() => handleOpenExchangeModal(row)}
              className="border-0 d-flex flex-column align-items-center outline-0 bg-transparent">
              <EyeOutlined />
            </button>
          </div> : (
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          )
        ) : dataIndex === "paymentType" && text === "Exchange" ?
          <div className="d-flex gap-2 align-items-center">
            <span>{text}</span>
            <button
              type="button"
              onClick={() => handleOpenExchangeModal(row)}
              className="border-0 d-flex flex-column align-items-center outline-0 bg-transparent">
              <EyeOutlined />
            </button>
          </div> : (
            text
          ),
  });

  const columns = [
    {
      key: "fullName",
      title: "NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "phone",
      title: "MOBILE",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },

    {
      key: "item",
      title: "ITEM",
      dataIndex: "item",
      ...getColumnSearchProps("item"),
    },
    {
      key: "quantity",
      title: "QUANTITY",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),
    },
    {
      key: "remainingQuantity",
      title: "REMAINING QUANTITY",
      dataIndex: "remainingQuantity",
      ...getColumnSearchProps("remainingQuantity"),
    },
    {
      key: "unitPrice",
      title: "UNIT PRICE",
      dataIndex: "unitPrice",
      ...getColumnSearchProps("unitPrice"),
    },
    {
      key: "price",
      title: "TOTAL PRICE",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
    },
    {
      key: "paymentType",
      title: "PAYMENT TYPE",
      dataIndex: "paymentType",
      ...getColumnSearchProps("paymentType"),
    },
    {
      key: "orderType",
      title: "ORDER TYPE",
      dataIndex: "orderType",
      width: 200,
      ...getColumnSearchProps("orderType"),
      render: (_, row) => (
        <Space size={30}>
          <span>{row?.orderType}</span>
        </Space>
      ),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
    },
  ];

  useEffect(() => {
    getOrders(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res?.data?.error);
      }
    );
  }, []);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Orders" />
            <SubHeader bgColor="#356ab3" title="All Orders " />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
                allowExport={true}
                exportConfig={exportConfig}
                title={"FinancialOrders"}
              />
            </div>
          </div>
        </div>
      </div>
      {isViewExchangeModalOpen && (
        <ViewExchange
          open={isViewExchangeModalOpen}
          rowData={rowData}
          onCancel={() => setIsViewExchangeModalOpen(false)}
        />
      )}
    </section>
  );
}

export default FinancialOrders;
