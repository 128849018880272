import { useRef, useState, useEffect } from "react";
import { Button, Input, Modal, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { endOffer, getFactoryRequestsApi } from "Network";

// components
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import FactoryRequestModal from "./FactoryRequestModal";

const FactoryAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [dataSource, setDataSource] = useState([]);
  const [isItemModalVisible, setIsItemModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isEndOfferModalOpen, setIsEndOfferModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState(false);
  const searchInput = useRef(null);
  const exportConfig = {
    tab: `Factory Requests Report`,
    baseProps: [
      { title: "Type", prop: "categoryName" },
      { title: "Price/Kilo", prop: "pricePerKilo" },
      { title: "Quantity", prop: "totalAcceptedQuantity" },
      { title: "Creation Date", prop: "creationDate" },
    ],
  };

  const handleOfferModal = (row) => {
    setRowData(row);
    setIsEndOfferModalOpen(true);
  };
  const handleOfferCancel = () => {
    setRowData("");
    setIsEndOfferModalOpen(false);
  };
  const handleEndOffer = () => {
    setIsLoading(true);
    endOffer(
      rowData.requestId,
      (res) => {
        setIsLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          setIsEndOfferModalOpen(false);
          setRenderMe(!renderMe);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
        setIsLoading(false);
      }
    );
  };
  const handleAddItems = () => {
    setIsItemModalVisible(true);
  };
  const handleItemCancel = () => {
    setIsItemModalVisible(false);
    setRenderMe((prev) => !prev);
    setMode("add");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${
            dataIndex === "categoryName"
              ? "type"
              : dataIndex === "creationDate"
              ? "date"
              : "price"
          }`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              return clearFilters && handleReset(clearFilters);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "categoryName",
      title: "TYPE",
      dataIndex: "categoryName",
      ...getColumnSearchProps("categoryName"),
      render: (_, row) => {
        return (
          <span className="d-inline-block text-truncate text-center">
            {row.categoryName ? row.categoryName : "none"}
          </span>
        );
      },
    },
    {
      key: "pricePerKilo",
      title: "PRICE/KILO",
      dataIndex: "pricePerKilo",
      ...getColumnSearchProps("pricePerKilo"),
      render: (_, row) => <span>{row.pricePerKilo}</span>,
    },
    {
      key: "totalAcceptedQuantity",
      title: "Quantity",
      dataIndex: "totalAcceptedQuantity",
      ...getColumnSearchProps("totalAcceptedQuantity"),
      render: (_, row) => <span>{row.totalAcceptedQuantity}</span>,
    },
    {
      key: "creationDate",
      title: "Creation Date",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
      render: (_, row) => <span>{row.creationDate}</span>,
    },
    {
      key: "requestId",
      title: "Action",
      render: (_, row) => {
        if (row.isLive) {
          return (
            <Button type="danger" onClick={() => handleOfferModal(row)}>
              End Offer
            </Button>
          );
        }
        return <></>;
      },
    },
  ];

  useEffect(() => {
    getFactoryRequestsApi(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res?.data?.error);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Factory Requests"
              plusButtonName={"Send Offer"}
              plusButtonAction={handleAddItems}
            />
            <SubHeader bgColor="#356ab3" title="All Factory Requests" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                allowExport={true}
                exportConfig={exportConfig}
                title={"Factory Requests"}
              />
            </div>
          </div>
        </div>
        {isItemModalVisible && (
          <FactoryRequestModal
            isItemModalVisible={isItemModalVisible}
            mode={mode}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title="Send New Offer"
                closeButton
                onCancel={handleItemCancel}
              />
            }
            onCancel={handleItemCancel}
            primaryBtnName="Send"
            secondaryBtnName={"Cancel"}
          />
        )}
        {isEndOfferModalOpen && (
          <Modal
            title="End Offer"
            open={isEndOfferModalOpen}
            onOk={handleEndOffer}
            centered
            destroyOnClose
            maskClosable={false}
            okText={"End"}
            okButtonProps={{ danger: true }}
            confirmLoading={isLoading}
            onCancel={handleOfferCancel}
          >
            <span>{"Are you sure you want to end this offer?"}</span>
          </Modal>
        )}
      </div>
    </section>
  );
};

export default FactoryAdmin;
