import { useRef, useState, useEffect } from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";

// components
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import RequestModal from "./RequestModal";
import { getSupplierAdminRequests } from "Network";

const SupplierAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsItemModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleAddRequests = () => {
    setIsItemModalVisible(true);
  };

  const onCancel = () => {
    setIsItemModalVisible(false);
    setRenderMe((prev) => !prev);
    setMode("add");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${ dataIndex === "categoryName" ? "type" : dataIndex === "creationDate" ? "creation date" : dataIndex === "pricePerKilo" ? "price":dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              return clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "categoryName",
      title: "Type",
      dataIndex: "categoryName",
      ellipsis: true,
      ...getColumnSearchProps("categoryName"),
      render: (_, row) => {
        return (
          <span className="d-inline-block text-truncate text-center">
            {row.categoryName ? row.categoryName : "none"}
          </span>
        );
      },
    },
    {
      key: "pricePerKilo",
      title: "PRICE/EGP",
      dataIndex: "pricePerKilo",
      ...getColumnSearchProps("pricePerKilo"),

      render: (_, row) => <span>{row.pricePerKilo}</span>,
    },
    {
      key: "quantity",
      title: "Quantity",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),

      render: (_, row) => <span>{row.quantity}</span>,
    },
    {
      key: "creationDate",
      title: "Creation Date",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),

      render: (_, row) => <span>{row.creationDate}</span>,
    },
  ];

  useEffect(() => {
    getSupplierAdminRequests(
      (res) => {
        if (res.success) {
          setLoading(false);
          setDataSource(res.data);
        } else {
          setLoading(false);
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          setLoading(false);
          toastError(res.message);
        } else {
          setLoading(false);
          toastError(res.message);
        }
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Supplier Requests"
              plusButtonName={"Add Request"}
              plusButtonAction={handleAddRequests}
            />
            <SubHeader bgColor="#356ab3" title="All Supplier Requests" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
              />
            </div>
          </div>
        </div>
        {isModalVisible && (
          <RequestModal
            visible={isModalVisible}
            mode={mode}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${mode === "add" ? "Add New Request" : "Edit Request"}`}
                closeButton
                onCancel={onCancel}
              />
            }
            onCancel={onCancel}
            primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
            secondaryBtnName={"Cancel"}
          />
        )}
      </div>
    </section>
  );
};

export default SupplierAdmin;
