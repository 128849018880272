import { utils, writeFile } from "xlsx";

export const handleAllowNumbersOnly = (e, allowFloat = false) => {
  if (
      e.key === "Backspace" ||
      (allowFloat && e.key === ".") ||
      (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight"))
  ) {
      return;
  }
  if (/\D+/gi.test(e.key)) {
      e.preventDefault();
  }
};

export const exportDataAsExcel = (
  dataToExport,
  config,
) => {
  const sheetData = [];
  dataToExport.forEach((baseObj) => {
    // set base object
    const sheetObj = {};
    config.baseProps.forEach((probObj) => {
      if (probObj.values) {
        sheetObj[probObj.title] = probObj.values[baseObj[probObj.prop]]
      } else if (probObj.concatprop) {
        let displayedValue = "";
        baseObj[probObj.prop].forEach((ele, indx, arr) => {
          displayedValue = displayedValue.concat(ele[probObj.concatprop])
          if (indx !== arr.length - 1) {
            displayedValue = displayedValue.concat(", ")
          }
        })
        sheetObj[probObj.title] = displayedValue
      } else {
        sheetObj[probObj.title] = baseObj[probObj.prop]
      }
    })
    // set nested objects if exists
    if (config.nestedProps) {
      baseObj[config.nestedProps.prop].forEach((nestedObj, indx) => {
        let extraObj = {}
        if (indx === 0) {
          extraObj = { ...sheetObj }
        }
        extraObj["Request No"] = indx + 1
        config.nestedProps.data.forEach((obj) => {
          extraObj[obj.title] = nestedObj[obj.prop]
        })
        sheetData.push(extraObj)
      })
    } else {
      sheetData.push(sheetObj)
    }
  })
  if (config.totalObj) {
    sheetData.push(config.totalObj);
  }
  const ws = utils.json_to_sheet(sheetData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFile(wb, `${config.tab}.xlsx`);
};

export const dropdownStyle = {
  border: " solid 1px #CEE2F6",
  borderRadius: "12px",
  boxShadow: "0px 8px 10px #0000001A ",
};
