import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, DatePicker } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { toastError } from "helpers/toasters";
import { getAuctionReport } from "Network";

// components
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import TransferDuesModal from "./TransferDuesModal";
import AcceptPaymentModal from "./AcceptPaymentModal";
import ViewParticipants from "./ViewParticipants";

function AuctionReport() {
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState(null);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  const [isViewParticipantsModalVisible, setIsViewParticipantsModalVisible] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handleOpenStatusModal = (row) => {
    setRowData(row);
    setIsStatusModalVisible(true);
  };

  const handleDateChange = (dates, dateStr) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele.date, "MM/DD/YYYY");
        return (
          comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
        );
      });
      setFilteredDataSource(dataWithingRange);
    } else {
      setFilteredDataSource(dataSource);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="all-orders-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined style={selectedRange ? { color: "blue" } : {}} />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "userName",
      title: "USER NAME",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "userMobile",
      title: "MOBILE NUMBER",
      dataIndex: "userMobile",
      ...getColumnSearchProps("userMobile"),
    },
    {
      key: "auctionName",
      title: "AUCTION",
      dataIndex: "auctionName",
      ...getColumnSearchProps("auctionName"),
    },
    {
      key: "type",
      title: "TYPE",
      dataIndex: "type",
      ...getColumnSearchProps("type"),
    },

    {
      key: "auctionPrice",
      title: "AUCTION PRICE",
      dataIndex: "auctionPrice",
      ...getColumnSearchProps("auctionPrice"),
    },
    {
      key: "paymentType",
      title: "PAYMENT TYPE",
      dataIndex: "paymentType",
      filters: [
        { text: "Cash", value: 1 },
        { text: "credit Card", value: 2 },
      ],
      onFilter: (value, record) => record?.paymentTypeId === value,
      render: (_, row) => {
        return <span>{row.paymentTypeId === 1 ? "Cash" : "Credit Card"}</span>;
      },
    },
    {
      key: "isPaid",
      title: "PAID STATUS",
      dataIndex: "isPaid",
      filters: [
        { text: "Paid", value: true },
        { text: "Not Paid", value: false },
      ],
      onFilter: (value, record) => record?.isPaid === value,
      render: (_, row) => {
        return <span>{row.isPaid ? "Paid" : "Not Paid"}</span>;
      },
    },
    {
      key: "isTransfered",
      title: "TRANSFER STATUS",
      dataIndex: "isTransfered",
      filters: [
        { text: "Transferred", value: true },
        { text: "Not Transferred", value: false },
      ],
      onFilter: (value, record) => record?.isTransfered === value,
      render: (_, row) => {
        return <span>{row.isTransfered ? "Transferred" : "Not Transferred"}</span>;
      },
    },
    {
      key: "date",
      title: "DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },

    {
      key: "id",
      title: "ACTIONS",
      render: (_, row) => {
        return (
          <div className="d-flex flex-column gap-2 align-items-center">
            <Space size={5}>
              <Button
                onClick={() => {
                  if (row.isPaid) return
                  handleOpenStatusModal(row)
                }}
                style={
                  row?.isPaid
                    ? { backgroundColor: "gray", color: "white" }
                    : { backgroundColor: "green", color: "white" }
                }
                disabled={row?.isPaid ? true : false}
              >
                Accept Payment
              </Button>
              <Button
                onClick={() => {
                  if (!row.isPaid || row.isTransfered) {
                    return
                  }
                  setRowData(row)
                  setIsTransferModalVisible(true)
                }}
                style={
                  row.isPaid && !row.isTransfered
                    ? { backgroundColor: "blue", color: "white" }
                    : { backgroundColor: "gray", color: "white" }
                }
                disabled={!row.isPaid || row.isTransfered}
              >
                Transfer Dues
              </Button>
            </Space>
            <Space>
              <Button
                onClick={() => {
                  setRowData(row)
                  setIsViewParticipantsModalVisible(true)
                }}
                style={{ backgroundColor: "#e74c3c", color: "white" }}
              >
                View Participants
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const auctionReport = () => {
    getAuctionReport(
      (res) => {
        if (res.success) {
          setLoading(false);
          const updatedData = res.data.map((ele) => {
            ele.date = new Date(ele.date).toLocaleString();
            return ele;
          });
          setDataSource(updatedData);
          setFilteredDataSource(updatedData);
        } else {
          setLoading(false);
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          setLoading(false);
          toastError(res.message);
        } else {
          setLoading(false);
          toastError(res.message);
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    auctionReport();
  }, [renderMe]);

  return (
    <section className="users auction-report">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Auction Report" />
            <SubHeader bgColor="#356ab3" title="Auction Report" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
        {isStatusModalVisible && (
          <AcceptPaymentModal
            open={isStatusModalVisible}
            onCancel={() => {
              setRowData(null)
              setIsStatusModalVisible(false)
            }}
            onSubmit={() => {
              setRowData(null)
              setIsStatusModalVisible(false)
              setRenderMe(!renderMe)
            }}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`Accept Auction Payment`}
                closeButton
                onCancel={() => {
                  setRowData(null)
                  setIsStatusModalVisible(false)
                }}
              />
            }
            rowData={rowData}
          />
        )}
        {isTransferModalVisible && (
          <TransferDuesModal
            open={isTransferModalVisible}
            onCancel={() => {
              setRowData(null)
              setIsTransferModalVisible(false)
            }}
            onSubmit={() => {
              setRowData(null)
              setIsTransferModalVisible(false)
              setRenderMe(!renderMe)
            }}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`Transfer Dues`}
                closeButton
                onCancel={() => {
                  setRowData(null)
                  setIsTransferModalVisible(false)
                }}
              />
            }
            rowData={rowData}
          />
        )}
        {isViewParticipantsModalVisible && (
          <ViewParticipants
            open={isViewParticipantsModalVisible}
            onCancel={() => {
              setRowData(null)
              setIsViewParticipantsModalVisible(false)
            }}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`View Participants`}
                closeButton
                onCancel={() => {
                  setRowData(null)
                  setIsViewParticipantsModalVisible(false)
                }}
              />
            }
            rowData={rowData}
          />
        )}
      </div>
    </section>
  );
}

export default AuctionReport;
