import React, { useState } from "react";
import { Col, Form, Input, Modal, Row, Space } from "antd";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import { SendPurchaseRequestApi } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import db from "../../firebase";
import { doc, updateDoc, increment } from "firebase/firestore";

function OrderModal({
  isOrderModalVisible,
  rowData = null,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);

  const incrementCount = async () => {
    await updateDoc(doc(db, `users`, rowData?.userId), {
      unReadMessagesCount: increment(1),
    });
  };
  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };
  function handleOnFinish(values) {
    if (values) {
      const data = {
        pricePerKilo: +values?.pricePerKilo,
        orderId: rowData?.id,
      };
      setIsModalLoading(true);
      SendPurchaseRequestApi(
        data,
        (success) => {
          if (success.success) {
            toastSuccess(success.message);
            incrementCount();
            handleOnCancel();
          } else {
            toastError(success.message);
            setIsModalLoading(false);
          }
        },
        (fail) => {
          if (fail.data?.error) {
            setIsModalLoading(false);
            toastError(fail.message);
          } else {
            setIsModalLoading(false);
            toastError();
          }
        }
      );
    }
  }

  return (
    isOrderModalVisible && (
      <Modal
        title={SubHeader}
        visible={isOrderModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={true}
        onCancel={onCancel}
      >
        <Form className="edit-actor__content  " onFinish={handleOnFinish}>
          <Row gutter={24}>
            <Col span={24}>
              <span>Send offer to user</span>
            </Col>
            <Col span={24}>
              {" "}
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Price per Kilo
                  </label>
                </div>
                <Form.Item
                  className=" new-category__form-item "
                  name="pricePerKilo"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the price per kilo",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="search__searchField py-2"
                    placeholder="Enter Price per kilo"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default OrderModal;
