import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  buyAndSupplyAddSupplyItem,
  buyAndSupplyEditSupplyItem,
  getCategoriesApi,
} from "Network";

import PrimaryButton from "../../../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../../../SubComponents/Buttons/SecondaryButton";
import FormItem from "./FormItem";

function SupplyModal({
  isItemModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  onSubmit
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [createdItems, setCreatedItems] = useState([{ id: 0, days: "", pricePerKilo: "" }]);
  const [form] = Form.useForm();
  const initialValues = {
    isActive: true,
    categoryId: undefined,
  }

  const handleAddNewItem = () => {
    const itemId = itemsCounter + 1;
    setItemsCounter(itemId);
    const newItem = {
      id: itemId,
      days: "",
      pricePerKilo: "",
    };

    if (mode === "edit") {
      newItem.state = 1;
    }
    const createdItemsCopy = structuredClone(createdItems);
    createdItemsCopy.push(newItem);
    setCreatedItems(createdItemsCopy);
  };

  const handleDeleteItem = (id) => {
    const createdItemsCopy = structuredClone(createdItems);
    const itemIndx = createdItemsCopy.findIndex((ele) => ele.id === id);
    if (mode === "add" || createdItemsCopy[itemIndx].state === 1) {
      const updatedItemsList = createdItemsCopy.filter((ele) => ele.id !== id);
      setCreatedItems(updatedItemsList);
    } else {
      createdItemsCopy[itemIndx].state = 3
      setCreatedItems(createdItemsCopy);
    }
  };

  const handleUpdateItem = (id, property, value) => {
    const createdItemsCopy = structuredClone(createdItems);
    const itemIndex = createdItemsCopy.findIndex((ele) => ele.id === id);
    createdItemsCopy[itemIndex][property] = value;
    setCreatedItems(createdItemsCopy);
  };

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const addSupplyItem = (payload) => {
    buyAndSupplyAddSupplyItem(
      payload,
      (res) => {
        setIsModalLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsModalLoading(false);
        toastError(res.message);
      }
    );
  };

  const editSupplyItem = (payload) => {
    buyAndSupplyEditSupplyItem(
      payload,
      (res) => {
        setIsModalLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit()
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsModalLoading(false);
        toastError(res.message);
      }
    );
  };

  const handleFinish = (values) => {
    if (isModalLoading) return;
    setIsModalLoading(true);

    const payload = {
      isActive: values.isActive,
      pricesDaysList: createdItems
    }

    if (mode === "add") {
      const updatedData = payload.pricesDaysList.map((item) => {
        const { id, ...remainData } = item;
        return remainData;
      });
      payload.pricesDaysList = updatedData;
      payload.categoryId = values.categoryId
      addSupplyItem(payload);
    } else {
      payload.id = rowData.itemId;
      const updatedData = payload.pricesDaysList.map((item) => {
        if (item.state === 1) {
          const { id, ...remainData } = item;
          return remainData;
        } else {
          return item;
        }
      });
      payload.pricesDaysList = updatedData;
      editSupplyItem(payload);
    }
  };

  useEffect(() => {
    if (rowData && mode === "edit") {
      const itemsList = rowData.pricesDaysList.map((item) => {
        form.setFieldValue(`days-${item.id}`, item.days)
        form.setFieldValue(`pricePerKilo-${item.id}`, item.pricePerKilo)
        return { ...item, state: 2, }
      });
      setCreatedItems(itemsList);
      form.setFieldsValue({ isActive: rowData.isActive, })
    }
  }, [rowData, mode]);

  useEffect(() => {
    getCategoriesApi(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.name, unitValue: ele.unitValue };
          });
          setCategories(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
  }, []);

  useEffect(() => {
    if (categories && rowData && mode === "edit") {
      form.setFieldsValue({ categoryId: rowData.categoryId })
    }
  }, [categories, rowData, mode])

  return (
    isItemModalVisible && (
      <Modal
        title={SubHeader}
        open={isItemModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={false}
        centered
        destroyOnClose
      >
        <Form
          className="edit-actor__content"
          initialValues={initialValues}
          onFinish={handleFinish}
          form={form}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please, select a type",
                    },
                  ]}
                >
                  <Select
                    disabled={mode === "edit"}
                    placeholder="Select a type"
                    loading={categories ? false : true}
                    options={categories ? categories : []}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name mb-4 row mx-0 align-items-center">
                <div className="col-4 px-0 text-start">
                  <label className="users__parent-info-label">Status</label>
                </div>
                <div className="col-8 px-0 d-flex">
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    className="mb-0"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <div
                  className="d-flex flex-column gap-3"
                // style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {createdItems.map(
                    (ele, indx) =>
                      ele?.state !== 3 && (
                        <FormItem
                          key={indx}
                          data={ele}
                          onDelete={handleDeleteItem}
                          onUpdateItem={handleUpdateItem}
                          order={indx}
                          mode={mode}
                        />
                      )
                  )}
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="d-flex justify-content-end wd-100">
                <Button className="mb-3" onClick={handleAddNewItem}>
                  + Add
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default SupplyModal;