import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { baseUrl } from "services";

let firebaseConfig = {};
if (baseUrl === "https://api.thegreenmatter.com/") {
  /*                  LIVE CONFIG               */
  firebaseConfig = {
    apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
    authDomain: "recycling-33b80.firebaseapp.com",
    projectId: "recycling-33b80",
    storageBucket: "recycling-33b80.appspot.com",
    messagingSenderId: "638393445867",
    appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
  };
} else {
  /*                  TEST CONFIG               */
  firebaseConfig = {
    apiKey: "AIzaSyA_OlsQZjl4LphquNVSQv7IkSZLsO-M1QA",
    authDomain: "greenmatterweb-test.firebaseapp.com",
    projectId: "greenmatterweb-test",
    storageBucket: "greenmatterweb-test.appspot.com",
    messagingSenderId: "734944809970",
    appId: "1:734944809970:web:d6ea12c5ad31a35b39916b",
    measurementId: "G-63K880YBYV",
  };
}
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;

/**********************************************/
/*                  LIVE CONFIG               */
/**********************************************/
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
//   authDomain: "recycling-33b80.firebaseapp.com",
//   projectId: "recycling-33b80",
//   storageBucket: "recycling-33b80.appspot.com",
//   messagingSenderId: "638393445867",
//   appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
// };
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// export default db;

/**********************************************/
/*                  TEST CONFIG               */
/**********************************************/
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyA_OlsQZjl4LphquNVSQv7IkSZLsO-M1QA",
//   authDomain: "greenmatterweb-test.firebaseapp.com",
//   projectId: "greenmatterweb-test",
//   storageBucket: "greenmatterweb-test.appspot.com",
//   messagingSenderId: "734944809970",
//   appId: "1:734944809970:web:d6ea12c5ad31a35b39916b",
//   measurementId: "G-63K880YBYV"
// };
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// export default db;

// const messaging = getMessaging();

// export const requestForToken = () => {
//   return getToken(messaging, { vapidKey: "BAl5EMWqtMG0lp9lir9iwOjt3cvazHm1g8S5uaW-yykzI8ueha4niVfMMM8zuG2RAcyZTmnHv3hLatgotPGfzaw" })
//     .then((currentToken) => {
//       if (currentToken) {
//         // console.log('current token for client: ', currentToken);
//         // Perform any other neccessary action with the token
//         return currentToken;
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
// };
