import { useState, useEffect, useRef } from "react";
import { Button, DatePicker, Input, Space } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";
import { getSoldOrders } from "Network";

import SubHeader from "../SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import { exportDataAsExcel } from "helpers/helpfulFunctions";

function SoldOrders() {
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRange, setSelectedRange] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    userName: "",
    userMobile: "",
    item: "",
    soldFor: "",
    sealedQuantities: "",
    perKiloOfferPrice: "",
    totalCost: "",
    orderType: "",
    type: "",
    unit: "",
    from: "",
    to: ""
  })
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);


  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues }
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }

  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate })
    } else {
      updateSearchValues("from", "", { property: "to", value: "" })
    }
  };

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Name", prop: "fullName" },
      { title: "Mobile", prop: "phone" },
      { title: "Type", prop: "categoryName" },
      { title: "Sold For", prop: "soldFor" },
      { title: "Unit", prop: "unit" },
      { title: "Sealed Quantities", prop: "sealedQuantities" },
      { title: "Offer Price Per Kilo", prop: "perKiloOfferPrice" },
      { title: "Total Cost", prop: "totalCost" },
      { title: "Order Type", prop: "orderType" },
      { title: "Creation Date", prop: "creationDate" },
      { title: "Total Orders Quantity", prop: "totalOrdersQuantity" },
      { title: "Total Orders Price", prop: "totalOrdersPrice" },
    ]
    const payload = {
      isExport: true,
      currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
        delete payload[property]
      }
    }
    getSoldOrders(
      payload,
      (res) => {
        if (res.success) {
          const dataToExport = res.data.response
          dataToExport[0].totalOrdersQuantity = res.data.totalQantities
          dataToExport[0].totalOrdersPrice = res.data.totalPrice
          exportDataAsExcel(dataToExport, { baseProps, tab: "Sold Orders Report" })
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  const handlePageChange = (page) => {
    setLoading(true)
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setLoading(true)
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", selectedKeys[0])
        break;
      case "phone":
        updateSearchValues("userMobile", selectedKeys[0])
        break;
      case "categoryName":
        updateSearchValues("type", selectedKeys[0])
        break;
      case "sealedQuantities":
        updateSearchValues("sealedQuantities", selectedKeys[0])
        break;
      case "soldFor":
        updateSearchValues("soldFor", selectedKeys[0])
        break;
      case "perKiloOfferPrice":
        updateSearchValues("perKiloOfferPrice", selectedKeys[0])
        break;
      case "totalCost":
        updateSearchValues("totalCost", selectedKeys[0])
        break;
      case "orderType":
        updateSearchValues("orderType", selectedKeys[0])
        break;
      case "type":
        updateSearchValues("type", selectedKeys[0])
        break;
      case "unit":
        updateSearchValues("unit", selectedKeys[0])
        break;
      case "item":
        updateSearchValues("item", selectedKeys[0])
        break;
      default:
        break;
    }
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    setLoading(true);
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", "")
        break;
      case "phone":
        updateSearchValues("userMobile", "")
        break;
      case "categoryName":
        updateSearchValues("type", "")
        break;
      case "sealedQuantities":
        updateSearchValues("sealedQuantities", "")
        break;
      case "soldFor":
        updateSearchValues("soldFor", "")
        break;
      case "perKiloOfferPrice":
        updateSearchValues("perKiloOfferPrice", "")
        break;
      case "totalCost":
        updateSearchValues("totalCost", "")
        break;
      case "orderType":
        updateSearchValues("orderType", "")
        break;
      case "type":
        updateSearchValues("type", "")
        break;
      case "unit":
        updateSearchValues("unit", "")
        break;
      case "item":
        updateSearchValues("item", "")
        break;
      default:
        break;
    }
    clearFilters();
    confirm()
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              return clearFilters && handleReset(clearFilters, dataIndex, confirm);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1)
            handleDateChange(dates, datesStr)
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      key: "fullName",
      title: "NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "phone",
      title: "MOBILE",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      key: "categoryName",
      title: "TYPE",
      dataIndex: "categoryName",
      ...getColumnSearchProps("categoryName"),
    },
    {
      key: "soldFor",
      title: "SOLD FOR",
      dataIndex: "soldFor",
      ...getColumnSearchProps("soldFor"),
    },
    {
      key: "unit",
      title: "UNIT",
      dataIndex: "unit",
      ...getColumnSearchProps("unit"),
    },
    {
      key: "sealedQuantities",
      title: "SEALED QUANTITIES",
      dataIndex: "sealedQuantities",
      ...getColumnSearchProps("sealedQuantities"),
    },
    {
      key: "perKiloOfferPrice",
      title: "OFFER PRICE PER KILO",
      dataIndex: "perKiloOfferPrice",
      ...getColumnSearchProps("perKiloOfferPrice"),
    },
    {
      key: "totalCost",
      title: "TOTAL COST",
      dataIndex: "totalCost",
      ...getColumnSearchProps("totalCost"),
    },
    {
      key: "id",
      title: "ORDER TYPE",
      dataIndex: "orderType",
      width: 200,
      ...getColumnSearchProps("orderType"),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
  ];

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getSoldOrders(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
          setTotalPrice(res.data.totalPrice);
          setTotalQuantity(res.data.totalQantities);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [searchValues, pageSize, currentPage]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Sold Orders" />
            <SubHeader
              bgColor="#356ab3"
              title="All Sold Orders"
              totalPrice={totalPrice}
              totalQuantity={totalQuantity}
            />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
                allowExport={true}
                apiExport={true}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                title={"Sold Orders"}
                soldOrders={{ totalQuantity, totalPrice }}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SoldOrders;
