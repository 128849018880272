import { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Row, Spin } from "antd";
import { editSettings, getSettings } from "../../Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

import Header from "modules/SubComponents/Header";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isWhatsAppSubmitting, setIsWhatsAppSubmitting] = useState(false);
  const [isCommissionSubmitting, setIsCommissionSubmitting] = useState(false);
  const [isPeriodSubmitting, setIsPeriodSubmitting] = useState(false);
  const [commissionForm] = Form.useForm();
  const [whatsAppForm] = Form.useForm();
  const [receivingPeriodForm] = Form.useForm();

  const handlePreventLetters = (e, allowFloat = true) => {
    if (
      e.key === "Backspace" ||
      (e.key === "." && allowFloat) ||
      (e.key.startsWith("Arrow") &&
        (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight")))
    )
      return;
    if (/\D+/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  const validatePhoneNumber = (phone) => {
    let phoneLen = 11;
    let charLen = 2; // will validate that user enters 0 and first two numbers inputed are 01
    if (phone.startsWith("2")) {
      phoneLen = 12;
      charLen = 3; // will validate that user entered 2 and first two number inputed are 0 and 1
    }
    if (
      phone.length > phoneLen ||
      (phone.length >= charLen &&
        !phone.startsWith(`${charLen === 2 ? "0" : "20"}1`)) ||
      (phone.length >= charLen + 1 &&
        !phone.startsWith(`${charLen === 2 ? "0" : "20"}10`) &&
        !phone.startsWith(`${charLen === 2 ? "0" : "20"}11`) &&
        !phone.startsWith(`${charLen === 2 ? "0" : "20"}12`) &&
        !phone.startsWith(`${charLen === 2 ? "0" : "20"}15`)) ||
      phone.length < phoneLen
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validatePeriod = (_, value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error("Please, Enter the receiving period"));
    } else if (value <= 0) {
      return Promise.reject(new Error("Please, Enter a valid period"));
    } else {
      return Promise.resolve();
    }
  };
  const validateCommission = (_, value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error("Please, Enter the commission"));
    } else if (value <= 0 || value > 100) {
      return Promise.reject(new Error("Please, Enter a valid commission"));
    } else {
      return Promise.resolve();
    }
  };

  const validateMobileNumber = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please, Enter the whats app number"));
    } else if (!validatePhoneNumber(value)) {
      return Promise.reject(
        new Error("Please, Enter a valid whats app number")
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleSubmitWhatsApp = (values) => {
    if (isWhatsAppSubmitting || loading) return;
    setIsWhatsAppSubmitting(true);

    const id = data.find((ele) => ele.key === "WhatsAppNumber")?.id;
    const payload = {
      id,
      value: values.whatsAppNumber,
    };
    editSettings(
      payload,
      (res) => {
        setIsWhatsAppSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsWhatsAppSubmitting(false);
        toastError(res.message);
      }
    );
  };

  const handleSubmitCommission = (values) => {
    if (isCommissionSubmitting || loading) return;
    setIsCommissionSubmitting(true);

    const id = data.find(
      (ele) => ele.key === "GreenMatterPercentageForStoring"
    )?.id;
    const payload = {
      id,
      value: values.commission.toString(),
    };
    editSettings(
      payload,
      (res) => {
        setIsCommissionSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsCommissionSubmitting(false);
        toastError(res.message);
      }
    );
  };
  const handleSubmitPeriod = (values) => {
    if (isPeriodSubmitting || loading) return;
    setIsPeriodSubmitting(true);

    const id = data.find(
      (ele) => ele.key === "Add balance to buy and supply supply wallet period"
    )?.id;
    const payload = {
      id,
      value: values.receivingPeriod.toString(),
    };
    editSettings(
      payload,
      (res) => {
        setIsPeriodSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsPeriodSubmitting(false);
        toastError(res.message);
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    getSettings(
      (res) => {
        setLoading(false);
        if (res.success) {
          const whatsNumber = res.data.find(
            (ele) => ele.key === "WhatsAppNumber"
          )?.value;
          whatsAppForm.setFieldsValue({
            whatsAppNumber: whatsNumber,
          });
          const commission = res.data.find(
            (ele) => ele.key === "GreenMatterPercentageForStoring"
          )?.value;
          commissionForm.setFieldsValue({
            commission: commission,
          });
          const receivingPeriod = res.data.find(
            (ele) =>
              ele.key === "Add balance to buy and supply supply wallet period"
          )?.value;
          receivingPeriodForm.setFieldsValue({
            receivingPeriod: receivingPeriod,
          });
          setData(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, []);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData align-items-stretch">
            <Header title="Settings" />
            <Spin className="w-100 d-block" spinning={loading}>
              <section className="mb-5">
                <Form
                  form={commissionForm}
                  className="px-lg-5 px-2"
                  onFinish={handleSubmitCommission}
                  initialValues={{ commission: "" }}
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <div className="new-actor__name new-category mb-5">
                        <div className="lang-name wd-100 flex ">
                          <label
                            className="users__parent-info-label mb-2"
                            htmlFor=""
                          >
                            Commission Percentage
                          </label>
                        </div>
                        <Form.Item
                          className="new-category__form-item"
                          name="commission"
                          rules={[{ validator: validateCommission }]}
                        >
                          <InputNumber
                            className="w-100 d-block search__searchField py-2"
                            placeholder="Enter commission percentage"
                            onKeyDown={handlePreventLetters}
                            controls={false}
                            keyboard={false}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={24}>
                      <PrimaryButton
                        buttonName={"Submit"}
                        loading={isCommissionSubmitting}
                        type="submit"
                        disableMe={loading}
                      />
                    </Col>
                  </Row>
                </Form>
              </section>
              <section className="mb-5">
                <Form
                  form={whatsAppForm}
                  className="px-lg-5 px-2"
                  onFinish={handleSubmitWhatsApp}
                  initialValues={{ whatsAppNumber: "" }}
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <div className="new-actor__name new-category mb-5">
                        <div className="lang-name wd-100 flex ">
                          <label
                            className="users__parent-info-label mb-2"
                            htmlFor=""
                          >
                            Whats App Number
                          </label>
                        </div>
                        <Form.Item
                          className="new-category__form-item"
                          name="whatsAppNumber"
                          rules={[{ validator: validateMobileNumber }]}
                        >
                          <Input
                            className="w-100 d-block search__searchField py-2"
                            placeholder="Enter whats app number"
                            onKeyDown={(e) => handlePreventLetters(e, false)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={24}>
                      <PrimaryButton
                        buttonName={"Submit"}
                        loading={isWhatsAppSubmitting}
                        type="submit"
                        disableMe={loading}
                      />
                    </Col>
                  </Row>
                </Form>
              </section>
              <section className="mb-5">
                <Form
                  form={receivingPeriodForm}
                  className="px-lg-5 px-2"
                  onFinish={handleSubmitPeriod}
                  initialValues={{ period: "" }}
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <div className="new-actor__name new-category mb-5">
                        <div className="lang-name wd-100 flex ">
                          <label
                            className="users__parent-info-label mb-2"
                            htmlFor=""
                          >
                            Add balance to buy and supply supply wallet period
                          </label>
                        </div>
                        <Form.Item
                          className="new-category__form-item"
                          name="receivingPeriod"
                          rules={[{ validator: validatePeriod }]}
                        >
                          <Input
                            className="w-100 d-block search__searchField py-2"
                            placeholder="Enter whats app number"
                            onKeyDown={(e) => handlePreventLetters(e, false)}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={24}>
                      <PrimaryButton
                        buttonName={"Submit"}
                        loading={isPeriodSubmitting}
                        type="submit"
                        disableMe={loading}
                      />
                    </Col>
                  </Row>
                </Form>
              </section>
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;
