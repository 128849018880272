import { useState, useEffect, useRef } from "react";
import { Button, Modal, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { deleteCategoryApi, getCategoriesApi } from "Network";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import CategoryModal from "./CategoryModal";
import OfferModal from "./OfferModal";

function Categories() {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isDeleteItemOpen, setIsDeleteItemOpen] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    getCategories();
  }, [renderMe]);

  const getCategories = () => {
    setLoading(true);
    getCategoriesApi(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        if (res.data?.error) {
          toastError(res.message);
        } else {
          toastError(res.message);
        }
      }
    );
  };
  const handleEditBtn = (e, row) => {
    setMode("edit");
    setRowData(row);
    setIsCategoryModalVisible(true);
  };
  const handlDeleteBtn = (e, row) => {
    setIsDeleteItemOpen(true);
    setRowData(row);
  };

  const handleAddItems = () => {
    setIsCategoryModalVisible(true);
  };

  const onCancel = () => {
    setIsCategoryModalVisible(false);
    setRenderMe((prev) => !prev);
    setMode("add");
  };
  const handleCancel = () => {
    setIsDeleteItemOpen(false);
    setIsOfferModalOpen(false);
  };

  const handleOk = () => {
    const data = { id: rowData?.id };
    deleteCategoryApi(
      data,
      (success) => {
        if (success?.success) {
          toastSuccess(success.message);
          setRenderMe((prev) => !prev);
          handleCancel();
        } else {
          toastError(success.message);
          handleCancel();
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };

  const handleOpenOfferModal = (row) => {
    setIsOfferModalOpen(true);
    setRowData(row);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      key: "name",
      title: "NAME",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      render: (_, row) => <span>{row.name}</span>,
    },
    {
      key: "unitValue",
      title: "UNIT",
      dataIndex: "unitValue",
      ...getColumnSearchProps("unitValue"),
      render: (_, row) => <span>{row.unitValue}</span>,
    },
    {
      key: "offer",
      title: "OFFER",
      dataIndex: "offer",
      render: (_, row) => (
        <Button type="primary" onClick={(e) => handleOpenOfferModal(row)}>
          Send offer to users
        </Button>
      ),
    },
    {
      key: "id",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              onClick={(e) => handleEditBtn(e, row)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Edit
            </Button>
            <Button type="danger" onClick={(e) => handlDeleteBtn(e, row)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Types"
              plusButtonName={"Add Type"}
              plusButtonAction={handleAddItems}
            />
            <SubHeader bgColor="#356ab3" title="All Types" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
        {isCategoryModalVisible && (
          <CategoryModal
            isCategoryModalVisible={isCategoryModalVisible}
            mode={mode}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${
                  mode === "add" ? "Add New Type" : "Edit Type"
                }`}
                closeButton
                onCancel={onCancel}
              />
            }
            onCancel={onCancel}
            primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isDeleteItemOpen && (
          <Modal
            title="Delete Type"
            open={isDeleteItemOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <span>{"Are you sure you want to delete this type?"}</span>
          </Modal>
        )}
        {isOfferModalOpen && (
          <OfferModal
            isOrderModalVisible={isOfferModalOpen}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={"Notify users for new buyer"}
              />
            }
            onCancel={handleCancel}
            primaryBtnName={`Send Offer`}
            secondaryBtnName={"Cancel"}
          />
        )}
      </div>
    </section>
  );
}

export default Categories;
