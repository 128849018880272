import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// assets
import accountIcon from "assets/avatars/accountIcon.svg";

function Navbar({
  showSidebar,
  onSidebarChange
}) {
  const MenuOutlinedStyle = {
    fontSize: "35px",
    color: "#356ab3",
    marginBlockEnd: "10px",
    marginInlineStart: "20px",
  };

  return (
    <nav className="navbar d-flex justify-content-between align-items-center">
      {showSidebar ? (
        <MenuFoldOutlined
          style={MenuOutlinedStyle}
          onClick={() => onSidebarChange(false)}
          role="button"
        />
      ) : (
        <MenuUnfoldOutlined
          style={MenuOutlinedStyle}
          onClick={() => onSidebarChange(true)}
          role="button"
        />
      )}
      <img className="navbar__account-icon" src={accountIcon} alt="" />
    </nav >
  );
}

export default Navbar;
