import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  acceptOrRejectBuyPendingOrders,
  getbuyItemsPendingOrders,
} from "Network";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";

const BuyPendingOrders = () => {
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isApprove, setIsApprove] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "fullName",
      title: "USER NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "phone",
      title: "MOBILE",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      key: "item",
      title: "ITEM",
      dataIndex: "item",
      ...getColumnSearchProps("item"),
    },
    {
      key: "quantity",
      title: "QUANTITY",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),
    },
    {
      key: "remainingQuantity",
      title: "REMAINING QUANTITY",
      dataIndex: "remainingQuantity",
      ...getColumnSearchProps("remainingQuantity"),
    },
    {
      key: "unitPrice",
      title: "PRICE",
      dataIndex: "unitPrice",
      ...getColumnSearchProps("unitPrice"),
    },
    {
      key: "price",
      title: "TOTAL PRICE",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
    },
    {
      key: "paymentTypeId",
      title: "PAYMENT TYPE",
      dataIndex: "paymentTypeId",
      filters: [
        {
          text: "Cash",
          value: 1,
        },
        {
          text: "Credit Card",
          value: 2,
        },
      ],
      onFilter: (value, record) => value === record.paymentTypeId,
      render: (_, row) => (
        <span>{row.paymentTypeId === 1 ? "Cash" : "Credit Card"}</span>
      ),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
    },
    {
      key: "itemId",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button type="primary" onClick={(e) => handleAcceptBtn(e, row)}>
              Accept
            </Button>
            <Button type="danger" onClick={(e) => handleRejectBtn(e, row)}>
              Reject
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleAcceptBtn = (e, row) => {
    setIsApprove(true);
    setRowData(row);
    setIsConfirmationModalVisible(true);
  };

  const handleRejectBtn = (e, row) => {
    setIsApprove(false);
    setRowData(row);
    setIsConfirmationModalVisible(true);
  };

  const onCancel = () => {
    setIsConfirmationModalVisible(false);
    setRenderMe((prev) => !prev);
  };

  const handleOk = () => {
    const data = {
      orderId: rowData.id,
      isAccepted: isApprove,
    };
    acceptOrRejectBuyPendingOrders(
      data,
      (res) => {
        if (res.success) {
          toastSuccess(res.message);
          setRenderMe((prev) => !prev);
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        toastError(fail?.data?.error);
      }
    );
    setIsConfirmationModalVisible(false);
  };

  useEffect(() => {
    getbuyItemsPendingOrders(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail?.data?.error);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Buy Items Pending Orders" />
            <SubHeader bgColor="#356ab3" title="All Buy items Pending Orders" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
        {isConfirmationModalVisible && (
          <Modal
            title={`${isApprove ? "Accept" : "Reject"} Buy Item`}
            open={isConfirmationModalVisible}
            onOk={handleOk}
            onCancel={onCancel}
          >
            <span>{`Are you sure you want ${isApprove ? "accept" : "reject"
              } the buy item?`}</span>
          </Modal>
        )}
      </div>
    </section>
  );
};

export default BuyPendingOrders;
