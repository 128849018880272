import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row } from "antd";
import { handleAllowNumbersOnly } from "helpers/helpfulFunctions";

const FormItem = ({
  data = {},
  onUpdateItem,
  onDelete,
  order,
  mode = "",
}) => {
  const validateDays = (_, value) => {
    if (!value && value !== 0) {
      return Promise.reject("Please, enter the days");
    } else if (value <= 0) {
      return Promise.reject("Minimum value should be more than 0");
    } else if (value !== parseInt(value)) {
      return Promise.reject("Enter a valid number");
    } else {
      return Promise.resolve();
    }
  };

  const validatePricePerKilo = (_, value) => {
    if (!value && value !== 0) {
      return Promise.reject("Please, enter the price per kilo");
    } else if (value <= 0) {
      return Promise.reject("Minimum value should be more than 0");
    }  else if (value > 10000) {
      return Promise.reject("Maximum value is 10,000 EGP");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div
      className="cans-sales-requests__add-request-form__item"
      style={{
        border: "solid #076128 2px",
        padding: "10px",
        borderRadius: "12px",
      }}
    >
      {order > 0 ? (
        <div className="d-flex justify-content-end align-items-center mb-3">
          <Button
            type="button"
            onClick={() => onDelete(data?.id)}
            className="d-block p-0 border-0 outline-none bg-transparent shadow-none"
          >
            <CloseOutlined style={{ color: "#f22626", fontSize: "16px" }} />
          </Button>
        </div>
      ) : (
        ""
      )}
      <Row>
        <Col span={24}>
          <div className="lang-name wd-100 flex">
            <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
              Days
            </label>
          </div>
          <Form.Item
            className="new-category__form-item w-100"
            name={`days-${data?.id}`}
            rules={[{ validator: validateDays }]}
          >
            <InputNumber
              className="w-100 d-block search__searchField"
              placeholder="Enter days"
              onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
              controls={false}
              keyboard={false}
              maxLength={8}
              value={data?.days}
              onChange={(value) => onUpdateItem(data?.id, "days", value)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="lang-name wd-100 flex">
            <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
              Price per kilo
            </label>
          </div>
          <Form.Item
            className="new-category__form-item w-100"
            name={`pricePerKilo-${data?.id}`}
            rules={[{ validator: validatePricePerKilo }]}
          >
            <InputNumber
              className="w-100 d-block search__searchField"
              placeholder="Enter price per kilo"
              onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
              controls={false}
              keyboard={false}
              maxLength={8}
              value={data.pricePerKilo}
              onChange={(value) =>
                onUpdateItem(data?.id, "pricePerKilo", value)
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default FormItem;
