import { useEffect, useRef, useState } from "react";
import { Button, Image, Input, Space, } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getSalesRequests } from "../../Network";
import { toastError } from "../../helpers/toasters";
import { baseUrl } from '../../services';

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "modules/SubComponents/SubHeader";
import Header from "modules/SubComponents/Header";

const SalesRequests = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: "requestIndex",
            title: "REQUEST NUMBER",
            dataIndex: "requestIndex",
            ...getColumnSearchProps("requestIndex"),
            sorter: (a, b) => a.requestIndex - b.requestIndex,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "dealerName",
            title: "DEALER NAME",
            dataIndex: "dealerName",
            ...getColumnSearchProps("dealerName"),
            sorter: (a, b) => a.dealerName.toLowerCase() < b.dealerName.toLowerCase() ? -1 : 1,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "dealerMobile",
            title: "DEALER MOBILE",
            dataIndex: "dealerMobile",
            ...getColumnSearchProps("dealerMobile"),
        },
        {
            key: "type",
            title: "TYPE",
            dataIndex: "type",
            ...getColumnSearchProps("type"),
            sorter: (a, b) => a.type.toLowerCase() < b.type.toLowerCase() ? -1 : 1,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "totalQuantity",
            title: "QUANTITY REQUESTED",
            dataIndex: "totalQuantity",
            ...getColumnSearchProps("totalQuantity"),
            sorter: (a, b) => a.totalQuantity - b.totalQuantity,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "image",
            title: "WEIGHT RECEIPT",
            dataIndex: "image",
            render: (_, row) => {
                return row.image ? (
                    <Image
                        width={90}
                        height={70}
                        src={baseUrl + row.image}
                        className="sales-requests__receipt-img d-block img-fluid object-fit-cover"
                        alt=""
                    />
                ) : "";
            },
        },
        {
            key: "perKiloPrice",
            title: "UNIT PRICE",
            dataIndex: "perKiloPrice",
            ...getColumnSearchProps("perKiloPrice"),
            sorter: (a, b) => a.perKiloPrice - b.perKiloPrice,
            sortDirections: ["ascend", "descend"],
            render: (_, row) => {
                return (
                    <span>
                        {row.perKiloPrice ? row.perKiloPrice : ""}
                    </span>
                );
            },
        },
        {
            key: "description",
            title: "DESCRIPTION",
            dataIndex: "description",
            ...getColumnSearchProps("description"),
            render: (_, row) => {
                return (
                    <span style={{ maxWidth: "30ch" }} title={row.description} className="d-inline-block text-truncate">
                        {row.description}
                    </span>
                );
            },
        },
        {
            key: "store",
            title: "STORE",
            dataIndex: "store",
            ...getColumnSearchProps("store"),
        },
        {
            key: "storeAdminName",
            title: "STORE ADMIN NAME",
            dataIndex: "storeAdminName",
            ...getColumnSearchProps("storeAdminName"),
        },
        {
            key: "storeAdminMobile",
            title: "STORE ADMIN MOBILE",
            dataIndex: "storeAdminMobile",
            ...getColumnSearchProps("storeAdminMobile"),
        },
        {
            key: "date",
            title: "CREATION DATE",
            dataIndex: "date",
            ...getColumnSearchProps("date"),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "requestStatus",
            title: "STATUS",
            dataIndex: "requestStatus",
            filters: [
                { text: "Accepted", value: 2 },
                { text: "Rejected", value: 3 },
                { text: "Pending", value: 1 },
            ],
            onFilter: (value, record) => record?.requestStatusId === value,
        },
    ]

    useEffect(() => {
        getSalesRequests(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            })
    }, [])

    return (
        <section className="users sales-requests">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header
                            title="Sales Requests"
                        />
                        <SubHeader bgColor="#356ab3" title="All Sales Requests" />
                        <div className="users__table grid-table mrg-y-30">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"salesRequestId"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SalesRequests;