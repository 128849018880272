import { useState, useEffect } from "react";
import { toastError } from "helpers/toasters";
import { Modal, Spin } from "antd";
import { getUserSoldInfoForAdminApi } from "Network";

function SoldModal({ isModalOpen, onCancel, userId, title }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    getUserSoldInfoForAdminApi(
      userId,
      (res) => {
        setLoading(false);
        if (res.success) {
          setData(res.data);
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.message);
      }
    );
  }, [userId]);

  return (
    <Modal
      centered
      width={window.innerWidth >= 1440 ?
        "60%" : window.innerWidth > 767 ?
          "70%" : "100%"}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}
      open={isModalOpen}
      footer={false}
      closable={true}
      onCancel={onCancel}
      className="modal-with-no-header"
    >
      <h2 className="green-text fw-bold fsize-4 text-center mb-3 mb-xxl-4">{title}</h2>
      {
        loading ?
          <div className="py-5 d-flex justify-content-center align-items-center">
            <Spin size="large" />
          </div>
          :
          <div className="orders">
            {data?.length > 0 ?
              <div className="accordion w-100" id="accordionFlushExample">
                {data.map((ele, indx) =>
                (
                  <div className="accordion-item mb-4" key={ele.categoryId}>
                    <h3 className="accordion-header">
                      <button className="accordion-button flex-column gap-2 align-items-start shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-${ele.categoryId}`} aria-expanded="false" aria-controls={`#flush-${ele.categoryId}`}>
                        <span className="fw-bold fs-4 fw-bold">{ele.categoryName}</span>
                        <span className="fw-semibold">Total Quantity: {ele.totalQuantity}</span>
                        <span className="fw-semibold">Total Sold Price: {ele.totalPrice}</span>
                      </button>
                    </h3>
                    <div id={`flush-${ele.categoryId}`} className="accordion-collapse collapse show">
                      <div className="accordion-body">
                        <ul className="m-0 list-unstyled">
                          {ele.items.map((item, indx) => (
                            <li className="orders__order" key={`${ele.categoryId}-${item.itemId}-${indx}`}>
                              <div className="orders__description">
                                <h4 className="orders__paragraph text-truncate fs-5 mb-1 fw-bold" title={item?.item}>{item?.item}</h4>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <span className="fsize-9 fw-semibold">Quantity:</span>
                                <span className="fsize-9">
                                  {item?.soldQuantities}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <span className="fsize-9 fw-semibold">Total Sold Price:</span>
                                <span className="fsize-9">
                                  {item?.sellPrice}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <span className="fsize-9 fw-semibold">Sell Date:</span>
                                <span className="fsize-9">
                                  {item?.sellDate}
                                </span>
                              </div>
                            </li>)
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              : (
                <h4 className="text-center text-danger pt-3">User doesn't have sold items</h4>
              )}
          </div>
      }
    </Modal>
  );
}

export default SoldModal;
