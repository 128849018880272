import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { exportDataAsExcel } from "helpers/helpfulFunctions";

function GridTable({
  loading,
  columns,
  dataSource,
  rowId,
  allowExport,
  apiExport,
  onExport,
  isExporting,
  exportConfig,
  title,
  soldOrders,
  onChange,
  rowClassName,
  currentPage,
  totalCount,
  onPageChange,
  pageSize,
  onPageSizeChange,
  ordersTotalPrice
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const exportAllUserAsExcel = () => {
    if (apiExport) {
      onExport()
    } else {
      let currentData = filteredData.length === 0 ? structuredClone(dataSource) : structuredClone(filteredData)
      if (soldOrders) {
        currentData.unshift({ totalQuantity: soldOrders.totalQuantity, totalPrice: soldOrders.totalPrice })
      }
      exportDataAsExcel(currentData, exportConfig)
    }
  }

  useEffect(() => {
    if (title === "FinancialOrders" && dataSource) {
      const arr = filteredData.length > 0 ? filteredData : dataSource;
      const totalPrice = arr.reduce((pre, curr) => pre + parseFloat(curr.price), 0)
      setTotalPrice(totalPrice.toFixed(2))
    }
  }, [filteredData, dataSource, title]);


  return (
    <>
      {allowExport ? (
        <Button
          onClick={exportAllUserAsExcel}
          className="ms-auto mb-3 d-block"
          loading={isExporting}
        >
          Export To Excel
        </Button>
      ) : (
        ""
      )}
      {title === "Orders" || title === "FinancialOrders" ? (
        <p>
          <span className="fw-bold">Total Price: </span>
          {parseFloat(title === "Orders" ? ordersTotalPrice : totalPrice).toLocaleString()} EGP
        </p>
      ) : (
        ""
      )}
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={(row) => row[rowId]}
        scroll={{ x: 600 }}
        onChange={(pagination, filters, sorter, extra) => {
          if (extra.action === "filter") {
            setFilteredData(extra.currentDataSource)
          }
          if (onChange) {
            onChange(pagination, filters, sorter, extra)
          }
        }}
        rowClassName={rowClassName ? rowClassName : (record, index) =>
          index % 2 === 0 && "background-even-records"
        }
        pagination={{
          pageSize: pageSize,
          defaultPageSize: 10,
          responsive: true,
          hideOnSinglePage: false,
          current: currentPage,
          total: totalCount,
          onChange: onPageChange,
          onShowSizeChange: onPageSizeChange,
          showSizeChanger: true
        }}
      />
    </>
  );
}

export default GridTable;
