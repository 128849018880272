import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Select, Space } from "antd";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import { getOrderStatusApi, updateOrderStatusApi } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";

function OrderStatusModal({
  isOrderModalVisible,
  rowData = null,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);

  console.log(rowData);
  useEffect(() => {
    getOrderTypes();
  }, []);

  const getOrderTypes = () => {
    getOrderStatusApi(
      (success) => {
        if (success.success) {
          setOrderTypes(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };
  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };
  function handleOnFinish(values) {
    if (values) {
      const data = {
        orderId: rowData?.id,
        orderTypeId: +values.orderTypeId,
      };
      setIsModalLoading(true);
      updateOrderStatusApi(
        data,
        (success) => {
          if (success.success) {
            toastSuccess(success.message);
            handleOnCancel();
          } else {
            toastError(success.message);
            setIsModalLoading(false);
          }
        },
        (fail) => {
          if (fail.data?.error) {
            setIsModalLoading(false);
            toastError(fail.message);
          } else {
            setIsModalLoading(false);
            toastError();
          }
        }
      );
    }
  }

  return (
    isOrderModalVisible && (
      <Modal
        title={SubHeader}
        visible={isOrderModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={true}
        onCancel={onCancel}
      >
        <Form
          className="edit-actor__content  "
          onFinish={handleOnFinish}
          initialValues={{
            orderTypeId: rowData?.orderType === "Purchase And Store" ? 2 : 1,
          }}
        >
          <Row gutter={24}>
            <Col span={24}></Col>
            <Col span={24}>
              {" "}
              <div className="new-actor__name new-category mb-5">
                <Form.Item
                  label={"Select order type"}
                  name={"orderTypeId"}
                  className="fsize-7 wd-100"
                  rules={[
                    { required: true, message: "Please select order type" },
                  ]}
                >
                  <Select
                    selectName=""
                    className=" "
                    placeholder="Order type"
                    style={{
                      color: "#9A9FA5",
                      width: "100%",
                    }}
                    allowClear
                    getPopupContainer={trigger => trigger.parentElement}
                  >
                    {orderTypes?.map((unit, index) => (
                      <Select.Option value={unit?.id} key={index}>
                        {unit?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default OrderStatusModal;
