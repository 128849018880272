import { useState } from "react";
import { Form, Modal, Row, Space } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { sendAuctionDuesToUser } from "Network";

import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";

const TransferDuesModal = ({
    open,
    onCancel,
    onSubmit,
    rowData,
    SubHeader
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {
        if (isSubmitting) return
        setIsSubmitting(true)

        const payload = {
            auctionId: rowData.auctionId
        }

        sendAuctionDuesToUser(
            payload,
            (res) => {
                setIsSubmitting(false)
                if (res.success) {
                    onSubmit()
                    toastSuccess(res.message)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setIsSubmitting(false)
                toastError(res.message)
            }
        )
    }

    return (
        <Modal
            title={SubHeader}
            open={open}
            onCancel={onCancel}
            centered
            footer={false}
            closable={false}
            className="msg-modal auction-report"
        >
            <p className="text-center mb-4">Are you sure you want to transfer participant dues?</p>
            <p className="mb-2">Participants</p>
            <div className="auction-report__participants-holder d-flex flex-column gap-2 mb-3">
                {rowData?.userParticpients.map((ele) => (
                    <div className="auction-report__participant">
                        <p className="mb-1">Name: {ele.name}</p>
                        <p className="mb-1">Mobile: {ele.mobile}</p>
                        <p className="mb-1">Amount: {ele.amount} kg</p>
                    </div>
                ))}
            </div>
            <Form
                name="form"
                className="edit-actor__content mb-0"
                onFinish={handleSubmit}
            >
                <Row className="mrg-ys-20 mrg-x-auto">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName="Transfer"
                            loading={isSubmitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default TransferDuesModal;