import { useState, useEffect, useRef } from "react";
import { Button, Modal, Space, Input, Switch, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { getStoreCategories, deleteStoreCategory } from "Network";
import { baseUrl } from "services";

// components
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import StoreCategoryForm from "./StoreCategoryForm";
import ToSubcategoryModal from "./ToSubcategoryModal";

const StoreCategories = () => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState(false);
  const [isSwitchToSubcategoryModalOpen, setIsSwitchToSubcategoryModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      key: "image",
      title: "IMAGE",
      dataIndex: "image",
      render: (_, row) => (
        <Image
          width={90}
          height={70}
          src={baseUrl + row.image}
          className="sales-requests__receipt-img d-block img-fluid object-fit-cover"
          alt=""
        />
      ),
    },
    {
      key: "nameEn",
      title: "NAME (En)",
      dataIndex: "nameEn",
      ...getColumnSearchProps("nameEn"),
    },
    {
      key: "nameAr",
      title: "NAME (AR)",
      dataIndex: "nameAr",
      ...getColumnSearchProps("nameAr"),
    },
    {
      key: "isActive",
      title: "IS ACTIVE",
      dataIndex: "isActive",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, record) => value === record.isActive,
      render: (_, row) => <Switch disabled={true} checked={row.isActive} />,
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
    },
    {
      key: "id",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              style={{ backgroundColor: "#356ab3", color: "white" }}
              onClick={() => handlSwitchToSubcategory(row)}
            >
              Make as Subcategory
            </Button>{" "}
            <Button
              onClick={() => handleEditBtn(row)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Edit
            </Button>
            <Button type="danger" onClick={() => handlDeleteBtn(row)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleEditBtn = (row) => {
    setMode("edit");
    setRowData(row);
    setIsCategoryModalVisible(true);
  };

  const handlDeleteBtn = (row) => {
    setIsDeleteCategoryOpen(true);
    setRowData(row);
  };
  const handlSwitchToSubcategory = (row) => {
    setIsSwitchToSubcategoryModalOpen(true);
    setRowData(row);
  };

  const handleAddCategories = () => {
    setIsCategoryModalVisible(true);
    setMode("add");
  };

  const handleModalCancel = () => {
    setIsCategoryModalVisible(false);
    setIsSwitchToSubcategoryModalOpen(false);
    setIsDeleteCategoryOpen(false)
    setMode("add");
    setRowData(null)
  };

  const handleDeleteCategory = () => {
    if (isDeleting) return;
    setIsDeleting(true);
    deleteStoreCategory(
      rowData.id,
      (res) => {
        setIsDeleting(false);
        if (res.success) {
          setIsDeleteCategoryOpen(false);
          toastSuccess(res.message);
          setLoading(true)
          setRenderMe((prev) => !prev);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsDeleting(false);
        toastError(res.message);
      }
    );
  };

  useEffect(() => {
    getStoreCategories(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        if (res.data?.error) {
          toastError(res.message);
        }
      }
    );
  }, [renderMe]);

  return (
    <section className="users store-items">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Store Categories"
              plusButtonName={"Add Store Category"}
              plusButtonAction={handleAddCategories}
            />
            <SubHeader bgColor="#356ab3" title="All Store Categories" />
            <div className="users__table store-items__data-table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
        {isCategoryModalVisible && (
          <StoreCategoryForm
            isCategoryModalVisible={isCategoryModalVisible}
            mode={mode}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${mode === "add"
                  ? "Add New Store Category"
                  : "Edit Store Category"
                  }`}
                closeButton
                onCancel={handleModalCancel}
              />
            }
            onSubmit={() => {
              setLoading(true)
              setRenderMe(!renderMe);
              handleModalCancel();
            }}
            onCancel={handleModalCancel}
            primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isDeleteCategoryOpen && (
          <Modal
            title="Delete Store Category"
            open={isDeleteCategoryOpen}
            onOk={handleDeleteCategory}
            okText="Delete"
            centered
            okButtonProps={{ loading: isDeleting }}
            onCancel={handleModalCancel}
          >
            <span>Are you sure you want to delete this store category?</span>
          </Modal>
        )}
        {isSwitchToSubcategoryModalOpen && (
          <ToSubcategoryModal
            rowData={rowData}
            isSwitchToSubcategoryModalOpen={isSwitchToSubcategoryModalOpen}
            onSubmit={() => {
              setLoading(true)
              setRenderMe(!renderMe)
              handleModalCancel()
            }}
            onCancel={handleModalCancel}
          />
        )}
      </div>
    </section>
  );
};

export default StoreCategories;
