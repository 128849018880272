import { useState } from "react";
import { Col, Form, Input, InputNumber, Modal, Row, Space } from "antd";
import { makeOfferForCategoryApi } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import { doc, updateDoc, increment } from "firebase/firestore";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import db from "../../firebase";

function OfferModal({
    isOrderModalVisible,
    rowData = null,
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onCancel,
}) {
    const [isModalLoading, setIsModalLoading] = useState(false);

    const handleNumberValidation = (_, value) => {
        if (value && (isNaN(+value) || +value < 1)) {
            return Promise.reject(new Error(`Please, enter a valid ${_.field === "quantity" ? "quantity" : "price"}`));
        } else {
            return Promise.resolve()
        }
    }

    const handlePreventLetters = (e) => {
        if (e.key === "Backspace" || e.key === "." || e.key.startsWith("Arrow")) return;
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const updateFireStore = async (data) => {
        await data.forEach(async (user) => {
            updateDoc(doc(db, `users`, user), {
                unReadMessagesCount: increment(1),
            });
        });
    };

    const handleOnCancel = () => {
        setIsModalLoading(false);
        onCancel();
    };

    function handleOnFinish(values) {
        if (isModalLoading) return;
        if (values && rowData) {
            const data = {
                pricePerKilo: +values?.pricePerKilo,
                categoryId: rowData?.id,
            };
            setIsModalLoading(true);
            makeOfferForCategoryApi(
                data,
                (success) => {
                    setIsModalLoading(false)
                    if (success?.success) {
                        toastSuccess(success.message);
                        updateFireStore(success.data);
                    } else {
                        toastError(success.message);
                    }
                    handleOnCancel();
                },
                (fail) => {
                    setIsModalLoading(false)
                    toastError(fail.message);
                    handleOnCancel();
                }
            );
        }
    }

    return (
        isOrderModalVisible && (
            <Modal
                title={SubHeader}
                open={isOrderModalVisible}
                className="msg-modal text-center"
                footer={false}
                closable={true}
                onCancel={onCancel}
                centered
            >
                <Form className="edit-actor__content  " onFinish={handleOnFinish}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <h4>Send offer to users who bought from this type</h4>
                        </Col>
                        <Col span={24}>
                            {" "}
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Price per Kilo
                                    </label>
                                </div>
                                <Form.Item
                                    className=" new-category__form-item "
                                    name="pricePerKilo"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the price per kilo",
                                        },
                                        { validator: handleNumberValidation }
                                    ]}
                                >
                                    <InputNumber
                                        min={1}
                                        className="w-100 d-block search__searchField py-2"
                                        placeholder="Enter Price per kilo"
                                        onKeyDown={handlePreventLetters}
                                        controls={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mrg-ys-20 mrg-x-auto">
                        <Space size={20}>
                            <SecondaryButton
                                buttonName={secondaryBtnName}
                                onClick={handleOnCancel}
                            />
                            <PrimaryButton
                                buttonName={primaryBtnName}
                                loading={isModalLoading}
                                type="submit"
                            />
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )
    );
}

export default OfferModal;