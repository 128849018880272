import { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, Space, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getStores, updateStore } from "../../Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import StoreModal from "./StoreModal";

const Stores = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [rowData, setRowData] = useState("");
    const [renderMe, setRenderMe] = useState(false);
    const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
    const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
    const [statusModalLoading, setStatusModalLoading] = useState(false);
    const [mode, setMode] = useState("add");
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const exportConfig = {
        tab: `Big Scrappers Stores Report`,
        baseProps: [
            { title: "Name(Ar)", prop: "nameAr" },
            { title: "Name(En)", prop: "name" },
            { title: "Address", prop: "address" },
            { title: "Is Active", prop: "isActive", values: { true: "Active", false: "Deactive" } },
        ]
    }

    const handleOpenStatusModal = (row) => {
        setRowData(row);
        setIsStatusModalVisible(true)
    }

    const handleOpenAddModal = () => {
        setIsStoreModalOpen(true)
        setMode("add")
    }

    const handleEditStore = (data) => {
        setIsStoreModalOpen(true)
        setMode("edit")
        setRowData(data)
    }

    const handleCancel = () => {
        setIsStoreModalOpen(false);
        setRowData("")
        setRenderMe((prev) => !prev);
        setMode("add");
    };

    const handleStatusChange = () => {
        if (statusModalLoading) return;
        setStatusModalLoading(true);
        const payload = {
            id: rowData.id,
            isActive: !rowData.isActive,
            name: rowData.name,
            nameAr: rowData.nameAr,
            address: rowData.address,
        }
        updateStore(
            payload,
            (res) => {
                setIsStatusModalVisible(false)
                setStatusModalLoading(false)
                if (res.success) {
                    toastSuccess(res.message)
                    setRenderMe(!renderMe)
                } else {
                    toastError(res.message)
                }
            }, (res) => {
                setIsStatusModalVisible(false)
                setStatusModalLoading(false)
                toastError(res.message)
            }
        )
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: "nameAr",
            title: "NAME(AR)",
            dataIndex: "nameAr",
            ...getColumnSearchProps("nameAr"),
        },
        {
            key: "name",
            title: "NAME(EN)",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
        },
        {
            key: "address",
            title: "ADDRESS",
            dataIndex: "address",
            ellipsis: true,
            ...getColumnSearchProps("address"),
            render: (_, row) => {
                return (
                    <span className="store-items__data-table__description text-truncate d-block">
                        {row.address}
                    </span>
                )
            }
        },
        {
            key: "isActive",
            title: "IS ACTIVE",
            dataIndex: "isActive",
            filters: [{ text: "Active", value: true }, { text: "Deactive", value: false }],
            onFilter: (value, record) => record?.isActive === value,
            render: (_, row) => {
                return (
                    <Switch checked={row.isActive} onChange={() => handleOpenStatusModal(row)} />
                );
            },
        },
        {
            key: "id",
            title: "ACTION",
            render: (_, row) => {
                return (
                    <Space size={5}>
                        <Button
                            onClick={() => handleEditStore(row)}
                            style={{ backgroundColor: "green", color: "white" }}
                        >
                            Edit
                        </Button>
                    </Space>
                );
            },
        },
    ]


    useEffect(() => {
        getStores(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            })
    }, [renderMe])

    return (
        <section className="users">
            <div className="users__all-contents ">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header
                            title="Big Scrappers Stores"
                            plusButtonName={"Add Store"}
                            plusButtonAction={handleOpenAddModal}
                        />
                        <SubHeader bgColor="#356ab3" title="All Stores" />
                        <div className="users__table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"id"}
                                allowExport={true}
                                title={"Stores"}
                                exportConfig={exportConfig}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isStoreModalOpen && (
                <StoreModal
                    open={isStoreModalOpen}
                    mode={mode}
                    rowData={rowData}
                    SubHeader={
                        <SubHeader
                            bgColor="#F58234"
                            title={`${mode === "add" ? "Add New" : "Edit"} Store`}
                            closeButton
                            onCancel={handleCancel}
                        />
                    }
                    onCancel={handleCancel}
                />
            )}
            {isStatusModalVisible && (
                <Modal
                    title={
                        <SubHeader
                            bgColor="#F58234"
                            title={`${rowData.isActive ? "Deactivate" : "Activate"} Store`}
                            closeButton
                            onCancel={() => {
                                setIsStatusModalVisible(false)
                            }}
                        />
                    }
                    open={isStatusModalVisible}
                    onOk={handleStatusChange}
                    okText={rowData.isActive ? "Deactivate" : "Activate"}
                    closable={false}
                    centered
                    confirmLoading={statusModalLoading}
                >
                    <span>You are about to {rowData.isActive ? "deactivate" : "activate"} this store!</span>
                </Modal>
            )}
        </section>
    );
}

export default Stores;