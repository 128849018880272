import { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
} from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { addCategoryApi, editCategoryApi } from "Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import UploadImage from "modules/SubComponents/UploadImage";

const CategoryModal = ({
  isCategoryModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const [form] = Form.useForm(); 
  const initialValues = {
    nameEn: "",
    nameAr: "",
    unitValue: "",
    image: "",
    // showForScraption: false,
  };

  const handlePreviewImgChange = (value) => {
    setPreviewImg(value);
  };

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const validateQuantityInput = (_, value) => {
    if (value && (isNaN(value) || value.toString().includes("e"))) {
      return Promise.reject(new Error("Please, enter a valid unit"));
    }
    if (value && +value <= 0) {
      return Promise.reject(new Error("Unit can't be <= 0"));
    }
    return Promise.resolve();
  };

  const handlePreventLetters = (e) => {
    if (
      e.key === "Backspace" ||
      e.key === "." ||
      (e.key.startsWith("Arrow") &&
        (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight")))
    )
      return;
    if (/\D+/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  function handleOnFinish(values) {
    if (values) {
      const formData = new FormData();
      const payload = {
        Name: values.nameEn,
        NameAr: values.nameAr,
        UnitValue: +values.unitValue,
        ForScraptionShown: false,
      };
      if (values.image) {
        payload.Image = values.image;
      }
      if (mode === "edit") {
        payload.id = rowData.id;
      }
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      setIsModalLoading(true);
      if (mode === "add") {
        addCategoryApi(
          formData,
          (success) => {
            if (success.success) {
              toastSuccess(success.message);
              handleOnCancel();
            } else {
              toastError(success.message);
              setIsModalLoading(false);
            }
          },
          (fail) => {
            if (fail.data?.error) {
              setIsModalLoading(false);
              toastError(fail.message);
            } else {
              setIsModalLoading(false);
              toastError();
            }
          }
        );
      } else {
        editCategoryApi(
          formData,
          (success) => {
            if (success.success) {
              toastSuccess(success.message);
              handleOnCancel();
            } else {
              toastError(success.message);
              setIsModalLoading(false);
            }
          },
          (fail) => {
            if (fail.data?.error) {
              setIsModalLoading(false);
              toastError(fail.message);
            } else {
              setIsModalLoading(false);
              toastError();
            }
          }
        );
      }
    }
  }

  useEffect(() => {
    if (rowData && mode === "edit") {
      form.setFieldsValue({
        nameEn: rowData.name,
        nameAr: rowData.nameAR,
        unitValue: +rowData.unitValue,
        image: rowData.image,
        // showForScraption: rowData.forScraptionShown
      });
    }
  }, [rowData, mode, form]);

  return (
    isCategoryModalVisible && (
      <Modal
        title={SubHeader}
        open={isCategoryModalVisible}
        className="msg-modal"
        footer={false}
        closable={false}
        centered
      >
        <Form
          form={form}
          className="edit-actor__content  "
          onFinish={handleOnFinish}
          initialValues={initialValues}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type Name In English
                </label>
                <Form.Item
                  className=" new-category__form-item "
                  name="nameEn"
                  rules={[
                    {
                      required: true,
                      message: "Name in English is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="py-2 search__searchField"
                    placeholder="Enter name in English"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type Name In Arabic
                </label>
                <Form.Item
                  className=" new-category__form-item "
                  name="nameAr"
                  rules={[
                    {
                      required: true,
                      message: "Name in Arabic is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    dir="rtl"
                    className="py-2 search__searchField"
                    placeholder="Enter name in Arabic"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type Unit
                </label>
                <Form.Item
                  className=" new-category__form-item "
                  name="unitValue"
                  rules={[
                    {
                      required: true,
                      message: "Unit is required",
                    },
                    { validator: validateQuantityInput },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter unit"
                    className="search__searchField py-1 w-100"
                    onKeyDown={handlePreventLetters}
                    keyboard={false}
                    controls={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Type Image
                  </label>
                </div>
                <UploadImage
                  mode={mode}
                  image={rowData?.image}
                  formRef={form}
                  previewImage={previewImg}
                  onPreviewCoverChange={handlePreviewImgChange}
                  acceptGIF={true}
                  title={"Upload Image +"}
                  validationRules={[
                    {
                      required: true,
                      message: "Please, Upload an image for this type",
                    },
                  ]}
                />
              </div>
            </Col>
            {/* <Col span={24}>
                            <Form.Item
                                name="showForScraption"
                                valuePropName="checked"
                            >
                                <Checkbox>For Big Scrappers</Checkbox>
                            </Form.Item>
                        </Col> */}
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
};

export default CategoryModal;
