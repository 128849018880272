function NotHaveAccess() {
  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__usersData">
          <h2>Sorry, you don't have an access to this page.</h2>;
        </div>
      </div>
    </section>
  );
}

export default NotHaveAccess;
