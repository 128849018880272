const ROUTES = {
  SIGNIN: "/",
  LOGOUT: "/logout",
  NOT_FOUND: "*",
  HOME: "/home",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  FACTORIES: "/factories",
  SUPPLIERS: "/suppliers-requests",
  Orders: "/orders",
  SOLD_ORDERS: "/sold-orders",
  CATEGORIES: "/categories",
  PENDINGORDERS: "/pending-orders",
  PENDINGOFFERS: "/pending-offers",
  ITEMS: "/items",
  ADMINS: "/admins",
  ADDEDITADMIN: "/add-edit-admin",
  USERSINFORMATION: "/user-information",
  // PURCHASEREQUESTS:"/purchase-requests",
  OFFERS: "/offers",
  /** FACTORY_ADMIN */
  FACTORY_ADMIN_REQUESTS: "factory-admin-requests",
  SUPPLIER_ADMIN_REQUESTS: "supplier-admin-requests",
  NOT_HAVE_ACCESS: "not-have-access",
  LUCKY_HOURS: "lucky-hours",
  SALES_REQUESTS: "sales-requests",
  AUCTIONS: "/auctions",
  STORES: "/stores",
  SETTINGS: "/settings",
  STORE_ITEMS: "/store-items",
  STORE_ORDERS: "/store-orders",
  STORE_CATEGORIES: "/store-categories",
  STORE_SUBCATEGORIES: "/store-subcategories",
  AUCTION_REPORT: "/auction-report",
  BUY: "/buy-items",
  BUYPENDINGORDERS: "/buy-pending-orders",
  SUPPLY: "/supply-types",
  SUPPLYPENDINGORDERS: "/supply-pending-orders",
  ACCEPTED_BUY_AND_SUPPLY_REPORT: "/accepted-buy-and-supply-report",
  SALES_PERSON_REPORT: "/sales-person-report",
  MERCHANT_STORE:"/merchant-store"
};

export default ROUTES;
