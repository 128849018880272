import { useState, useEffect, useRef } from "react";
import { Button, Input, Modal, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { toastError, toastSuccess } from "helpers/toasters";
import Highlighter from "react-highlight-words";
import {
  assignUserToSalesPersonApi,
  getSalesPersonApi,
  getUserApiV2,
} from "Network";
import { exportDataAsExcel } from "helpers/helpfulFunctions";

import SubHeader from "../SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import BalanceModal from "./BalanceModal";
import SoldModal from "./SoldModal";
import Header from "modules/SubComponents/Header";

function Users() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState("");
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [isSoldModalOpen, setIsSoldModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [salesPersons, setSalesPersons] = useState([]);
  const [selectedPersonId, setSelectedPersonId] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [render, setRender] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    userName: "",
    userMobile: "",
    salesPerson: ""
  })
  const searchInput = useRef(null);

  const handleOpenBalance = (row) => {
    setRowData(row);
    setIsBalanceModalOpen(true);
  };

  const handleOpenSold = (row) => {
    setRowData(row);
    setIsSoldModalOpen(true);
  };

  const handleAssignSalesPerson = (personId, record) => {
    setSelectedPersonId(personId);
    setSelectedUser(record?.userId);
    setShowModal(true);
  };

  const handleOk = () => {
    const data = {
      userId: selectedUser,
      salesPersonId: selectedPersonId,
    };
    assignUserToSalesPersonApi(
      data,
      (success) => {
        if (success?.success) {
          toastSuccess(success.message);
          setRender((prev) => !prev);
          setShowModal(false);
        } else {
          toastError(success.message);
          setShowModal(false);
        }
      },
      (fail) => {
        toastError(fail.message);
        setShowModal(false);
      }
    );
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues }
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }

  const handlePageChange = (page) => {
    setLoading(true)
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Name", prop: "fullName" },
      { title: "Mobile Number", prop: "mobile" },
      { title: "Sales Person", prop: "salesPerson" },
    ]
    const payload = {
      isExport: true,
      currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
        delete payload[property]
      }
    }
    getUserApiV2(
      payload,
      (res) => {
        if (res.success) {
          exportDataAsExcel(res.data.response, { baseProps, tab: "Users Report" })
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setLoading(true)
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", selectedKeys[0])
        break;
      case "mobile":
        updateSearchValues("userMobile", selectedKeys[0])
        break;
      case "salesPerson":
        updateSearchValues("salesPerson", selectedKeys[0])
        break;
      default:
        break;
    }
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    setLoading(true);
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", "")
        break;
      case "mobile":
        updateSearchValues("userMobile", "")
        break;
      case "salesPerson":
        updateSearchValues("salesPerson", "")
        break;
      default:
        break;
    }
    clearFilters();
    confirm()
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "fullName",
      title: "NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "mobile",
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "salesPerson",
      title: "SALES PERSON",
      dataIndex: "salesPerson",
      ...getColumnSearchProps("salesPerson"),
    },
    {
      key: "person",
      title: "ASSIGN USER TO SALES PERSON",
      dataIndex: "person",
      width: 200,
      // className: "custom-column",
      render: (_, record) => (
        <Select
          className="assign-user-to-sales-person"
          allowClear
          getPopupContainer={trigger => trigger.parentElement}
          onChange={(personId) => {
            if (personId) {
              handleAssignSalesPerson(personId, record)
            }
          }}
          options={salesPersons}
        />
      ),
    },
    {
      key: "userId",
      title: "ACTION",
      dataIndex: "action",
      render: (_, row) => (
        <Space size={5}>
          <Button type="primary" onClick={() => handleOpenBalance(row)}>
            View Balance
          </Button>
          <Button
            className="main-btn-green"
            onClick={() => handleOpenSold(row)}
          >
            View Sold Items
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getUserApiV2(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.err_msg);
      }
    );
  }, [render, currentPage, searchValues, pageSize]);

  useEffect(() => {
    getSalesPersonApi(
      (res) => {
        if (res.success) {
          let persons = [];
          res.data.forEach((person) => {
            persons.push({ value: person.id, label: person.name });
          });
          setSalesPersons(persons);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.message);
      }
    );
  }, [])

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Users" />
            <SubHeader bgColor="#356ab3" title="All Users" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"userId"}
                allowExport={true}
                apiExport={true}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                title={"Users"}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isBalanceModalOpen && (
        <BalanceModal
          isModalOpen={isBalanceModalOpen}
          onCancel={() => setIsBalanceModalOpen(false)}
          userId={rowData.userId}
          title={`${rowData.fullName.split(" ")[0]}'s Balance`}
        />
      )}
      {isSoldModalOpen && (
        <SoldModal
          isModalOpen={isSoldModalOpen}
          onCancel={() => setIsSoldModalOpen(false)}
          userId={rowData.userId}
          title={`${rowData.fullName.split(" ")[0]}'s Sold Info`}
        />
      )}
      {showModal && (
        <Modal
          title={"Assign user to Sales person"}
          open={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <span>{`Are you sure you want to assign user to this sales person?`}</span>
        </Modal>
      )}
    </section>
  );
}

export default Users;