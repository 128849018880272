import React, { useState } from "react";
import { Form, Row, Col, Button, Input } from "antd";
import WebsiteTitle from "helpers/WebsiteTitle";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "helpers/toasters";
import { signinAdmin } from "Network";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import { useDispatch } from "react-redux";
import { login } from "store/authorization";
import { updateCurrentPath } from "store/dynamicData";
// import { requestForToken } from "../../firebase";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectRoute = (roles) => {
    if (roles.includes(1) || roles.includes(4) ||roles.includes(6)) {
      dispatch(updateCurrentPath("users"));
      navigate(ROUTES.USERS);
      return;
    }
    if (roles.includes(2)) {
      dispatch(updateCurrentPath("orders"));
      navigate(ROUTES.Orders);
      return;
    }
    if (roles.includes(3)) {
      dispatch(updateCurrentPath("factory-admin-requests"));
      navigate(ROUTES.FACTORY_ADMIN_REQUESTS);
      return;
    }
    if (roles.includes(5)) {
      dispatch(updateCurrentPath("supplier-admin-requests"));
      navigate(ROUTES.SUPPLIER_ADMIN_REQUESTS);
      return;
    }
    if (roles.includes(8)) {
      dispatch(updateCurrentPath("store-items"));
      navigate(ROUTES.STORE_ITEMS);
      return;
    }
    if (roles.includes(13)) {
      dispatch(updateCurrentPath("merchant-store"));
      navigate(ROUTES.MERCHANT_STORE);
      return;
    }
  };

   function onFinish(values) {
    if (values?.userName && values?.password) {
      setLoading(true);
      // const FBToken = await requestForToken();
      // const data = {...values,firebaseToken:FBToken }
      const data = {...values }
      signinAdmin(
        data,
        (success) => {
          setLoading(false);
          if (success?.success) {
            setLoading(false);
            toastSuccess(success.message);
            dispatch(login(success.data));
            handleRedirectRoute(success.data.roles);
            // dispatch(updateCurrentPath("items"));
            // navigate(ROUTES.USERS);
          } else {
            toastError(success.message);
            setLoading(false);
          }
        },
        (fail) => {
          if (fail.data?.error) {
            setLoading(false);
            toastError(fail.data.err_msg);
          } else {
            setLoading(false);
            toastError();
          }
        }
      );
    }
  }

  return (
    <section className="sign-in">
      <WebsiteTitle title="Login" />
      <div className="content p-4">
        <div
          className=" p-4"
          style={{ backgroundColor: "#031830", borderRadius: "25px" }}
        >
          <div className="title text-center mrg-ye-50">SignIn</div>
          <Row>
            <Col
              xs={{ offset: 3, span: 18 }}
              sm={{ offset: 3, span: 18 }}
              md={{ offset: 6, span: 12 }}
            >
              <Form onFinish={onFinish}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name="userName"
                      placeholder={t("User Name")}
                      rules={[
                        {
                          required: true,
                          message: "Please provide your user name",
                        },
                      ]}
                    >
                      <Input className={"form-item"} placeholder="User Name" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please provide your password ",
                        },
                      ]}
                    >
                      <Input.Password
                        className={"form-item"}
                        placeholder="Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ offset: 3, span: 18 }}
                    sm={{ offset: 3, span: 18 }}
                    md={{ offset: 5, span: 14 }}
                  >
                    <Button
                      loading={loading}
                      className="btn-login "
                      htmlType="submit"
                    >
                      SignIn
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
