import { Col, Form, Input, Modal, Row, Select, Space } from "antd";
import { useState, useEffect } from "react";
import {
  AddSupplierRequest,
  getCategoriesApi,
} from "Network";
import { toastError, toastSuccess } from "helpers/toasters";

// components
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";

const RequestModal = ({
  visible,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [form] = Form.useForm();

  const handleNumberValidation = (_, value) => {
    if (value && (isNaN(+value) || +value < 1)) {
      return Promise.reject(new Error(`Please, enter a valid ${_.field === "quantity" ? "quantity" : "price"}`));
    } else {
      return Promise.resolve()
    }
  }

  useEffect(() => {
    getCategoriesApi(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.name };
          });
          setCategories(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        } else {
          toastError(res.message);
        }
      }
    );
  }, []);

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const handleOnFinish = (values) => {
    if (values) {
      const data = {
        categoryId: values.categoryId,
        pricePerKilo: +values.pricePerKilo,
        quantity: +values.quantity,
      };
      setIsModalLoading(true);

      AddSupplierRequest(
        data,
        (success) => {
          if (success.success) {
            toastSuccess(success.message);
            handleOnCancel();
          } else {
            toastError(success.message);
            setIsModalLoading(false);
          }
        },
        (fail) => {
          if (fail.data?.error) {
            setIsModalLoading(false);
            toastError(fail.message);
          } else {
            setIsModalLoading(false);
            toastError();
          }
        }
      );
    }
  };

  return (
    visible && (
      <Modal
        title={SubHeader}
        open={visible}
        centered
        className="msg-modal text-center"
        footer={false}
        closable={false}
      >
        <Form
          form={form}
          className="edit-actor__content"
          onFinish={handleOnFinish}
          initialValues={{
            category: undefined,
            pricePerKilo: "",
            quantity: "",
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a type"
                    loading={categories ? false : true}
                    options={categories ? categories : []}
                    getPopupContainer={trigger => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Price per Kilo
                  </label>
                </div>
                <Form.Item
                  className="new-category__form-item"
                  name="pricePerKilo"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the price per kilo",
                    },
                    { validator: handleNumberValidation }
                  ]}
                >
                  <Input
                    type="number"
                    min={1}
                    className="search__searchField py-2"
                    placeholder="Enter Price per kilo"
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Quantity
                  </label>
                </div>
                <Form.Item
                  className=" new-category__form-item "
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter quantity",
                    },
                    { validator: handleNumberValidation }
                  ]}
                >
                  <Input
                    type="number"
                    min={1}
                    className="search__searchField py-2"
                    placeholder="Enter quantity"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
};

export default RequestModal;
