import { useState, useEffect, useRef } from "react";
import { Button, Input, Space, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { EyeOutlined, SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getOrdersV2 } from "Network";
import { toastError } from "helpers/toasters";
import { exportDataAsExcel } from "helpers/helpfulFunctions";

// components
import OrderModal from "./OrderModal";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import OrderStatusModal from "./OrderStatusModal";
import Header from "modules/SubComponents/Header";
import ViewExchange from "./ViewExchange";

// assets
import editIcon from "../../assets/icons/pen.svg";

function Orders() {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewExchangeModalOpen, setIsViewExchangeModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    userName: "",
    userMobile: "",
    item: "",
    quantity: "",
    remainingQuantity: "",
    unitPrice: "",
    totalPrice: "",
    paymentType: "",
    orderType: "",
    from: "",
    to: ""
  })
  const [selectedRange, setSelectedRange] = useState([]);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);
  const roles = useSelector((store) => store.auth.authorization?.roles);
  const isFinancialAdmin = roles ? roles.includes(2) : null;

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues }
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }

  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate })
    } else {
      updateSearchValues("from", "", { property: "to", value: "" })
    }
  };

  const handlePageChange = (page) => {
    setLoading(true)
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Name", prop: "fullName" },
      { title: "Mobile", prop: "phone" },
      { title: "Item", prop: "item" },
      { title: "Quantity", prop: "quantity" },
      { title: "Remaining Quantity", prop: "remainingQuantity" },
      { title: "Unit Price", prop: "unitPrice" },
      { title: "Total Price", prop: "price" },
      { title: "Payment Type", prop: "paymentType" },
      { title: "Order Type", prop: "orderType" },
      { title: "Creation Date", prop: "creationDate" },
    ]
    const payload = {
      isExport: true,
      currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
        delete payload[property]
      }
    }
    getOrdersV2(
      payload,
      (res) => {
        if (res.success) {
          exportDataAsExcel(res.data.orders, { baseProps, tab: "All Orders Report" })
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setLoading(true)
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", selectedKeys[0])
        break;
      case "phone":
        updateSearchValues("userMobile", selectedKeys[0])
        break;
      case "item":
        updateSearchValues("item", selectedKeys[0])
        break;
      case "quantity":
        updateSearchValues("quantity", selectedKeys[0])
        break;
      case "remainingQuantity":
        updateSearchValues("remainingQuantity", selectedKeys[0])
        break;
      case "unitPrice":
        updateSearchValues("unitPrice", selectedKeys[0])
        break;
      case "price":
        updateSearchValues("totalPrice", selectedKeys[0])
        break;
      case "paymentType":
        updateSearchValues("paymentType", selectedKeys[0])
        break;
      case "orderType":
        updateSearchValues("orderType", selectedKeys[0])
        break;
      default:
        break;
    }
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    setLoading(true);
    switch (dataIndex) {
      case "fullName":
        updateSearchValues("userName", "")
        break;
      case "phone":
        updateSearchValues("userMobile", "")
        break;
      case "item":
        updateSearchValues("item", "")
        break;
      case "quantity":
        updateSearchValues("quantity", "")
        break;
      case "remainingQuantity":
        updateSearchValues("remainingQuantity", "")
        break;
      case "unitPrice":
        updateSearchValues("unitPrice", "")
        break;
      case "price":
        updateSearchValues("totalPrice", "")
        break;
      case "paymentType":
        updateSearchValues("paymentType", "")
        break;
      case "orderType":
        updateSearchValues("orderType", "")
        break;
      default:
        break;
    }
    clearFilters();
    confirm()
  };

  const handleOpenExchangeModal = (rowData) => {
    setRowData(rowData)
    setIsViewExchangeModalOpen(true)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              return clearFilters && handleReset(clearFilters, dataIndex, confirm);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, row) =>
      searchedColumn === dataIndex ?
        (dataIndex === "paymentType" && text === "Exchange" ?
          <div className="d-flex gap-2 align-items-center">
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
            <button
              type="button"
              onClick={() => handleOpenExchangeModal(row)}
              className="border-0 d-flex flex-column align-items-center outline-0 bg-transparent">
              <EyeOutlined />
            </button>
          </div> : (
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          )
        ) : dataIndex === "paymentType" && text === "Exchange" ?
          <div className="d-flex gap-2 align-items-center">
            <span>{text}</span>
            <button
              type="button"
              onClick={() => handleOpenExchangeModal(row)}
              className="border-0 d-flex flex-column align-items-center outline-0 bg-transparent">
              <EyeOutlined />
            </button>
          </div> : (
            text
          ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="all-orders-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text
  });
  const columns = [
    {
      key: "fullName",
      title: "NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "phone",
      title: "MOBILE",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      key: "item",
      title: "ITEM",
      dataIndex: "item",
      ...getColumnSearchProps("item"),
    },
    {
      key: "quantity",
      title: "QUANTITY",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),
    },
    {
      key: "remainingQuantity",
      title: "REMAINING QUANTITY",
      dataIndex: "remainingQuantity",
      ...getColumnSearchProps("remainingQuantity"),
    },
    {
      key: "unitPrice",
      title: "UNIT PRICE",
      dataIndex: "unitPrice",
      ...getColumnSearchProps("unitPrice"),
    },
    {
      key: "price",
      title: "TOTAL PRICE",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
    },
    {
      key: "paymentType",
      title: "PAYMENT TYPE",
      dataIndex: "paymentType",
      ...getColumnSearchProps("paymentType"),
    },
    {
      key: "orderType",
      title: "ORDER TYPE",
      dataIndex: "orderType",
      width: 200,
      ...getColumnSearchProps("orderType"),
      render: (_, row) => (
        <div
          className="w-100 d-flex justify-content-between align-items-center"
        >
          <span>{row.orderType}</span>
          {isFinancialAdmin ? null : (
            <div className="cast-actions-edit ">
              <img
                role="button"
                src={editIcon}
                alt=""
                onClick={(event) => handleOpenStatusModal(event, row)}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
  ];

  const handleOpenStatusModal = (e, row) => {
    setRowData(row);
    setIsStatusModalOpen(true);
  };
  const handleOnCancel = () => {
    setRowData({});
    setIsStatusModalOpen(false);
    setIsModalOpen(false);
    setRenderMe((prev) => !prev);
  };

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getOrdersV2(
      payload,
      (res) => {
        if (res.success) {
          setLoading(false);
          setDataSource(res.data.orders);
          setTotalCount(res.data.totalCount);
          setTotalPrice(res.data.totalPrice)
        } else {
          toastError(res.message);
          setLoading(false);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.err_msg);
      }
    );
  }, [renderMe, currentPage, pageSize, searchValues]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Orders" />
            <SubHeader bgColor="#356ab3" title="All Orders" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
                allowExport={true}
                apiExport={true}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                title={"Orders"}
                ordersTotalPrice={totalPrice}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <OrderModal
            isOrderModalVisible={isModalOpen}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={"Notify user for new buyer"}
              />
            }
            onCancel={handleOnCancel}
            primaryBtnName={`Order`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isStatusModalOpen && (
          <OrderStatusModal
            isOrderModalVisible={isStatusModalOpen}
            rowData={rowData}
            SubHeader={
              <SubHeader bgColor="#F58234" title={"Change Order Type"} />
            }
            onCancel={handleOnCancel}
            primaryBtnName={`Change`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isViewExchangeModalOpen && (
          <ViewExchange
            open={isViewExchangeModalOpen}
            rowData={rowData}
            onCancel={() => setIsViewExchangeModalOpen(false)}
          />
        )}
      </div>
    </section>
  );
}

export default Orders;
