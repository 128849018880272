import { useEffect, useState } from "react";
import { Col, Form, InputNumber, Modal, Row, Space } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { handleAllowNumbersOnly } from "helpers/helpfulFunctions";
import { editTypePriceByMerchant } from "Network";

import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const UpdatePriceModal = ({
    open,
    rowData,
    SubHeader,
    onCancel,
    onSubmit,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        price: ""
    }

    const handlePriceValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error("Please, enter price per kilo"))
        } else if (!/^[\d.]+$/.test(value)) {
            return Promise.reject(new Error("Please, enter a valid price"))
        } else if (value > 10000) {
            return Promise.reject(new Error("Maximum price is 10,000"))
        } else if (value < 0) {
            return Promise.reject(new Error("Minimum price is 0"))
        }
        return Promise.resolve()
    }

    const handleFinish = (values) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const payload = {
            typeId: rowData.id,
            pricePerKilo: values.price
        }

        editTypePriceByMerchant(
            payload,
            (res) => {
                setIsSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message)
                    onSubmit();
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setIsSubmitting(false);
                toastError(res.message);
            }
        )
    }

    useEffect(() => {
        if (rowData) {
            form.setFieldsValue({ price: rowData.perKiloPrice, })
        }
    }, [rowData])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={handleFinish}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Price Per Kilo
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="price"
                                rules={[
                                    {
                                        validator: handlePriceValidation
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter Price"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            type="submit"
                            loading={isSubmitting}
                            buttonName="Save"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default UpdatePriceModal;