import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Space, Switch } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { addStore, updateStore } from "Network";

import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const StoreModal = ({
    mode,
    rowData,
    SubHeader,
    onCancel,
    open
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        nameEn: "",
        nameAr: "",
        address: "",
        isActive: true,
    }

    const handleFinish = (values) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const payload = {
            name: values.nameEn,
            nameAr: values.nameAr,
            address: values.address,
            isActive: values.isActive
        }

        if (mode === "add") {
            addStore(
                payload,
                (res) => {
                    setIsSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message)
                        onCancel();
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setIsSubmitting(false);
                    toastError(res.message);
                }
            )
        } else {
            payload.id = rowData.id;
            updateStore(
                payload,
                (res) => {
                    setIsSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message)
                        onCancel();
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setIsSubmitting(false);
                    toastError(res.message);
                }
            )
        }
    }

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                nameEn: rowData.name,
                nameAr: rowData.nameAr,
                address: rowData.address,
                isActive: rowData.isActive,
            })
        }
    }, [rowData, mode])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal text-center store-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content"
                initialValues={initialValues}
                onFinish={handleFinish}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Store name in English
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="nameEn"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the store name",
                                    },
                                    {
                                        max: 20,
                                        message: "Maximum length is 20 characters"
                                    },
                                    {
                                        pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]+$/,
                                        message: "Please, Enter correct store name",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter store name in English"
                                    maxLength={20}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Store name in Arabic
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="nameAr"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the store name",
                                    },
                                    {
                                        max: 20,
                                        message: "Maximum length is 20 characters"
                                    },
                                    {
                                        pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~`]+$/,
                                        message: "Please, Enter correct store name",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter store name in Arabic"
                                    maxLength={20}
                                    dir="rtl"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Store address
                            </label>
                            <Form.Item
                                className="new-category__form-item w-100"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the store address",
                                    },
                                    {
                                        max: 100,
                                        message: "Maximum length is 100 characters"
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    rows={4}
                                    maxLength={100}
                                    placeholder="Enter store address"
                                    className="search__searchField store-modal__textarea py-2"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name d-flex align-items-center gap-3 mb-5">
                            <label className="users__parent-info-label mb-0" htmlFor="">
                                Status
                            </label>
                            <Form.Item
                                className="mb-0"
                                name="isActive"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            type="submit"
                            loading={isSubmitting}
                            buttonName={mode === "add" ? "Add" : "Save Changes"}
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default StoreModal;