import { Form } from "antd";
import Dragger from "antd/es/upload/Dragger";
import ImgCrop from "antd-img-crop";
import { baseUrl } from "services";

// assets
import editIcon from "../../../assets/icons/upload--red.svg";

const CoverImg = ({
  mode,
  image,
  formRef,
  previewImage,
  onPreviewCoverChange,
}) => {
  const props = {
    name: "image",
    showUploadList: false,
    maxCount: 1,
    accept: ".png,.jpg,.jpeg,.bmp,.svg",
    onChange(info) {
      if (!info.file.type.startsWith("image") || info.file.type.endsWith("gif")) {
        onPreviewCoverChange("");
        formRef.setFields([
          {
            name: "image",
            errors: ["Unsupported image format. Please upload another image"],
            value: "",
          }
        ])
      } else {
        handlePreview(info.file);
      }
    }
  };

  const handleBeforeCrop = (file) => {
    if (!file.type.startsWith("image") || file.type.endsWith("gif")) {
      onPreviewCoverChange("");
      formRef.resetFields(["image"])
      return false;
    } else {
      return true
    }
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    formRef.setFieldValue("image", file.originFileObj);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    onPreviewCoverChange(file.url || file.preview);
  };

  return (
    <div>
      {previewImage ? (
        <div className="store-items__item-form__images__preview-holder position-relative">
          <img
            src={previewImage}
            alt=""
            className="store-items__item-form__images__preview-img d-block object-fit-cover"
          />
          <Form.Item
            name={"image"}
            className="position-absolute h-100 w-100 top-0 start-0 store-items__item-form__images__input"
          >
            <ImgCrop cropperProps={{ restrictPosition: false }} beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} rotationSlider aspectSlider minZoom={0.5}>
              <Dragger {...props} className="main-input--upload__edit-btn">
                <button
                  type="button"
                  className="d-block mx-auto bg-transparent border-0 outline-0 shadow-none p-0 fsize--5 fw-bold text--blue-3 border-bottom"
                >
                  <img src={editIcon} alt="" className="d-block" />
                </button>
              </Dragger>
            </ImgCrop>
          </Form.Item>
        </div>
      ) : mode === "edit" ? (
        <div className="store-items__item-form__images__preview-holder position-relative">
          <img
            src={baseUrl + image}
            alt=""
            className="store-items__item-form__images__preview-img d-block object-fit-cover"
          />
          <Form.Item
            name={"image"}
            className="position-absolute h-100 w-100 top-0 start-0 store-items__item-form__images__input"
          >
            <ImgCrop cropperProps={{ restrictPosition: false }} beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} rotationSlider aspectSlider minZoom={0.5}>
              <Dragger {...props} className="main-input--upload__edit-btn">
                <button
                  type="button"
                  className="d-block mx-auto bg-transparent border-0 outline-0 shadow-none p-0 fsize--5 fw-bold text--blue-3 border-bottom"
                >
                  <img src={editIcon} alt="" className="d-block" />
                </button>
              </Dragger>
            </ImgCrop>
          </Form.Item>
        </div>
      ) : (
        ""
      )}
      <div
        style={previewImage || mode === "edit" ?
          { width: 0, height: 0, overflow: "hidden", opacity: 0, display: "none" } : {}}
      >
        <Form.Item
          name={"image"}
          className="position-relative store-items__item-form__images__input"
          rules={[{ required: true, message: "Please, Upload the item image" }]}
        >
          <ImgCrop cropperProps={{ restrictPosition: false }} beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} rotationSlider aspectSlider minZoom={0.5}>
            <Dragger disabled={previewImage || mode === "edit"} {...props} className="main-input--upload">
              <span className="mb-0 fsize--6">Upload Cover</span>
            </Dragger>
          </ImgCrop>
        </Form.Item>
      </div>
    </div>
  )
};

export default CoverImg;
