import { Routes, Route, Navigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { useAuth } from "hooks/useAuth";

import SignIn from "modules/Authentications/SignIn";
import Users from "modules/Users/Users";
import Orders from "modules/Orders/Orders";
import Logout from "modules/Authentications/Logout";
import Admins from "modules/Admins/Admins";
import Items from "modules/Items/Items";
import PendingOrders from "modules/Orders/PendingOrders";
import PendingOffers from "modules/Orders/PendingOffers";
import Categories from "modules/Categories/Categories";
import Factories from "modules/Factories/Factories";
import FactoryAdmin from "modules/Admins/Factory/FactoryAdmin";
import FinancialOrders from "modules/Orders/FinancialOrders";
import Notfound from "modules/Notfound/Notfound";
import NotHaveAccess from "modules/NotHaveAccess/Notfound";
import SoldOrders from "modules/Orders/SoldOrders";
import UsersForSales from "modules/Users/UsersForSales";
import SupplierAdmin from "modules/Admins/Supplier/SupplierAdmin";
import SuppliersRequests from "modules/SuppliersRequests";
import Offers from "modules/Offers";
import LuckyHour from "modules/LuckyHour";
import SalesRequests from "modules/SalesRequests";
import Stores from "modules/Stores";
import Settings from "modules/Settings";
import StoreItems from "modules/StoreItems";
import StoreOrders from "modules/StoreOrders";
// import PurchaseRequests from "modules/PurchaseRequests";
import Auctions from "modules/Auctions";
import AuctionReport from "modules/AuctionReport";
import StoreCategories from "modules/StoreCategories";
import StoreSubcategories from "modules/StoreSubcategories";
import Buy from "modules/BuyAndSupply/Buy/BuyItems";
import Supply from "modules/BuyAndSupply/Supply/SupplyTypes";
import BuyPendingOrders from "modules/BuyAndSupply/Buy/BuyPendingOrders";
import SupplyPendingOrders from "modules/BuyAndSupply/Supply/SupplyPendingOrders";
import AcceptedBuyAndSupply from "modules/AcceptedBuyAndSupply";
import SalesPersonReport from "modules/SalesPersonReport";
import MerchantStore from "modules/MerchantStore";

function RoutesFile() {
  const { isAuthorized, adminRoles } = useAuth();
  const isAdmin = adminRoles?.includes(1);
  const isSales = adminRoles?.includes(4) || adminRoles?.includes(6);

  return (
    <Routes>
      <Route path={ROUTES.SIGNIN} element={<SignIn />} />
      <Route element={<ProtectedRoutes allowedRoles={[1]} />}>
        <Route path={ROUTES.ADMINS} element={<Admins />} />
        <Route path={ROUTES.FACTORIES} element={<Factories />} />
        <Route path={ROUTES.SUPPLIERS} element={<SuppliersRequests />} />
        <Route path={ROUTES.CATEGORIES} element={<Categories />} />
        <Route path={ROUTES.ITEMS} element={<Items />} />
        <Route path={ROUTES.OFFERS} element={<Offers />} />
        <Route path={ROUTES.LUCKY_HOURS} element={<LuckyHour />} />
        <Route path={ROUTES.SALES_REQUESTS} element={<SalesRequests />} />
        <Route path={ROUTES.STORES} element={<Stores />} />
        <Route path={ROUTES.SETTINGS} element={<Settings />} />
        <Route path={ROUTES.STORE_ORDERS} element={<StoreOrders />} />
        <Route path={ROUTES.AUCTIONS} element={<Auctions />} />
        <Route path={ROUTES.AUCTION_REPORT} element={<AuctionReport />} />
        <Route path={ROUTES.ACCEPTED_BUY_AND_SUPPLY_REPORT} element={<AcceptedBuyAndSupply />} />
        {/* <Route path={ROUTES.PURCHASEREQUESTS} element={<PurchaseRequests />} /> */}
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 8]} />}>
        <Route path={ROUTES.STORE_ITEMS} element={<StoreItems />} />
        <Route path={ROUTES.STORE_CATEGORIES} element={<StoreCategories />} />
        <Route
          path={ROUTES.STORE_SUBCATEGORIES}
          element={<StoreSubcategories />}
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 6]} />}>
        <Route path={ROUTES.SALES_PERSON_REPORT} element={<SalesPersonReport />} />
        <Route path={ROUTES.PENDINGORDERS} element={<PendingOrders />} />
        <Route path={ROUTES.PENDINGOFFERS} element={<PendingOffers />} />
        <Route path={ROUTES.BUY} element={<Buy />} />
        <Route path={ROUTES.BUYPENDINGORDERS} element={<BuyPendingOrders />} />
        <Route path={ROUTES.SUPPLY} element={<Supply />} />
        <Route path={ROUTES.SUPPLYPENDINGORDERS} element={<SupplyPendingOrders />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 2, 4, 6]} />}>
        <Route
          path={ROUTES.Orders}
          element={isAdmin ? <Orders /> : <FinancialOrders />}
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[3]} />}>
        <Route
          path={ROUTES.FACTORY_ADMIN_REQUESTS}
          element={<FactoryAdmin />}
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[5]} />}>
        <Route
          path={ROUTES.SUPPLIER_ADMIN_REQUESTS}
          element={<SupplierAdmin />}
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 4, 6]} />}>
        <Route path={ROUTES.SOLD_ORDERS} element={<SoldOrders />} />
        <Route path={ROUTES.USERS} element={isSales ? <UsersForSales /> : <Users />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 13]} />}>
        <Route path={ROUTES.MERCHANT_STORE} element={<MerchantStore />} />
      </Route>
      <Route path={ROUTES.NOT_HAVE_ACCESS} element={<NotHaveAccess />} />
      <Route
        path={ROUTES.NOT_FOUND}
        element={isAuthorized ? <Notfound /> : <Navigate to={ROUTES.SIGNIN} />}
      />
      <Route path={ROUTES.LOGOUT} element={<Logout />} />
    </Routes>
  );
}

export default RoutesFile;
