import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Routes from "modules/Routes/Routes";
import { updateSidebarCollapseState } from "store/sidebarSlice";

import Navbar from "modules/Navbar/Navbar";
import SideBar from "modules/SideBar/SideBar";

function General() {
  // const [showSidebar, setShowSidebar] = useState(true);
  const { collapsed } = useSelector((state) => state.sidebarState);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const notHaveNavSide = ["/"];
  const pagesHaveSearchInNavbar = [];

  const handleSidebarStateChange = (value) => {
    dispatch(updateSidebarCollapseState(value))
  }

  return (
    <section className={"general-section "}>
      {!notHaveNavSide.includes(pathname) && (
        <div
          className={`${notHaveNavSide.includes(pathname) || collapsed  ? "d-none" : "side-bar"}`}
        >
          <SideBar />
        </div>
      )}
      <div
        className={`${notHaveNavSide.includes(pathname) ? "wd-100" : "all-content flex-fill"}`}
      >
        {!notHaveNavSide.includes(pathname) && (
          <div className="nav-bar">
            <Navbar
              withSearch={
                pagesHaveSearchInNavbar.includes(pathname) ? true : false
              }
              showSidebar={collapsed}
              onSidebarChange={handleSidebarStateChange}
            />
          </div>
        )}
        <div className="component-wrapper">
          <div className="component">
            <Routes />
          </div>
        </div>
      </div>
    </section>
  );
}

export default General;
