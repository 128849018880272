import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { getSupplyTypesPendingOrders } from "Network";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";

const SupplyPendingOrders = () => {
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isApprove, setIsApprove] = useState(false);

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    supplyTypesPendingOrders();
  }, [renderMe]);

  const supplyTypesPendingOrders = () => {
    getSupplyTypesPendingOrders(
      (success) => {
        if (success.success) {
          setLoading(false);
          setDataSource(success.data);
        } else {
          setLoading(false);
          toastError(success.message);
        }
      },
      (fail) => {
        if (fail.data?.error) {
          setLoading(false);
          toastError(fail.message);
        } else {
          setLoading(false);
          toastError(fail.message);
        }
      }
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      key: "fullName",
      title: "USER NAME",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "userMobile",
      title: "MOBILE",
      dataIndex: "userMobile",
      ...getColumnSearchProps("userMobile"),
    },
    {
      key: "type",
      title: "TYPE",
      dataIndex: "type",
      ...getColumnSearchProps("type"),
    },
    {
      key: "quantity",
      title: "QUANTITY",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),
    },
    {
      key: "unitPrice",
      title: "PRICE PER KILO",
      dataIndex: "unitPrice",
      ...getColumnSearchProps("unitPrice"),
    },
    {
      key: "totalPrice",
      title: "TOTAL PRICE",
      dataIndex: "totalPrice",
      ...getColumnSearchProps("totalPrice"),
    },
    {
      key: "willReceiveAfterDays",
      title: "WILL RECEIVE AFTER DAYS",
      dataIndex: "willReceiveAfterDays",
      ...getColumnSearchProps("willReceiveAfterDays"),
    },
    {
      key: "fromBuyAndSupplyBalance",
      title: "USER BALANCE DEDUCTION",
      dataIndex: "fromBuyAndSupplyBalance",
      filters: [
        {
          text: "Deducted",
          value: true,
        },
        {
          text: "Not Deducted",
          value: false,
        },
      ],
      onFilter: (value, record) => value === record.fromBuyAndSupplyBalance,
      render: (_, record) => (
        <span>{`${
          record?.fromBuyAndSupplyBalance ? "Deducted" : "Not Deducted"
        }`}</span>
      ),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
    },
  ];

  const handleAcceptBtn = (e, row) => {
    setIsApprove(true);
    setRowData(row);
    setIsConfirmationModalVisible(true);
  };

  const handleRejectBtn = (e, row) => {
    setIsApprove(false);
    setRowData(row);
    setIsConfirmationModalVisible(true);
  };

  const onCancel = () => {
    setIsConfirmationModalVisible(false);
    setRenderMe((prev) => !prev);
  };
  const handleOk = () => {
    const data = {
      orderId: rowData?.id,
      isAccepted: isApprove ? true : false,
    };
    // acceptOrRejectBuyPendingOrders(
    //   data,
    //   (success) => {
    //     if (success?.success) {
    //       toastSuccess(success.message);
    //       setRenderMe((prev) => !prev);
    //     } else {
    //       toastError(success.message);
    //     }
    //   },
    //   (fail) => {
    //     toastError(fail.message);
    //   }
    // );
    setIsConfirmationModalVisible(false);
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Supply Types Orders" />
            <SubHeader
              bgColor="#356ab3"
              title="All Supply Types Orders"
            />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
        {isConfirmationModalVisible && (
          <Modal
            title={isApprove ? "Accept Buy Item" : "Reject Buy Item"}
            open={isConfirmationModalVisible}
            onOk={handleOk}
            onCancel={onCancel}
          >
            <span>{`Are you sure you want ${
              isApprove ? "accept" : "reject"
            } the buy item?`}</span>
          </Modal>
        )}
      </div>
    </section>
  );
};

export default SupplyPendingOrders;
