import { useState, useEffect, useRef } from "react";
import { Button, Modal, Space, Input, DatePicker } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { toastError, toastSuccess } from "helpers/toasters";
import Highlighter from "react-highlight-words";
import { deleteItemApi, getAllItemsApi } from "Network";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import ItemModal from "./ItemModal";
// import OfferModal from "./OfferModal";

function Items() {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isItemModalVisible, setIsItemModalVisible] = useState(false);
  const [isDeleteItemOpen, setIsDeleteItemOpen] = useState(false);
  // const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [searchValues, setSearchValues] = useState({
    description: "",
    itemStatus: "",
    price: "",
    isActive: "",
    quantityPerKilo: "",
    totalUnits: "",
    categoryName: "",
    unitValue: "",
    from: "",
    to: ""
  })
  const [selectedRange, setSelectedRange] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handleEditBtn = (e, row) => {
    setMode("edit");
    setRowData(row);
    setIsItemModalVisible(true);
  };

  const handlDeleteBtn = (e, row) => {
    setIsDeleteItemOpen(true);
    setRowData(row);
  };

  // const handleOpenOfferModal = (e, row) => {
  //   setIsOfferModalOpen(true);
  //   setRowData(row);
  // };

  const handleTableChange = (_, filters) => {
    if (filters.isActive) {
      if (filters.isActive.length > 1) {
        updateSearchValues("isActive", "")
      } else {
        updateSearchValues("isActive", filters.isActive[0])
      }
    } else if (searchValues.isActive) {
      updateSearchValues("isActive", "")
    }
  }

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues }
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }

  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate })
    } else {
      updateSearchValues("from", "", { property: "to", value: "" })
    }
  };

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setLoading(true)
    }
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setLoading(true)
    switch (dataIndex) {
      case "description":
        updateSearchValues("description", selectedKeys[0])
        break;
      case "itemStatus":
        updateSearchValues("itemStatus", selectedKeys[0])
        break;
      case "price":
        updateSearchValues("price", selectedKeys[0])
        break;
      case "quantityPerKilo":
        updateSearchValues("quantityPerKilo", selectedKeys[0])
        break;
      case "totalUnits":
        updateSearchValues("totalUnits", selectedKeys[0])
        break;
      case "categoryName":
        updateSearchValues("categoryName", selectedKeys[0])
        break;
      case "unitValue":
        updateSearchValues("unitValue", selectedKeys[0])
        break;
      default:
        break;
    }
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    setLoading(true);
    switch (dataIndex) {
      case "description":
        updateSearchValues("description", "")
        break;
      case "itemStatus":
        updateSearchValues("itemStatus", "")
        break;
      case "price":
        updateSearchValues("price", "")
        break;
      case "quantityPerKilo":
        updateSearchValues("quantityPerKilo", "")
        break;
      case "totalUnits":
        updateSearchValues("totalUnits", "")
        break;
      case "categoryName":
        updateSearchValues("categoryName", "")
        break;
      case "unitValue":
        updateSearchValues("unitValue", "")
        break;
      default:
        break;
    }
    clearFilters();
    confirm()
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1)
            handleDateChange(dates, datesStr)
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      key: "description",
      title: "DESCRIPTION",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      render: (_, row) => (
        <span>{`${row?.description?.length > 50
          ? row.description.slice(0, 50) + "..."
          : row.description
          }`}</span>
      ),
    },
    {
      key: "isActive",
      title: "IS ACTIVE",
      dataIndex: "isActive",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Deactive',
          value: false,
        }
      ],
      render: (_, row) => (
        <span>{row.isActive ? "Active" : "Deactive"}</span>
      ),
    },
    {
      key: "price",
      title: "PRICE/EGP",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
      render: (_, row) => {
        let units = row.itemUnits;
        return <span>{`${units?.length ? units[0]?.price : ""} `}</span>;
      },
    },
    {
      key: "categoryName",
      title: "TYPE",
      dataIndex: "categoryName",
      ...getColumnSearchProps("categoryName"),
      render: (_, row) => {
        return (
          <span className="d-inline-block text-truncate text-center">
            {row.categoryName ? row.categoryName : "none"}
          </span>
        );
      },
    },
    {
      key: "quantityPerKilo",
      title: "QUANTITY/KILO",
      dataIndex: "quantityPerKilo",
      ...getColumnSearchProps("quantityPerKilo"),
    },
    {
      key: "totalUnits",
      title: "TOTAL UNITS",
      dataIndex: "totalUnits",
      ...getColumnSearchProps("totalUnits"),
      render: (_, row) => {
        return (
          <span>
            {row.totalUnits.toFixed(3)}
          </span>
        );
      },
    },
    {
      key: "unitValue",
      title: "UNIT VALUE",
      dataIndex: "unitValue",
      ...getColumnSearchProps("unitValue"),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
    // {
    //   key: "id",
    //   title: "OFFER",
    //   dataIndex: "Offer",
    //   render: (_, row) => {
    //     return (
    //       <Button type="primary" onClick={(e) => handleOpenOfferModal(e, row)}>
    //         Send offer to users
    //       </Button>
    //     );
    //   },
    // },
    {
      key: "itemId",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              onClick={(e) => handleEditBtn(e, row)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Edit
            </Button>
            <Button type="danger" onClick={(e) => handlDeleteBtn(e, row)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleAddItems = () => {
    setIsItemModalVisible(true);
    setMode("add");
  };

  const onCancel = () => {
    setIsItemModalVisible(false);
    setRenderMe((prev) => !prev);
    setMode("add");
  };

  const handleCancel = () => {
    setIsDeleteItemOpen(false);
    // setIsOfferModalOpen(false);
  };

  const handleOk = () => {
    const data = { itemId: rowData?.itemId };
    deleteItemApi(
      data,
      (res) => {
        if (res.success) {
          toastSuccess(res.message);
          setRenderMe((prev) => !prev);
          handleCancel();
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
        delete payload[property]
      }
    }
    getAllItemsApi(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe, currentPage, pageSize, searchValues]);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Items"
              plusButtonName={"Add Item"}
              plusButtonAction={handleAddItems}
            />
            <SubHeader bgColor="#356ab3" title="All items" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"itemId"}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
        {isItemModalVisible && (
          <ItemModal
            isItemModalVisible={isItemModalVisible}
            mode={mode}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${mode === "add" ? "Add New Item" : "Edit Item"}`}
                closeButton
                onCancel={onCancel}
              />
            }
            onCancel={onCancel}
            primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isDeleteItemOpen && (
          <Modal
            title="Delete Item"
            open={isDeleteItemOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <span>{"Are you sure you want to delete this item?"}</span>
          </Modal>
        )}
        {/* {isOfferModalOpen && (
          <OfferModal
            isOrderModalVisible={isOfferModalOpen}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={"Notify users for new buyer"}
              />
            }
            onCancel={handleCancel}
            primaryBtnName={`Send Offer`}
            secondaryBtnName={"Cancel"}
          />
        )} */}
      </div>
    </section>
  );
}

export default Items;
