import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: "sidebarState",
    initialState: {
        collapsed: false
    },
    reducers: {
        updateSidebarCollapseState: (state, action) => {
            state.collapsed = action.payload;
        },
    },
});

const { reducer, actions } = sidebarSlice;
export const { updateSidebarCollapseState } = actions;
export default reducer;
