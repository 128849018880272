import { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { buyAndSupplyAddBuyItem, buyAndSupplyEditBuyItem, getCategoriesApi, } from "Network";
import { handleAllowNumbersOnly } from "helpers/helpfulFunctions";
import { arabicNameValidation } from "constants/RegexValidations";
import { toastError, toastSuccess } from "helpers/toasters";
import { baseUrl } from "services";

// components
import PrimaryButton from "../../../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../../../SubComponents/Buttons/SecondaryButton";

function BuyModal({
  isItemModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [unitValue, setUnitValue] = useState(0);
  const [invalidImage, setInvalidImage] = useState(false)
  const [previewImage, setPreviewImage] = useState("");
  const [itemImage, setItemImage] = useState(null);
  const [form] = Form.useForm();
  const props = {
    name: 'image',
    accept: ".png,.jpg,.jpeg,.bmp,.gif,.svg",
    maxCount: 1,
    showUploadList: false,
    onChange(info) {
      if (!info.file.type.startsWith("image")) {
        handleRemoveImage();
        form.setFields([
          {
            name: "image",
            errors: ["Please, upload a valid image for the item"],
            value: "",
          }
        ])
        return;
      }
      handlePreview(info.file)
    },
  };

  const handleBeforeCrop = (file) => {
    if (!file.type.startsWith("image") || file.type.endsWith("gif")) {
      handleRemoveImage();
      return false;
    } else {
      return true
    }
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    setItemImage(file.originFileObj);
    form.setFields([{ name: "image", value: file.originFileObj, errors: [] }])
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
  };

  const handleRemoveImage = () => {
    setPreviewImage("");
    setItemImage("");
    setInvalidImage(true)
  };

  const handleTotalWeightValidation = (_, value) => {
    const inputValue = parseFloat(value);
    if (inputValue < 0) {
      return Promise.reject(new Error("Minimum value should be greater than 0"));
    } else if (inputValue > 1000000) {
      return Promise.reject(new Error("Maximum value is million"));
    } else if (inputValue && isNaN(inputValue)) {
      return Promise.reject(new Error(`Please, Enter a valid weight`));
    } else {
      return Promise.resolve();
    }
  };

  const handlePriceValidation = (_, value) => {
    const inputValue = parseFloat(value);
    if (
      inputValue === 0 ||
      (inputValue && (isNaN(inputValue) || inputValue < 1))
    ) {
      return Promise.reject(new Error(`Minimum value should be greater than 0`));
    } else if (inputValue > 1000000) {
      return Promise.reject(new Error(`Maximum value is million`));
    } else {
      return Promise.resolve();
    }
  };

  const handleQuantityValidation = (_, value) => {
    const inputValue = parseFloat(value);
    if (inputValue < 1) {
      return Promise.reject(new Error("Minimum value is 1"));
    } else if (inputValue > 1000000) {
      return Promise.reject(new Error("Maximum value is million"));
    } else if (inputValue && isNaN(inputValue)) {
      return Promise.reject(new Error(`Please, Enter a valid quantity`));
    } else {
      return Promise.resolve();
    }
  };

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const handleQuantityPerKilo = (quantity, categoryUnit) => {
    if (!categoryUnit || !quantity) return
    const value = quantity * categoryUnit;
    form.setFieldValue("totalWeight", value.toFixed(3));
    form.validateFields(["totalWeight"])
  };

  const handleTotalWeightChange = (total, categoryUnit) => {
    if (!categoryUnit || !total) return
    const quantity = total / categoryUnit;
    form.setFieldValue("quantity", quantity.toFixed(3));
    form.validateFields(["quantity"])
  };

  const addBuyItem = (payload) => {
    buyAndSupplyAddBuyItem(
      payload,
      (res) => {
        setIsModalLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          handleOnCancel();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsModalLoading(false);
        toastError(res?.data?.error);
      }
    );
  };

  const editBuyItem = (payload) => {
    buyAndSupplyEditBuyItem(
      payload,
      (res) => {
        setIsModalLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          handleOnCancel();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsModalLoading(false);
        toastError(res?.data?.error);
      }
    );
  };

  const handleOnFinish = (values) => {
    if (isModalLoading) return;
    setIsModalLoading(true);

    const quantityToSend = unitValue !== 1 ? values.totalWeight : values.quantity;
    const formData = new FormData();
    const data = {
      isActive: values.isActive,
      description: values.description,
      descriptionAr: values.descriptionAr,
      categoryId: values.category,
      quantityPerKilo: quantityToSend,
      pricePerKilo: values.pricePerKilo,
      image: itemImage,
    };
    if (mode === "edit") {
      data.id = rowData?.itemId;
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    if (mode === "add") {
      addBuyItem(formData);
    } else {
      editBuyItem(formData);
    }
  };

  useEffect(() => {
    if (rowData && mode === "edit") {
      form.setFieldsValue({
        description: rowData.description,
        descriptionAr: rowData.descriptionAr,
        image: rowData.image,
        pricePerKilo: rowData.pricePerKilo,
        quantity: rowData.totalUnits,
        totalWeight: rowData.quantityPerKilo,
        isActive: rowData.isActive,
      });
      setUnitValue(rowData.unitValue);
    }
  }, [rowData, mode, form]);

  useEffect(() => {
    getCategoriesApi(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.name, unitValue: ele.unitValue };
          });
          setCategories(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res?.data?.error);
      }
    );
  }, []);

  useEffect(() => {
    if (categories && rowData && mode === "edit") {
        form.setFieldValue("category", rowData.categoryId)
    }
  }, [categories, rowData, mode])

  return (
    isItemModalVisible && (
      <Modal
        title={SubHeader}
        open={isItemModalVisible}
        className="msg-modal"
        footer={false}
        closable={false}
        centered
        destroyOnClose
      >
        <Form
          form={form}
          className="edit-actor__content"
          onFinish={handleOnFinish}
          initialValues={{
            description: "",
            descriptionAr: "",
            category: undefined,
            image: "",
            pricePerKilo: "",
            quantity: "",
            isActive: true,
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Type
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please, select a type",
                    },
                  ]}
                >
                  <Select
                    disabled={mode === "edit"}
                    placeholder="Select a type"
                    loading={categories ? false : true}
                    options={categories ? categories : []}
                    onChange={(value) => {
                      const categoryUnit = categories.find((ele) => ele.value === value).unitValue;
                      setUnitValue(categoryUnit);
                      handleQuantityPerKilo(form.getFieldValue("quantity"), categoryUnit)
                    }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Name in English
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="description"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please, Enter the item name in English",
                    },
                    {
                      pattern: /^[a-zA-Z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*$/i,
                      message: "Please, enter the item name in English",
                    },
                    {
                      min: 3,
                      message: "Name should be at least 3 characters",
                    },
                    {
                      pattern: /^[a-zA-Z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]$/i,
                      message: "Please, enter the item name in English",
                    },
                    {
                      max: 50,
                      message: "Name should not exceed 50 characters.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="search__searchField py-1"
                    placeholder="Enter name in English"
                    maxLength={50}
                    showCount
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Name in Arabic
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="descriptionAr"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please, Enter the item name in Arabic",
                    },
                    {
                      pattern: /^[\u0600-\u06FF][\u0600-\u06FF\s\d$!@#%^&?*()_+-=\[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]*$/,
                      message: "Please, enter the item name in Arabic",
                    },
                    {
                      min: 3,
                      message: "Name should be at least 3 characters",
                    },
                    {
                      pattern: arabicNameValidation,
                      message: "Please, enter the item name in Arabic",
                    },
                    {
                      max: 50,
                      message: "Name should not exceed 50 characters.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="search__searchField py-1"
                    placeholder="Enter name in Arabic"
                    maxLength={50}
                    showCount
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Quantity
                  </label>
                </div>
                <Form.Item
                  className="new-category__form-item"
                  name="quantity"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: "Please, enter a quantity",
                    },
                    { validator: handleQuantityValidation },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-100 d-block search__searchField py-1"
                    placeholder="Enter quantity"
                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                    controls={false}
                    keyboard={false}
                    onChange={(value) => {
                      if (value !== undefined && value !== null) {
                        handleQuantityPerKilo(value, unitValue);
                      } else {
                        form.resetFields(["totalWeight"])
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex ">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Price per Kilo
                  </label>
                </div>
                <Form.Item
                  className="new-category__form-item"
                  name="pricePerKilo"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: "Please, Enter the price per kilo",
                    },
                    { validator: handlePriceValidation },
                  ]}
                >
                  <InputNumber
                    className="w-100 d-block search__searchField py-1"
                    placeholder="Enter Price per kilo"
                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                    controls={false}
                    keyboard={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="new-actor__name mb-4 row mx-0 align-items-center">
                <div className="col-4 px-0 text-start">
                  <label className="users__parent-info-label">Active</label>
                </div>
                <div className="col-8 px-0 d-flex">
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    className="mb-0"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </div>
            </Col>
            {unitValue && unitValue !== 1 ? (
              <Col span={24}>
                <div className="new-actor__name new-category mb-5">
                  <div className="lang-name wd-100 flex">
                    <label className="users__parent-info-label mb-2" htmlFor="">
                      Total Weight
                    </label>
                  </div>
                  <Form.Item
                    className="new-category__form-item"
                    name="totalWeight"
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: "Please, enter weight",
                      },
                      { validator: handleTotalWeightValidation }
                    ]}
                  >
                    <InputNumber
                      className="w-100 d-block search__searchField py-1"
                      placeholder="Enter Total weight"
                      onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                      controls={false}
                      onChange={(value) => {
                        if (value !== null && value !== undefined) {
                          handleTotalWeightChange(value, unitValue);
                        } else {
                          form.resetFields(["quantity"])
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
            ) : (
              ""
            )}
            <Col span={24}>
              <div className="new-actor__name">
                <div className="lang-name wd-100 flex">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Image
                  </label>
                </div>
                {previewImage ? (
                  <img src={previewImage} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                ) : mode === "edit" && !invalidImage ? (
                  <img src={baseUrl + rowData?.image} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                ) : (
                  ""
                )}
                <Form.Item
                  name="image"
                  rules={[
                    { required: true, message: "Please, upload an image for the item" }
                  ]}
                  className="w-100"
                >
                  <ImgCrop beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} cropperProps={{ restrictPosition: false }} minZoom={0.5} rotationSlider aspectSlider>
                    <Upload {...props}>
                      Upload Image +
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default BuyModal;