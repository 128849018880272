import { useEffect, useState } from "react";
import { Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import {
    addStoreItem,
    editStoreItem,
    getActiveStoreCategories,
    getActiveStoreSubcategories,
    uploadStoreItemImage
} from "Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import UploadImages from "./UploadImages";

const AddEditStoreItem = ({
    isItemModalVisible,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onSubmit,
    onCancel
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [previewCover, setPreviewCover] = useState("");
    const [previewImages, setPreviewImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [imagesPaths, setImagesPaths] = useState([])
    const [activeStoreCategories, setActiveStoreCategories] = useState(null);
    const [activeStoreSubcategories, setActiveStoreSubcategories] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [firstRender, setFirestRender] = useState(true);
    const [itemHasOffer, setItemHasOffer] = useState(false);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const initialValues = {
        nameEn: "",
        nameAr: "",
        image: "",
        images: "",
        price: "",
        quantity: "",
        descriptionEn: "",
        descriptionAr: "",
        shortDescriptionEn: "",
        shortDescriptionAr: "",
        url: "",
        category: undefined,
        subcategory: undefined,
        storeAvailability: 1,
        isActive: true,
        hasOffer: false,
        offerPercentage: ""
    }

    const handleRemoveImagePath = (path) => {
        const newPaths = [...imagesPaths].filter((imagePath) => imagePath !== path);
        setImagesPaths(newPaths);
    };

    const handleUploadedImagesChange = (value) => {
        setUploadedImages(value);
    };

    const handlePreviewCoverChange = (value) => {
        setPreviewCover(value);
    };

    const handlePreviewFilesChange = (value) => {
        setPreviewImages(value);
    };

    const handlePriceValidation = (_, value) => {
        const inputValue = parseFloat(value);
        if (inputValue <= 0) {
            return Promise.reject(new Error(`Minimum value is 0 EGP`));
        } else if (inputValue > 1000000) {
            return Promise.reject(new Error(`Maximum value is 1,000,000 EGP`));
        } else {
            return Promise.resolve()
        }
    }

    const handleQuantityValidation = (_, value) => {
        const inputValue = parseFloat(value);
        if ((mode === "add" && inputValue === 0) || (inputValue && (isNaN(inputValue) || inputValue < 1))) {
            return Promise.reject(new Error(`Minimum value is 1`));
        } else if (inputValue > 10000) {
            return Promise.reject(new Error(`Maximum value is 10,000`));
        } else {
            return Promise.resolve()
        }
    }

    const handleOfferPercentageValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Percentage is required, please enter the percentage of the offer.`));
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else if (value > 100) {
            return Promise.reject(new Error(`Maximum value should be less than or equal to 100`));
        } else {
            return Promise.resolve()
        }
    }

    const handlePreventLetters = (e, allowFloat = true) => {
        if (e.key === "Backspace" ||
            (allowFloat && e.key === ".") ||
            (e.key.startsWith("Arrow") && (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight")))
        ) {
            return;
        }
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const addItem = (payload) => {
        addStoreItem(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    }

    const editItem = (payload) => {
        editStoreItem(
            payload,
            (success) => {
                setSubmitting(false);
                if (success.success) {
                    toastSuccess(success.message);
                    onSubmit();
                } else {
                    toastError(success.message);
                }
            },
            (fail) => {
                setSubmitting(false);
                toastError(fail.message);
            }
        );
    }

    const getImagePath = (payload, itemData, type, nextItems) => {
        uploadStoreItemImage(
            payload,
            (res) => {
                if (res.success) {
                    if (type === "cover") {
                        itemData.image = res.data
                    } else {
                        itemData.images = [...itemData.images, res.data]
                    }
                    if (nextItems.length > 0) {
                        const formData = new FormData();
                        formData.set("image", nextItems[0])
                        const updatedNextItems = nextItems.length > 1 ? [nextItems[1]] : []
                        getImagePath(formData, itemData, "other", updatedNextItems)
                    } else {
                        if (itemData.images.length === 0) {
                            delete itemData.images
                        }
                        if (mode === "add") {
                            addItem(itemData)
                        } else {
                            editItem(itemData)
                        }
                    }
                } else {
                    setSubmitting(false);
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res?.data?.error);
            }
        );
    }

    const uploadItemImages = async (mainImage, otherImages, itemData) => {
        const formData = new FormData();
        if (mainImage) {
            formData.set("image", mainImage)
            const nextItems = otherImages.length > 0 ? otherImages : []
            getImagePath(formData, itemData, "cover", nextItems)
        } else {
            formData.set("image", otherImages[0])
            const updatedNextItems = otherImages.length > 1 ? [otherImages[1]] : []
            getImagePath(formData, itemData, "other", updatedNextItems)
        }
    };

    const handleOnFinish = (values) => {
        const imagesError = form.getFieldError("images")
        if (submitting || imagesError.length > 0) return;
        setSubmitting(true);

        const payload = {
            Name: values.nameEn,
            NameAr: values.nameAr,
            Description: values.descriptionEn,
            DescriptionAr: values.descriptionAr,
            shortDescription: values.shortDescriptionEn,
            shortDescriptionAr: values.shortDescriptionAr,
            link: values.url,
            Quantity: values.quantity,
            CategoryId: selectedCategory,
            Price: parseFloat(values.price.toFixed(2)),
            IsActive: values.isActive,
            images: mode === "add" ? [] : imagesPaths,
            image: mode === "add" ? "" : rowData.image,
            forGreenMatter: values.storeAvailability === 1 || values.storeAvailability === 3,
            forscrapper: values.storeAvailability === 2 || values.storeAvailability === 3,
        }
        if (values.subcategory) {
            payload.sUbCategoryId = selectedSubcategory
        }
        if (itemHasOffer) {
            payload.DiscountPercentage = values.offerPercentage
        }
        if (mode === "add") {
            uploadItemImages(values.image, uploadedImages, payload)
        } else {
            payload.Id = rowData.itemId;
            if (previewCover || uploadedImages.length > 0) {
                uploadItemImages(previewCover ? values.image : "", uploadedImages, payload)
            } else {
                if (payload.images.length === 0) {
                    delete payload.images
                }
                editItem(payload)
            }
        }
    };

    useEffect(() => {
        if (rowData && mode === "edit") {
            let storeAvailability;
            if (rowData.forGreenMatter) {
                if (rowData.forscrapper) {
                    storeAvailability = 3
                } else {
                    storeAvailability = 1
                }
            } else if (rowData.forscrapper) {
                storeAvailability = 2
            }
            if (rowData.discountPercentage) {
                setItemHasOffer(true)
            }
            setImagesPaths(rowData.images)
            form.setFieldsValue({
                nameEn: rowData.name,
                nameAr: rowData.nameAr,
                image: rowData.image,
                price: rowData.price,
                quantity: rowData.quantity,
                descriptionEn: rowData.description,
                descriptionAr: rowData.descriptionAr,
                shortDescriptionEn: rowData.shortDescription,
                shortDescriptionAr: rowData.shortDescriptionAr,
                url: rowData.link,
                isActive: rowData.isActive,
                hasOffer: rowData.discountPercentage > 0,
                offerPercentage: rowData.discountPercentage > 0 ? rowData.discountPercentage : "",
                storeAvailability,
            });
            setSelectedCategory(rowData.categoryId)
            setSelectedSubcategory(rowData.subCategoryId)
        }
    }, [rowData, mode, form]);

    useEffect(() => {
        getActiveStoreCategories(
            (res) => {
                if (res.success) {
                    const dataArr = res.data.map((category) => {
                        return { value: category.id, label: category.name };
                    })
                    setActiveStoreCategories(dataArr);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [])

    useEffect(() => {
        if (!selectedCategory) return;
        getActiveStoreSubcategories(
            selectedCategory,
            (res) => {
                if (res.success) {
                    const dataArr = res.data.map((category) => {
                        return { value: category.id, label: category.name };
                    })
                    setActiveStoreSubcategories(dataArr);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [selectedCategory])

    useEffect(() => {
        if (!rowData) return;
        if (activeStoreCategories && firstRender) {
            const categoryExists = activeStoreCategories.find((category) => category.value === rowData.categoryId)
            if (!categoryExists) {
                form.setFieldValue("category", rowData.categoryName)
            } else {
                form.setFieldValue("category", rowData.categoryId)
            }
        }
        if (activeStoreSubcategories && firstRender) {
            const subcategoryExists = activeStoreSubcategories.find((subcategory) => subcategory.value === rowData.subCategoryId)
            if (!subcategoryExists) {
                form.setFieldValue("subcategory", rowData.subCategoryName)
            } else {
                form.setFieldValue("subcategory", rowData.subCategoryId)
            }
            if (mode !== "edit" || (mode === "edit" && activeStoreCategories)) {
                setFirestRender(false)
            }
        }
    }, [rowData, activeStoreCategories, activeStoreSubcategories])

    return (
        isItemModalVisible && (
            <Modal
                title={SubHeader}
                open={isItemModalVisible}
                className="msg-modal"
                footer={false}
                closable={false}
                centered
                destroyOnClose
            >
                <Form
                    form={form}
                    className="edit-actor__content store-items__item-form"
                    onFinish={handleOnFinish}
                    initialValues={initialValues}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Item Image
                                    </label>
                                </div>
                                <UploadImages
                                    mode={mode}
                                    formRef={form}
                                    data={rowData}
                                    onRemoveImagePath={handleRemoveImagePath}
                                    imagesPaths={imagesPaths}
                                    previewCover={previewCover}
                                    onPreviewCoverChange={handlePreviewCoverChange}
                                    previewFiles={previewImages}
                                    onPreviewFilesChange={handlePreviewFilesChange}
                                    uploadedImages={uploadedImages}
                                    onUploadedImagesChange={handleUploadedImagesChange}
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Name in English
                                </label>
                                <Form.Item
                                    className=" new-category__form-item"
                                    name="nameEn"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item name in English",
                                        },
                                        {
                                            max: 25,
                                            message: "Maximum length is 25 characters"
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                        {
                                            pattern: /^[a-zA-Z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]$/,
                                            message: "Please, Enter a valid item name in English",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter Name in English"
                                        maxLength={25}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Name in Arabic
                                </label>
                                <Form.Item
                                    className=" new-category__form-item"
                                    name="nameAr"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item name in Arabic",
                                        },
                                        {
                                            max: 25,
                                            message: "Maximum length is 25 characters"
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                        {
                                            pattern: /^[\u0600-\u06FF][\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]*[\u0600-\u06FF\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]$/,
                                            message: "Please, Enter a valid item name in Arabic",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        dir="rtl"
                                        className="search__searchField py-2"
                                        placeholder="Enter Name in Arabic"
                                        maxLength={25}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Price
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="price"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item price",
                                        },
                                        { validator: handlePriceValidation }
                                    ]}
                                >
                                    <InputNumber
                                        className="w-100 d-block search__searchField py-1"
                                        placeholder="Enter Price"
                                        onKeyDown={handlePreventLetters}
                                        controls={false}
                                        keyboard={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Available Quantity
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="quantity"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the available item quantity",
                                        },
                                        { validator: handleQuantityValidation }
                                    ]}
                                >
                                    <InputNumber
                                        className="w-100 d-block search__searchField py-1"
                                        placeholder="Enter available quantity"
                                        onKeyDown={(e) => handlePreventLetters(e, false)}
                                        controls={false}
                                        keyboard={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Description in English
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="descriptionEn"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item description in English",
                                        },
                                        {
                                            pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*$/,
                                            message: "Please, Enter a valid item description in English",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        maxLength={60}
                                        showCount
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter description in English"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Description in Arabic
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="descriptionAr"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item description in Arabic",
                                        },
                                        {
                                            pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./؟’~‘÷×>`]*$/,
                                            message: "Please, Enter a valid item description in Arabic",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        maxLength={60}
                                        showCount
                                        dir="rtl"
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter description in Arabic"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Short Description in English
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="shortDescriptionEn"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item short description in English",
                                        },
                                        {
                                            pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*$/,
                                            message: "Please, Enter a valid item short description in English",
                                        },
                                        { min: 3, message: "Minimum length is 3 characters" }
                                    ]}
                                >
                                    <TextArea
                                        maxLength={30}
                                        showCount
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter short description in English"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Short Description in Arabic
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="shortDescriptionAr"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Enter the item short description in Arabic",
                                        },
                                        {
                                            pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./؟’~‘÷×>`]*$/,
                                            message: "Please, Enter a valid item short description in Arabic",
                                        },
                                        { min: 3, message: "Minimum length is 3 characters" }
                                    ]}
                                >
                                    <TextArea
                                        maxLength={30}
                                        showCount
                                        dir="rtl"
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter short description in Arabic"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    URL
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="url"
                                    rules={[
                                        // {
                                        //     required: true,
                                        //     message: "Please, Enter the item url",
                                        // },
                                        { type: "url", message: "Please, Enter a valid item url" }
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter url"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Category
                                </label>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Select the Category",
                                        },
                                    ]}
                                    name="category"
                                    className="new-category__form-item"
                                >
                                    <Select
                                        name="category"
                                        className="new-category__form-item"
                                        placeholder="Select Category"
                                        onChange={(value) => {
                                            setSelectedCategory(value)
                                            form.resetFields(["subcategory"])
                                        }}
                                        loading={activeStoreCategories ? false : true}
                                        options={activeStoreCategories ? activeStoreCategories : []}
                                        getPopupContainer={trigger => trigger.parentElement}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Subcategory
                                </label>
                                <Form.Item
                                    rules={activeStoreSubcategories?.length > 0 ? (
                                        [{
                                            required: true,
                                            message: "Please, Select subcategory",
                                        }]
                                    ) : (
                                        []
                                    )}
                                    name="subcategory"
                                    className="new-category__form-item"
                                >
                                    <Select
                                        name="subcategory"
                                        className="new-category__form-item"
                                        placeholder="Select Subcategory"
                                        onChange={(value) => setSelectedSubcategory(value)}
                                        loading={activeStoreSubcategories ? false : true}
                                        options={activeStoreSubcategories ? activeStoreSubcategories : []}
                                        getPopupContainer={trigger => trigger.parentElement}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Store Availability
                                </label>
                                <Form.Item
                                    name="storeAvailability"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Select a store",
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            <Radio value={1}>Green Matter Store</Radio>
                                            <Radio value={2}>Big Scrappers Store</Radio>
                                            <Radio value={3}>Both Stores</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name mb-4">
                                <Form.Item
                                    name="hasOffer"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Checkbox
                                        onChange={(e) => setItemHasOffer(e.target.checked)}
                                    >
                                        Has Offer
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Col>
                        {itemHasOffer && (
                            <Col span={24}>
                                <div className="new-actor__name new-category mb-5">
                                    <div className="lang-name wd-100 flex">
                                        <label className="users__parent-info-label mb-2" htmlFor="">
                                            Percentage
                                        </label>
                                    </div>
                                    <Form.Item
                                        className="new-category__form-item"
                                        name="offerPercentage"
                                        rules={[{ validator: handleOfferPercentageValidation }]}
                                    >
                                        <InputNumber
                                            className="w-100 d-block search__searchField py-1"
                                            placeholder="Enter percentage"
                                            onKeyDown={handlePreventLetters}
                                            controls={false}
                                            keyboard={false}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        )}
                        <Col span={24}>
                            <div className="new-actor__name mb-4 row mx-0 align-items-center">
                                <div className="col-4 px-0 text-start">
                                    <label className="users__parent-info-label">Active</label>
                                </div>
                                <div className="col-8 px-0 d-flex">
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mrg-ys-20 mrg-x-auto">
                        <Space size={20}>
                            <SecondaryButton
                                buttonName={secondaryBtnName}
                                onClick={onCancel}
                            />
                            <PrimaryButton
                                buttonName={primaryBtnName}
                                loading={submitting}
                                type="submit"
                            />
                        </Space>
                    </Row>
                </Form>
            </Modal >
        ));
}

export default AddEditStoreItem;