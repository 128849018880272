import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentPath } from "store/dynamicData";
import { baseUrl } from "services";
import ROUTES from "constants/NewRoutes";

import pendingOrdersIcon from "assets/icons/sidebar/Pending orders.png";
import pendingOffersIcon from "assets/icons/sidebar/pending offers.png";
import itemsIcon from "assets/icons/sidebar/Items.png";
import usersIcon from "assets/icons/sidebar/users.svg";
import adminsIcon from "assets/icons/sidebar/adminIcon.svg";
import soldOrdersIcon from "assets/icons/sidebar/sold-orders.png";
import allOrdersIcon from "assets/icons/sidebar/all-orders.png";
import categoryIcon from "assets/icons/sidebar/category.svg";
import offersIcon from "assets/icons/sidebar/Offers.png";
import suppliersIcon from "assets/icons/sidebar/suppliers.svg";
import factoriesIcon from "assets/icons/sidebar/factory.svg";
import logoutIcon from "assets/icons/sidebar/logout.svg";
import luckyHourIcon from "assets/icons/sidebar/lucky-hour.png";
// import SalesRequestsIcon from "assets/icons/sidebar/sales-request.png";
import storesIcon from "assets/icons/sidebar/stores.png";
import settingsIcon from "assets/icons/sidebar/settings.png";
import storeItemsIcon from "assets/icons/sidebar/store-items.png";
import storeCategoriesIcon from "assets/icons/sidebar/store-categories.png";
import storeSubcategoriesIcon from "assets/icons/sidebar/store-subcategory.png";
import storeOrdersIcon from "assets/icons/sidebar/store-orders.png";
// import requestsIcon from "assets/icons/sidebar/User request.png";
import auctionIcon from "assets/icons/sidebar/auction.png";
import auctionReportIcon from "assets/icons/sidebar/auctionReportIcon.svg";
import buyIcon from "assets/icons/sidebar/buy1.svg";
import buyPendingOrdersIcon from "assets/icons/sidebar/pandingBuy 1.svg";
import supplyIcon from "assets/icons/sidebar/sup1.svg";
import supplyPendingOrdersIcon from "assets/icons/sidebar/pandingSupply.svg";
import acceptedBuyAndSupplyReportIcon from "assets/icons/sidebar/accepted-buy-and-supply-report.svg";
import salesPersonReportIcon from "assets/icons/sidebar/sales-person-report.svg";

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { authorization } = useSelector((state) => state?.auth);
  const { currentPath } = useSelector((state) => state?.dynamicData?.dynamicDataOnSystem);
  const adminRoles = authorization.roles;

  const categoriesData = [
    {
      icon: adminsIcon,
      name: "Admins",
      link: "admins",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: usersIcon,
      name: "Users",
      link: "users",
      roles: [1, 4, 6],
      environment: [1, 2],
    },
    {
      icon: categoryIcon,
      name: "Types",
      link: "categories",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: itemsIcon,
      name: "Items",
      link: "items",
      roles: [1],
      environment: [1, 2],
    },
    // { icon: requestsIcon, name: "Purchase Requests", link: "purchase-requests", roles: [1] },
    {
      icon: luckyHourIcon,
      name: "Discounted Prices",
      link: "lucky-hours",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: pendingOrdersIcon,
      name: "Pending Orders",
      link: "pending-orders",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: allOrdersIcon,
      name: "All Orders",
      link: "orders",
      roles: [1, 2, 4, 6],
      environment: [1, 2],
    },
    {
      icon: soldOrdersIcon,
      name: "Sold Orders",
      link: "sold-orders",
      roles: [1, 4, 6],
      environment: [1, 2],
    },
    {
      icon: pendingOffersIcon,
      name: "Pending Offers",
      link: "pending-offers",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: offersIcon,
      name: "Offers",
      link: "offers",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: storeOrdersIcon,
      name: "Store Orders",
      link: "store-orders",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: buyIcon,
      name: "Buy Items",
      link: "buy-items",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: buyPendingOrdersIcon,
      name: "Buy Pending Orders",
      link: "buy-pending-orders",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: supplyIcon,
      name: "Supply Types",
      link: "supply-types",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: supplyPendingOrdersIcon,
      name: "Supply Types Orders",
      link: "supply-pending-orders",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: acceptedBuyAndSupplyReportIcon,
      name: "Accepted Buy & Supply Report",
      link: "accepted-buy-and-supply-report",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: auctionIcon,
      name: "Auctions",
      link: "auctions",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: auctionReportIcon,
      name: "Auctions Report",
      link: "auction-report",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: storeCategoriesIcon,
      name: "Store Categories",
      link: "store-categories",
      roles: [1, 8],
      environment: [1, 2],
    },
    {
      icon: storeSubcategoriesIcon,
      name: "Store Subcategories",
      link: "store-subcategories",
      roles: [1, 8],
      environment: [1, 2],
    },
    {
      icon: storeItemsIcon,
      name: "Store Items",
      link: "store-items",
      roles: [1, 8],
      environment: [1, 2],
    },
    {
      icon: factoriesIcon,
      name: "Factories Requests",
      link: "factories",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: factoriesIcon,
      name: "Factory Admin requests",
      link: "factory-admin-requests",
      roles: [3],
      environment: [1, 2],
    },
    {
      icon: suppliersIcon,
      name: "Suppliers Requests",
      link: "suppliers-requests",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: suppliersIcon,
      name: "Supplier Admin Requests",
      link: "supplier-admin-requests",
      roles: [5],
      environment: [1, 2],
    },
    // {
    //   icon: allOrdersIcon,
    //   name: "All Financial Orders",
    //   link: "financial-orders",
    //   roles: [2],environment:[1,2]
    // },
    // {
    //   icon: SalesRequestsIcon,
    //   name: "Sales Requests",
    //   link: "sales-requests",
    //   roles: [1],environment:[1,2]
    // },
    {
      icon: storesIcon,
      name: "Big Scrappers Stores",
      link: "stores",
      roles: [1],
      environment: [1, 2],
    },
    {
      icon: storesIcon,
      name: "Merchant Store",
      link: "merchant-store",
      roles: [1, 13],
      environment: [1, 2],
    },
    {
      icon: salesPersonReportIcon,
      name: "Sales Person Report",
      link: "sales-person-report",
      roles: [1, 6],
      environment: [1, 2],
    },
    {
      icon: settingsIcon,
      name: "Settings",
      link: "settings",
      roles: [1],
      environment: [1, 2],
    },
  ];

  const handleCatOnClick = ({ link }) => {
    window.scrollTo(0, 0);
    dispatch(updateCurrentPath(link));
    navigate(`/${link}`);
  };

  useEffect(() => {
    if (location) {
      dispatch(updateCurrentPath(location.pathname.slice(1)));
    }
  }, [location])

  return (
    <div className="sidebar flex dir-col items-center">
      <div>
        <div className="logo ">
          <h1 style={{ color: "#0DB14B" }}>Green Matter</h1>
        </div>
        <div className="account ">
          <div className="details ">
            <div className="admin">admin</div>
            <div className="account-name">{`${authorization?.fullName}`}</div>
          </div>
          <div className="logout ">
            <Link to={ROUTES.LOGOUT}>
              <img src={logoutIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="list flex-fill mt-3 align-self-stretch">
        {categoriesData?.map((item, index) => {
          if (adminRoles?.some((role) => item?.roles?.includes(role))) {
            if (
              baseUrl ===
              "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:6047/" &&
              item?.environment?.includes(1)
            ) {
              return (
                <ItemCategory
                  item={item}
                  currentPath={currentPath}
                  onClick={() => handleCatOnClick(item)}
                  key={index}
                />
              );
            } else if (
              baseUrl === "https://api.thegreenmatter.com/" &&
              item?.environment?.includes(2)
            ) {
              return (
                <ItemCategory
                  item={item}
                  currentPath={currentPath}
                  onClick={() => handleCatOnClick(item)}
                  key={index}
                />
              );
            } else {
              return null;
            }
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default SideBar;

const ItemCategory = ({ item, currentPath, onClick }) => (
  <div
    role="button"
    onClick={onClick}
    className={`category d-flex gap-2 ${currentPath === item.link && " selected"}`}
  >
    <div className="cat-icon">
      <img src={item.icon} alt={item.name} className="img-fluid" />
    </div>
    <div className="category-name">{item.name}</div>
  </div>
);
