import { useEffect, useRef, useState } from "react";
import { Button, DatePicker, Input, Space, Switch } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getStoreOrders, submitStoreExchangeDelivery } from "../../Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import ViewExchange from "modules/Orders/ViewExchange";

const StoreOrders = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [rowData, setRowData] = useState("");
    const [renderMe, setRenderMe] = useState(false);
    const [exchangeModalOpen, setExchangeModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [searchValues, setSearchValues] = useState({
        userName: "",
        userMobile: "",
        storeItem: "",
        paymentType: "",
        storeItemDescription: "",
        unitPrice: "",
        totalPrice: "",
        storeItemQuantity: "",
        cashFromUser: "",
        cashToUser: "",
        from: "",
        to: ""
    })
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [selectedRange, setSelectedRange] = useState([]);
    const { RangePicker } = DatePicker;
    const searchInput = useRef(null);

    const updateSearchValues = (property, newValue, otherProperty) => {
        const copyOfSearchObj = { ...searchValues }
        copyOfSearchObj[property] = newValue;
        if (otherProperty) {
            copyOfSearchObj[otherProperty.property] = otherProperty.value
        }
        setSearchValues(copyOfSearchObj)
    }

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("YYYY-MM-DD");
            const endDate = dates[1].format("YYYY-MM-DD");
            updateSearchValues("from", startDate, { property: "to", value: endDate })
        } else {
            updateSearchValues("from", "", { property: "to", value: "" })
        }
    };

    const handlePageChange = (page) => {
        if (page !== currentPage) {
            setLoading(true)
        }
        setCurrentPage(page)
    }

    const handlePageSizeChange = (current, newSize) => {
        setLoading(true)
        setPageSize(newSize)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setLoading(true)
        switch (dataIndex) {
            case "userName":
                updateSearchValues("userName", selectedKeys[0])
                break;
            case "userPhone":
                updateSearchValues("userMobile", selectedKeys[0])
                break;
            case "storeItem":
                updateSearchValues("storeItem", selectedKeys[0])
                break;
            case "paymentType":
                updateSearchValues("paymentType", selectedKeys[0])
                break;
            case "storeItemDescription":
                updateSearchValues("storeItemDescription", selectedKeys[0])
                break;
            case "unitPrice":
                updateSearchValues("unitPrice", selectedKeys[0])
                break;
            case "totalPrice":
                updateSearchValues("totalPrice", selectedKeys[0])
                break;
            case "storeItemQuantity":
                updateSearchValues("storeItemQuantity", selectedKeys[0])
                break;
            case "cashFromUser":
                updateSearchValues("cashFromUser", selectedKeys[0])
                break;
            case "cashToUser":
                updateSearchValues("cashToUser", selectedKeys[0])
                break;
            default:
                break;
        }
        confirm();
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        setLoading(true);
        switch (dataIndex) {
            case "userName":
                updateSearchValues("userName", "")
                break;
            case "userPhone":
                updateSearchValues("userMobile", "")
                break;
            case "storeItem":
                updateSearchValues("storeItem", "")
                break;
            case "paymentType":
                updateSearchValues("paymentType", "")
                break;
            case "storeItemDescription":
                updateSearchValues("storeItemDescription", "")
                break;
            case "unitPrice":
                updateSearchValues("unitPrice", "")
                break;
            case "totalPrice":
                updateSearchValues("totalPrice", "")
                break;
            case "storeItemQuantity":
                updateSearchValues("storeItemQuantity", "")
                break;
            case "cashFromUser":
                updateSearchValues("cashFromUser", "")
                break;
            case "cashToUser":
                updateSearchValues("cashToUser", "")
                break;
            default:
                break;
        }
        clearFilters();
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => true,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const getColumnDateProps = (dataIndex) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={(dates, datesStr) => {
                        setCurrentPage(1)
                        handleDateChange(dates, datesStr)
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const handleOrderStatus = (row) => {
        submitStoreExchangeDelivery(
            row.id,
            (res) => {
                setRowData("")
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message)
                    setRenderMe(!renderMe)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res.message)
            }
        )
    }

    const handleShowExchangeInfo = (row) => {
        setRowData(row)
        setExchangeModalOpen(true)
    }

    const columns = [
        {
            key: "userName",
            title: "USERNAME",
            dataIndex: "userName",
            ...getColumnSearchProps("userName"),
        },
        {
            key: "userPhone",
            title: "MOBILE NUMBER",
            dataIndex: "userPhone",
            ...getColumnSearchProps("userPhone"),
        },
        {
            key: "storeItem",
            title: "ITEM NAME",
            dataIndex: "storeItem",
            ellipsis: true,
            ...getColumnSearchProps("storeItem"),
            render: (_, row) => {
                return (
                    <span className="store-items__data-table__description text-truncate d-block">
                        {row.storeItem}
                    </span>
                )
            }
        },
        {
            key: "storeItemDescription",
            title: "Item Description",
            dataIndex: "storeItemDescription",
            ellipsis: true,
            ...getColumnSearchProps("storeItemDescription"),
            render: (_, row) => {
                return (
                    <span className="store-items__data-table__description text-truncate d-block">
                        {row.storeItemDescription}
                    </span>
                )
            }
        },
        {
            key: "unitPrice",
            title: "ITEM UNIT PRICE",
            dataIndex: "unitPrice",
            ...getColumnSearchProps("unitPrice"),
        },
        {
            key: "storeItemQuantity",
            title: "QUANTITY",
            dataIndex: "storeItemQuantity",
            ...getColumnSearchProps("storeItemQuantity"),
        },
        {
            key: "totalPrice",
            title: "TOTAL PRICE",
            dataIndex: "totalPrice",
            ...getColumnSearchProps("totalPrice"),
        },
        {
            key: "paymentType",
            title: "PAYMENT METHOD TYPE",
            dataIndex: "paymentType",
            ...getColumnSearchProps("paymentType"),
        },
        {
            key: "cashToUser",
            title: "CASH TO USER",
            dataIndex: "cashToUser",
            ...getColumnSearchProps("cashToUser"),
        },
        {
            key: "cashFromUser",
            title: "CASH FROM USER",
            dataIndex: "cashFromUser",
            ...getColumnSearchProps("cashFromUser"),
        },
        {
            key: "creationDate",
            title: "ORDER DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "id",
            title: "ACTIONS",
            render: (_, row) => {
                return (
                    <div className="d-flex flex-column align-items-center gap-3">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <span>Pending</span>
                            <Switch
                                checked={row.orderStatus !== "Pending"}
                                onChange={() => {
                                    if (row.orderStatus !== "Pending" || (rowData?.id === row.id && submitting)) return;
                                    setRowData(row)
                                    setSubmitting(true)
                                    handleOrderStatus(row)
                                }}
                                loading={submitting && rowData?.id === row.id}
                            />
                            <span>Delivered</span>
                        </div>
                        {row.paymentTypeId === 5 ? (
                            <Button
                                onClick={() => handleShowExchangeInfo(row)}
                                style={{ backgroundColor: "green", color: "white" }}
                            >
                                View Exchanged items
                            </Button>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
        },
    ]

    useEffect(() => {
        const payload = {
            pageNumber: currentPage,
            perPage: pageSize,
            ...searchValues
        }
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
                delete payload[property]
            }
        }
        getStoreOrders(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data.response);
                    setTotalCount(res.data.totalCount);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            })
    }, [renderMe, searchValues, currentPage, pageSize])

    return (
        <section className="users">
            <div className="users__all-contents ">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header
                            title="Store Orders"
                        />
                        <SubHeader bgColor="#356ab3" title="All Store Orders" />
                        <div className="users__table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"id"}
                                title={"Stores"}
                                totalCount={totalCount}
                                onPageChange={handlePageChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {exchangeModalOpen && (
                <ViewExchange
                    open={exchangeModalOpen}
                    onCancel={() => {
                        setExchangeModalOpen(false);
                        setRowData("")
                    }}
                    type="storeOrder"
                    rowData={rowData}
                />
            )}
        </section>
    );
}

export default StoreOrders;