import { useSelector } from "react-redux";

export const useAuth = () => {
  const {
    token: isAuthorized,
    factoryAdmin: isFactoryAdmin,
    roles: adminRoles,
  } = useSelector((state) => state.auth.authorization);

  return {
    isAuthorized,
    isFactoryAdmin,
    adminRoles,
  };
};
