import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { buyAndSupplyDeleteSupplyType, getSupplyItems } from "Network";

// components
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import SupplyModal from "./SupplyModal";
import ViewPricesList from "./ViewPricesList";

const Supply = () => {
  const [loading, setLoading] = useState(true);
  const [isDeleteItemOpen, setIsDeleteItemOpen] = useState(false);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [isItemModalVisible, setIsItemModalVisible] = useState(false);
  const [isViewPricesListModalVisible, setIsViewPricesListModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleEditBtn = (row) => {
    setMode("edit");
    setRowData(row);
    setIsItemModalVisible(true);
  };

  const handlDeleteBtn = (row) => {
    setIsDeleteItemOpen(true);
    setRowData(row);
  };

  const handleAddItems = () => {
    setIsItemModalVisible(true);
    setMode("add");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "typeNameEn",
      title: "TYPE (EN)",
      dataIndex: "typeNameEn",
      ...getColumnSearchProps("typeNameEn"),
    },
    {
      key: "typeNameAr",
      title: "TYPE (AR)",
      dataIndex: "typeNameAr",
      ...getColumnSearchProps("typeNameAr"),
    },
    {
      key: "unitValue",
      title: "UNIT VALUE",
      dataIndex: "unitValue",
      ...getColumnSearchProps("unitValue"),
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, record) => value === record.isActive,
      render: (_, row) => <span>{row.isActive ? "Active" : "Inactive"}</span>,
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
    },
    {
      key: "itemId",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              onClick={() => handleEditBtn(row)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Edit
            </Button>
            <Button type="danger" onClick={() => handlDeleteBtn(row)}>
              Delete
            </Button>
            <Button type="primary" onClick={() => {
              setRowData(row)
              setIsViewPricesListModalVisible(true)
            }}>
              View Prices List
            </Button>
          </Space>
        );
      },
    },
  ];

  const onCancel = () => {
    setIsItemModalVisible(false);
    setIsDeleteItemOpen(false);
    setMode("add");
  };

  const handleOk = () => {
    const data = { itemId: rowData.itemId };
    buyAndSupplyDeleteSupplyType(
      data,
      (res) => {
        if (res.success) {
          toastSuccess(res.message);
          setRenderMe((prev) => !prev);
          onCancel();
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
  };

  useEffect(() => {
    setLoading(true)
    getSupplyItems(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res?.data?.error);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Supply Types"
              plusButtonName={"Add Supply Type"}
              plusButtonAction={handleAddItems}
            />
            <SubHeader bgColor="#356ab3" title="All Types" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"itemId"}
              />
            </div>
          </div>
        </div>
        {isItemModalVisible && (
          <SupplyModal
            isItemModalVisible={isItemModalVisible}
            mode={mode}
            rowData={rowData}
            onSubmit={() => {
              setRenderMe(!renderMe)
              onCancel()
            }}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${mode === "add" ? "Add New" : "Edit"} Supply Type`}
                closeButton
                onCancel={onCancel}
              />
            }
            onCancel={onCancel}
            primaryBtnName={mode === "add" ? "Add" : "Edit"}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isDeleteItemOpen && (
          <Modal
            title="Delete Supply Type"
            open={isDeleteItemOpen}
            onOk={handleOk}
            onCancel={onCancel}
          >
            <span>Are you sure you want to delete this type?</span>
          </Modal>
        )}
        {isViewPricesListModalVisible && (
          <ViewPricesList
            open={isViewPricesListModalVisible}
            onCancel={() => {
              setRowData(null)
              setIsViewPricesListModalVisible(false)
            }}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`View Prices List`}
                closeButton
                onCancel={() => {
                  setRowData(null)
                  setIsViewPricesListModalVisible(false)
                }}
              />
            }
            rowData={rowData}
          />
        )}
      </div>
    </section>
  );
};

export default Supply;
