import { useState, useEffect, useRef } from "react";
import { Button, Modal, Space, Input, Switch, DatePicker, Image } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { deleteStoreItem, getStoreItems } from "Network";
import { baseUrl } from 'services';

// components
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import AddEditStoreItem from "./AddEditStoreItem";

const StoreItems = () => {
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("add");
    const [rowData, setRowData] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [isItemModalVisible, setIsItemModalVisible] = useState(false);
    const [isDeleteItemOpen, setIsDeleteItemOpen] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [searchValues, setSearchValues] = useState({
        description: "",
        descriptionAr: "",
        name: "",
        nameAr: "",
        isActive: "",
        storeAvailability: null,
        quantity: "",
        price: "",
        categoryName: "",
        subCategoryName: "",
        discountPercentage: "",
        link: "",
        from: "",
        to: ""
    })
    const [selectedRange, setSelectedRange] = useState([]);
    const { RangePicker } = DatePicker;
    const searchInput = useRef(null);

    const handleTableChange = (_, filters) => {
        setLoading(true)
        if (filters.isActive) {
            if (filters.isActive.length > 1) {
                updateSearchValues("isActive", "")
            } else {
                updateSearchValues("isActive", filters.isActive[0])
            }
        } else if (searchValues.isActive || searchValues.isActive === false) {
            updateSearchValues("isActive", "")
        }
        if (filters.forGreenMatter) {
            if (searchValues.storeAvailability) {
                const filterArrToStr = [...filters.forGreenMatter].sort((x, y) => x - y).join("")
                const crrentArrToStr = [...searchValues.storeAvailability].sort((x, y) => x - y).join("")
                if (filterArrToStr !== crrentArrToStr) {
                    updateSearchValues("storeAvailability", filters.forGreenMatter)
                }
            } else {
                updateSearchValues("storeAvailability", filters.forGreenMatter)
            }
        } else if (searchValues.storeAvailability) {
            updateSearchValues("storeAvailability", "")
        }
    }

    const updateSearchValues = (property, newValue, otherProperty) => {
        const copyOfSearchObj = { ...searchValues }
        copyOfSearchObj[property] = newValue;
        if (otherProperty) {
            copyOfSearchObj[otherProperty.property] = otherProperty.value
        }
        setSearchValues(copyOfSearchObj)
    }

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("YYYY-MM-DD");
            const endDate = dates[1].format("YYYY-MM-DD");
            updateSearchValues("from", startDate, { property: "to", value: endDate })
        } else {
            updateSearchValues("from", "", { property: "to", value: "" })
        }
    };

    const handlePageChange = (page) => {
        if (page !== currentPage) {
            setLoading(true)
        }
        setCurrentPage(page)
    }

    const handlePageSizeChange = (current, newSize) => {
        setLoading(true)
        setPageSize(newSize)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setLoading(true)
        switch (dataIndex) {
            case "description":
                updateSearchValues("description", selectedKeys[0])
                break;
            case "descriptionAr":
                updateSearchValues("descriptionAr", selectedKeys[0])
                break;
            case "name":
                updateSearchValues("name", selectedKeys[0])
                break;
            case "nameAr":
                updateSearchValues("nameAr", selectedKeys[0])
                break;
            case "quantity":
                updateSearchValues("quantity", selectedKeys[0])
                break;
            case "price":
                updateSearchValues("price", selectedKeys[0])
                break;
            case "categoryName":
                updateSearchValues("categoryName", selectedKeys[0])
                break;
            case "subCategoryName":
                updateSearchValues("subCategoryName", selectedKeys[0])
                break;
            case "discountPercentage":
                updateSearchValues("discountPercentage", selectedKeys[0])
                break;
            case "link":
                updateSearchValues("link", selectedKeys[0])
                break;
            default:
                break;
        }
        confirm();
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        setLoading(true);
        switch (dataIndex) {
            case "description":
                updateSearchValues("description", "")
                break;
            case "descriptionAr":
                updateSearchValues("descriptionAr", "")
                break;
            case "name":
                updateSearchValues("name", "")
                break;
            case "nameAr":
                updateSearchValues("nameAr", "")
                break;
            case "quantity":
                updateSearchValues("quantity", "")
                break;
            case "price":
                updateSearchValues("price", "")
                break;
            case "categoryName":
                updateSearchValues("categoryName", "")
                break;
            case "subCategoryName":
                updateSearchValues("subCategoryName", "")
                break;
            case "discountPercentage":
                updateSearchValues("discountPercentage", "")
                break;
            case "link":
                updateSearchValues("link", "")
                break;
            default:
                break;
        }
        clearFilters();
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => true,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || text === 0 ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const getColumnDateProps = (dataIndex) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={(dates, datesStr) => {
                        setCurrentPage(1)
                        handleDateChange(dates, datesStr)
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const columns = [
        {
            key: "image",
            title: "ITEM IMAGE",
            dataIndex: "image",
            render: (_, row) => (
                <Image
                    width={90}
                    height={70}
                    src={baseUrl + row.image}
                    className="sales-requests__receipt-img d-block img-fluid object-fit-cover"
                    alt=""
                />
            ),
        },
        {
            key: "name",
            title: "NAME (En)",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
        },
        {
            key: "nameAr",
            title: "NAME (AR)",
            dataIndex: "nameAr",
            ...getColumnSearchProps("nameAr"),
        },
        {
            key: "price",
            title: "PRICE",
            dataIndex: "price",
            ...getColumnSearchProps("price"),
        },
        {
            key: "quantity",
            title: "AVAILABLE QUANTITY",
            dataIndex: "quantity",
            ...getColumnSearchProps("quantity"),
        },
        {
            key: "description",
            title: "DESCRIPTION (EN)",
            dataIndex: "description",
            ...getColumnSearchProps("description"),
            render: (_, row) => {
                return (
                    <span className="store-items__data-table__description d-block">
                        {row.description}
                    </span>
                )
            }
        },
        {
            key: "descriptionAr",
            title: "DESCRIPTION (AR)",
            dataIndex: "descriptionAr",
            ...getColumnSearchProps("descriptionAr"),
            render: (_, row) => {
                return (
                    <span dir={"rtl"} className="store-items__data-table__description d-block text-end">
                        {row.descriptionAr}
                    </span>
                )
            }
        },
        {
            key: "link",
            title: "LINK",
            dataIndex: "link",
            ...getColumnSearchProps("link"),
            render: (_, row) => {
                return (
                    <a
                        href={row.link}
                        className="store-items__data-table__link text-center mx-auto d-block text-decoration-none"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {row.link}
                    </a>
                )
            }
        },
        {
            key: "categoryName",
            title: "CATEGORY",
            dataIndex: "categoryName",
            ...getColumnSearchProps("categoryName"),
        },
        {
            key: "subCategoryName",
            title: "SUBCATEGORY",
            dataIndex: "subCategoryName",
            ...getColumnSearchProps("subCategoryName"),
        },
        {
            key: "forGreenMatter",
            title: "STORE AVAILABILITY",
            dataIndex: "forGreenMatter",
            filters: [
                { text: "Green Matter Store", value: 1 },
                { text: "Big Scrappers Store", value: 2 },
                { text: "Both Stores", value: 3 },
            ],
            render: (_, row) => {
                return (
                    <span className="">
                        {row.forGreenMatter && row.forscrapper ? "Both Stores" : row.forGreenMatter ? "Green Matter Store" : row.forscrapper ? "Big Scrappers Store" : "_"}
                    </span>
                )
            }
        },
        {
            key: "discountPercentage",
            title: "DISCOUNT PERCENTAGE",
            dataIndex: "discountPercentage",
            ...getColumnSearchProps("discountPercentage"),
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "isActive",
            title: "IS ACTIVE",
            dataIndex: "isActive",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Deactive',
                    value: false,
                }
            ],
            render: (_, row) => (
                <Switch disabled={true} checked={row.isActive} />
            ),
        },
        {
            key: "itemId",
            title: "ACTIONS",
            dataIndex: "action",
            render: (_, row) => {
                return (
                    <Space size={5}>
                        <Button
                            onClick={() => handleEditBtn(row)}
                            style={{ backgroundColor: "green", color: "white" }}
                        >
                            Edit
                        </Button>
                        <Button type="danger" onClick={() => handlDeleteBtn(row)}>
                            Delete
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const handleEditBtn = (row) => {
        setMode("edit");
        setRowData(row);
        setIsItemModalVisible(true);
    };

    const handlDeleteBtn = (row) => {
        setIsDeleteItemOpen(true);
        setRowData(row);
    };

    const handleAddItems = () => {
        setIsItemModalVisible(true);
        setMode("add");
    };

    const handleModalCancel = () => {
        setIsItemModalVisible(false);
        setMode("add");
        setRowData(null);
    };

    const handleDeleteItem = () => {
        if (isDeleting) return;
        setIsDeleting(true)
        deleteStoreItem(
            rowData.itemId,
            (res) => {
                setIsDeleting(false)
                if (res.success) {
                    setIsDeleteItemOpen(false)
                    toastSuccess(res.message);
                    setRenderMe((prev) => !prev);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setIsDeleting(false)
                toastError(res.message);
            }
        );
    };

    useEffect(() => {
        const payload = {
            pageNumber: currentPage,
            perPage: pageSize,
            ...searchValues
        }
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
                delete payload[property]
            }
        }
        getStoreItems(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data.response);
                    setTotalCount(res.data.totalCount);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [renderMe, searchValues, pageSize, currentPage]);

    return (
        <section className="users store-items">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header
                            title="Store Items"
                            plusButtonName={"Add Store Item"}
                            plusButtonAction={handleAddItems}
                        />
                        <SubHeader bgColor="#356ab3" title="All Store Items" />
                        <div className="users__table store-items__data-table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"itemId"}
                                currentPage={currentPage}
                                totalCount={totalCount}
                                onPageChange={handlePageChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
                {isItemModalVisible && (
                    <AddEditStoreItem
                        isItemModalVisible={isItemModalVisible}
                        mode={mode}
                        rowData={rowData}
                        SubHeader={
                            <SubHeader
                                bgColor="#F58234"
                                title={`${mode === "add" ? "Add New Store Item" : "Edit Store Item"}`}
                                closeButton
                                onCancel={handleModalCancel}
                            />
                        }
                        onSubmit={() => {
                            setRenderMe(!renderMe)
                            handleModalCancel()
                        }}
                        onCancel={handleModalCancel}
                        primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
                        secondaryBtnName={"Cancel"}
                    />
                )}
                {isDeleteItemOpen && (
                    <Modal
                        title="Delete Store Item"
                        open={isDeleteItemOpen}
                        onOk={handleDeleteItem}
                        okText="Delete"
                        centered
                        okButtonProps={{ loading: isDeleting }}
                        onCancel={() => {
                            setRowData("")
                            setIsDeleteItemOpen(false)
                        }}
                    >
                        <span>{"Are you sure you want to delete this store item?"}</span>
                    </Modal>
                )}
            </div>
        </section>
    );
}

export default StoreItems;