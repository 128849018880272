import { useEffect, useRef, useState } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Modal, Space } from "antd";
import Highlighter from "react-highlight-words";
import { toastError, toastSuccess } from "helpers/toasters";
import { getAdminOffers, removeOfferByAdmin } from "Network";
import { exportDataAsExcel } from "helpers/helpfulFunctions";

import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";

const Offers = () => {
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [isDeleteOfferModalOpen, setIsDeleteOfferModalOpen] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(1);
    const [isExporting, setIsExporting] = useState(false);
    const [searchValues, setSearchValues] = useState({
        type: "",
        price: "",
        from: "",
        to: ""
    });
    const { RangePicker } = DatePicker;
    const [selectedRange, setSelectedRange] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const updateSearchValues = (property, newValue, otherProperty) => {
        const copyOfSearchObj = { ...searchValues }
        copyOfSearchObj[property] = newValue;
        if (otherProperty) {
            copyOfSearchObj[otherProperty.property] = otherProperty.value
        }
        setSearchValues(copyOfSearchObj)
    }

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("YYYY-MM-DD");
            const endDate = dates[1].format("YYYY-MM-DD");
            updateSearchValues("from", startDate, { property: "to", value: endDate })
        } else {
            updateSearchValues("from", "", { property: "to", value: "" })
        }
    };

    const handleExportDataToExcel = () => {
        if (isExporting) return;
        setIsExporting(true)
        const baseProps = [
            { title: "Type Name", prop: "categoryName" },
            { title: "Offer Price", prop: "pricePerKilo" },
            { title: "Creation Date", prop: "creationDate" },
        ]
        const payload = {
            isExport: true,
            currentPage,
            perPage: pageSize,
            ...searchValues,
        };
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
                delete payload[property]
            }
        }
        getAdminOffers(
            payload,
            (res) => {
                if (res.success) {
                    exportDataAsExcel(res.data.response, { baseProps, tab: "Offers Report" })
                    setIsExporting(false)
                }
            },
            (res) => {
                setIsExporting(false)
                toastError(res?.data?.error);
            }
        );
    }

    const handlePageChange = (page) => {
        setLoading(true)
        setCurrentPage(page)
    }

    const handlePageSizeChange = (current, newSize) => {
        setLoading(true)
        setPageSize(newSize)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setLoading(true)
        switch (dataIndex) {
            case "categoryName":
                updateSearchValues("type", selectedKeys[0])
                break;
            case "pricePerKilo":
                updateSearchValues("price", selectedKeys[0])
                break;
            default:
                break;
        }
        confirm();
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        setLoading(true);
        switch (dataIndex) {
            case "categoryName":
                updateSearchValues("type", "")
                break;
            case "pricePerKilo":
                updateSearchValues("price", "")
                break;
            default:
                break;
        }
        clearFilters();
        confirm()
    };

    const handlDeleteBtn = (row) => {
        setIsDeleteOfferModalOpen(true);
        setRowData(row);
    };
    const getColumnDateProps = (dataIndex) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={(dates, datesStr) => {
                        setCurrentPage(1)
                        handleDateChange(dates, datesStr)
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            return clearFilters && handleReset(clearFilters, dataIndex, confirm);
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => true,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            key: "categoryName",
            title: "TYPE NAME",
            dataIndex: "categoryName",
            ...getColumnSearchProps("categoryName"),
        },
        {
            key: "pricePerKilo",
            title: "OFFER PRICE",
            dataIndex: "pricePerKilo",
            ...getColumnSearchProps("pricePerKilo"),
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "requestId",
            title: "ACTION",
            dataIndex: "action",
            render: (_, row) => {
                return row.canDelete ? (
                    <Space size={5}>
                        <Button type="danger" onClick={() => handlDeleteBtn(row)}>
                            Delete
                        </Button>
                    </Space>
                ) : "";
            },
        },
    ];

    const handleCancel = () => {
        setIsDeleteOfferModalOpen(false)
    }

    const handleOk = () => {
        const data = { offerId: rowData.requestId };
        setModalLoading(true)
        removeOfferByAdmin(
            data,
            (res) => {
                handleCancel();
                setModalLoading(false)
                if (res.success) {
                    toastSuccess(res.message);
                    setRenderMe((prev) => !prev);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setModalLoading(false)
                toastError(res.message);
            }
        );
    };

    useEffect(() => {
        const payload = {
            pageNumber: currentPage,
            perPage: pageSize,
            ...searchValues
        }
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0) {
                delete payload[property]
            }
        }
        getAdminOffers(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data.response);
                    setTotalCount(res.data.totalCount);
                } else {
                    toastError(res.message);
                }
            },
            (fail) => {
                setLoading(false);
                toastError(fail.message);
            }
        );
    }, [renderMe, searchValues, pageSize, currentPage]);

    return (
        <section className="users">
            <div className="users__all-contents ">
                <div className="users__content">
                    <div className="users__usersData">
                        <Header title="Offers" />
                        <SubHeader bgColor="#356ab3" title="All Offers" />
                        <div className="users__table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"requestId"}
                                allowExport={true}
                                apiExport={true}
                                isExporting={isExporting}
                                onExport={handleExportDataToExcel}
                                title={"Offers"}
                                currentPage={currentPage}
                                totalCount={totalCount}
                                onPageChange={handlePageChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        </div>
                    </div>
                </div>
                {isDeleteOfferModalOpen && (
                    <Modal
                        title="Delete Offer"
                        open={isDeleteOfferModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        confirmLoading={modalLoading}
                    >
                        <span>{"Are you sure you want to delete this offer?"}</span>
                    </Modal>
                )}
            </div>
        </section>
    );
}

export default Offers;