import { useState, useEffect, useRef } from "react";
import { Button, Modal, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";
import { getAdminsApi, getRoles } from "Network";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import SubHeader from "modules/SubComponents/SubHeader";
import Header from "modules/SubComponents/Header";
import AdminModal from "./AdminModal";

function Admins() {
  const [mode, setMode] = useState("add");
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [renderMe, setRenderMe] = useState(false);
  const [allDataSource, setAllDataSource] = useState([]);
  const [isDeleteItemOpen, setIsDeleteItemOpen] = useState(false);
  const [isAdminModalVisible, setIsAdminModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [roles, setRoles] = useState([]);
  const searchInput = useRef(null);

  useEffect(() => {
    getRoles(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.name, text: ele.name };
          });
          setRoles(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        } else {
          toastError(res.message);
        }
      }
    );
  }, []);

  useEffect(() => {
    getAdmins();
  }, [renderMe]);

  const getAdmins = () => {
    getAdminsApi(
      (success) => {
        setLoading(false);
        if (success.success) {
          setAllDataSource(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        setLoading(false);
        if (fail.data.error) {
          toastError(fail.message);
        } else {
          toastError(fail.message);
        }
      }
    );
  };

  const handleAddAdminModal = () => {
    setIsAdminModalVisible(true);
  };

  const handleCancel = () => {
    if (isAdminModalVisible) {
      setRenderMe(!renderMe);
      setMode("add");
      setIsAdminModalVisible(false);
    } else {
      setIsDeleteItemOpen(false);
    }
  };

  const handleOk = () => { };

  const handleEditBtn = (row) => {
    setIsAdminModalVisible(true)
    setRowData(row);
    setMode("edit")
  };

  // const handleDeleteBtn = (row) => {
  //   setRowData(row);
  //   setIsDeleteItemOpen(true);
  // };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "adminId",
      title: "ADMIN",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "adminTypename",
      title: "TYPE",
      dataIndex: "adminTypename",
      filters: roles,
      onFilter: (value, record) => value === record.adminTypename,
      render: (_, record) => {
        return <span>{record.adminTypename}</span>
      }
    },
    {
      key: "isActive",
      title: "IS ACTIVE",
      dataIndex: "isActive",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Deactive',
          value: false,
        }
      ],
      onFilter: (value, record) => value === record.isActive,
      render: (_, record) => {
        return <span>{record.isActive ? "Active" : "Deactive"}</span>;
      },
    },
    {
      key: "adminId",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              onClick={() => handleEditBtn(row)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Edit
            </Button>
            {/* <Button type="danger" onClick={() => handleDeleteBtn(row)}>
              Delete
            </Button> */}
          </Space>
        );
      },
    },
  ];

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Admins"
              plusButtonName="Add Admin"
              plusButtonAction={handleAddAdminModal}
            />
            <SubHeader bgColor="#F58234" title="All Admins" />
            <div className="users__table grid-table mrg-y-30 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={allDataSource}
                rowId={"adminId"}
              />
            </div>
          </div>
        </div>
      </div>
      {isAdminModalVisible && (
        <AdminModal
          isAdminModalVisible={isAdminModalVisible}
          mode={mode}
          rowData={rowData}
          SubHeader={
            <SubHeader
              bgColor="#F58234"
              title={`${mode === "add" ? "Add New Admin" : "Edit Admin"}`}
              closeButton
              onCancel={handleCancel}
            />
          }
          onCancel={handleCancel}
          primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
          secondaryBtnName={"Cancel"}
        />
      )}
      {/* {isDeleteItemOpen && (
        <Modal
          title="Delete Item"
          open={isDeleteItemOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <span>{"Are you sure you want to delete this item?"}</span>
        </Modal>
      )} */}
    </section>
  );
}

export default Admins;
