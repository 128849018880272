import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Select } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  getActiveStoreCategories,
  makeCategoryAsSubCategoryOfAnotherCategory,
} from "Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const ToSubcategoryModal = ({
  isSwitchToSubcategoryModalOpen,
  onSubmit,
  rowData = {},
  onCancel,
}) => {
  const [storeCategories, setStoreCategories] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const initialValues = {
    categoryId: "",
  };

  const handleFinish = (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const payload = {
      newCategoryId: values.categoryId,
      currentCategoryId: rowData?.id,
    };

    makeCategoryAsSubCategoryOfAnotherCategory(
      payload,
      (res) => {
        setIsSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsSubmitting(false);
        toastError(res?.data?.error);
      }
    );
  };

  useEffect(() => {
    getActiveStoreCategories(
      (res) => {
        if (res.success) {
          const options = res.data.map((category) => ({
            value: category.id,
            label: category.name,
          }));
          setStoreCategories(
            options.filter((option) => option.value !== rowData.id)
          );
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        }
      }
    );
  }, [rowData]);

  return (
    isSwitchToSubcategoryModalOpen && (
      <Modal
        title={"Change to Subcategory"}
        open={isSwitchToSubcategoryModalOpen}
        className="msg-modal text-center auction-modal"
        footer={false}
        closable={false}
        centered
      >
        <Form
          form={form}
          name="form"
          className="edit-actor__content"
          initialValues={initialValues}
          onFinish={handleFinish}
        >
          <Row gutter={24}>
            <Col span={24}>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2">
                  Select Category
                </label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please, Select the category",
                    },
                  ]}
                  name="categoryId"
                  className="new-category__form-item"
                >
                  <Select
                    name="categoryId"
                    className="new-category__form-item auction-modal__select-holder"
                    placeholder="Select Category"
                    loading={storeCategories ? false : true}
                    options={storeCategories ? storeCategories : []}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
              <PrimaryButton
                buttonName={"Change"}
                loading={isSubmitting}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
};

export default ToSubcategoryModal;
