import { checkAdminStatus } from "Network";
import axios from "axios";

// live
export const baseUrl = "https://api.thegreenmatter.com/";

// test
// export const baseUrl =
//   "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:6047/";

let token = null;

export function getToken(newToken) {
  token = newToken;
}
function handleLogout() {
  window.location.href = "/logout";
}

export function getResource(path, onSuccess, onFail, reqAuth = true) {
  let requestData = {
    method: "get",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US"
    },
  };

  if (reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + token,
      "Accept-Language": "en-US"
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multiPart = false
) {
  if (token) {
    checkAdminStatus(
      (res) => {
        if (res.success) {
          if (!res.data) {
            handleLogout();
          }
        }
      },
      (res) => { }
    );
  }

  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US"
    },
    data,
  };
  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }
  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}

export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  checkAdminStatus(
    (res) => {
      if (res.success) {
        if (!res.data) {
          handleLogout();
        }
      }
    },
    (res) => { }
  );

  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US"
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function patchResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  checkAdminStatus(
    (res) => {
      if (res.success) {
        if (!res.data) {
          handleLogout();
        }
      }
    },
    (res) => { }
  );

  let requestData = {
    method: "patch",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US"
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function deleteResource(path, onSuccess, onFail, reqAuth = true) {
  checkAdminStatus(
    (res) => {
      if (res.success) {
        if (!res.data) {
          handleLogout();
        }
      }
    },
    (res) => { }
  );

  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: { "Accept-Language": "en-US" },
  };

  if (token && reqAuth) {
    requestData.headers = {
      "Accept-Language": "en-US",
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function deleteResourceWithData(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true
) {
  checkAdminStatus(
    (res) => {
      if (res.success) {
        if (!res.data) {
          handleLogout();
        }
      }
    },
    (res) => { }
  );

  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US"
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      "Accept-Language": "en-US",
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function uploadFileService(
  method,
  path,
  formDataType,
  data,
  onProgress,
  onSuccess,
  onFail,
  cancelToken
) {
  checkAdminStatus(
    (res) => {
      if (res.success) {
        if (!res.data) {
          handleLogout();
        }
      }
    },
    (res) => { }
  );

  let requestData = {
    method: method,
    url: baseUrl + path,
    formDataType,
    headers: {
      "Accept-Language": "en-US",
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
    },
    data,
    onUploadProgress: (progressEvent) => {
      onProgress(progressEvent);
    },
    cancelToken,
  };

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}
