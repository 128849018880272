import { useState, useEffect, useRef } from "react";
import { Button, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";
import { getFactoryRequestsForAdminApi } from "Network";

import SubHeader from "../SubComponents/SubHeader";
import GridTable from "modules/SubComponents/GridTable/GridTable";
import Header from "modules/SubComponents/Header";

function Factories() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const exportConfig = {
    tab: `Factories Report`,
    baseProps: [
      { title: "Name", prop: "factoryAdminName" },
      { title: "Type", prop: "categoryName" },
      { title: "Accepted Quantity", prop: "totalAcceptedQuantity" },
      { title: "Price/Kilo", prop: "pricePerKilo" },
      { title: "Created At", prop: "creationDate" },
    ]
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              return clearFilters && handleReset(clearFilters);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      key: "factoryAdminName",
      title: "NAME",
      dataIndex: "factoryAdminName",
      ...getColumnSearchProps("factoryAdminName"),
    },
    {
      key: "categoryName",
      title: "TYPE",
      dataIndex: "categoryName",
      ...getColumnSearchProps("categoryName"),
    },
    {
      key: "totalAcceptedQuantity",
      title: "ACCEPTED QUANTITY",
      dataIndex: "totalAcceptedQuantity",
    },
    {
      key: "pricePerKilo",
      title: "PRICE/KILO",
      dataIndex: "pricePerKilo",
      ...getColumnSearchProps("pricePerKilo"),
    },
    {
      key: "creationDate",
      title: "CREATED AT",
      dataIndex: "creationDate",
      ...getColumnSearchProps("creationDate"),
    },
  ];

  useEffect(() => {
    getFactoryRequestsForAdminApi(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        setLoading(false);
        toastError(res?.data?.error);
      }
    );
  }, []);

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title={"Factories Requests"} />
            <SubHeader bgColor="#356ab3" title="All Factories Requests" />
            <div className="users__table grid-table mrg-ye-50 ">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                allowExport={true}
                exportConfig={exportConfig}
                title={"Factories Requests"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Factories;
